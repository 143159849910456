import React from "react";
import Home from "./routes/home/Home";
import "./App.css";

const App = () => {
    return (
        <Home />
    );
};

export default App;