import React from "react";
import result_analysis_demo from "../../../images/other/result-analysis-demo.png";
import "./About.scss";
import "./ResultsAnalysisMobile.scss";

const ResultsAnalysisMobile = () => {
    return (
        <section className="results-analysis-mobile about-section-mobile">
            <div className="wrapper">
                <section className="subtitle">
                    <h2>Foutenanalyse</h2>
                </section>

                <section className="summary">
                    <p>Leren van je fouten is het belangrijkst. Zo kom je snel voorruit en blijft het beter hangen. We hebben een uitgebreide foutenanalyse. Don't worry! </p>
                </section>

                <section className="image-section">
                    <img className="result-analysis-image" alt="" src={result_analysis_demo} />
                </section>
            </div>
        </section>
    );
};

export default ResultsAnalysisMobile;