import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { getJWT } from "../../helpers/jwt";
import { DASHBOARD_ROUTE, ERROR_MESSAGE, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, RESUME_PACKAGE_API, SIGN_IN_ROUTE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR } from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import Loader from "../../components/core/loader/Loader";
import logo_icon from "../../images/logos/logo-icon.svg";
import "./PackagePaused.scss";


const PackagePaused = () => {
    const [resumingLicense, setResumingLicense] = useState(false);
    const [resumePackageWarning, setResumePackageWarning] = useState(false);

    const navigate = useNavigate();
    const jwt = getJWT();
    const { user, fetchingUser, reduxUser, setUser } = useAuthContext();

    const resumeLicense = async () => {
        setResumingLicense(true);

        try {
            const response = await fetch(RESUME_PACKAGE_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({})
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                const currentDate = new Date();
                const packagePauseEnd = new Date(user.pakketPauzeEind);
                let oldPackageEnd = new Date(user.autoToegang);
                let newPackageEnd = null;
                let userCopy = JSON.parse(JSON.stringify(user));
    
                if (currentDate < packagePauseEnd) {
                    newPackageEnd = new Date(oldPackageEnd.getTime() - (packagePauseEnd.getTime() - currentDate.getTime()));
                }
            
                userCopy.autoToegang = newPackageEnd ? newPackageEnd : user.autoToegang;
                userCopy.pakketPauzeEind = currentDate < packagePauseEnd ? currentDate : packagePauseEnd;
                userCopy.pakketGepauzeerd = false;
                setUser(userCopy);
                navigate(DASHBOARD_ROUTE);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setResumingLicense(false);
        }
    };

    const getDateTime = (dateString) => {
        const date = new Date(dateString);
        const netherlandsOffset = -60;
        const isDST = date.dst();
        const totalOffset = netherlandsOffset - (isDST ? 60 : 0);

        date.setUTCMinutes(date.getUTCMinutes() - totalOffset);

        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
        const hours = String(date.getUTCHours()).padStart(2, "0");
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    };

    Date.prototype.dst = function() {
        const startDST = new Date(this.getFullYear(), 2, 31);
        const endDST = new Date(this.getFullYear(), 9, 31);
        
        startDST.setDate(31 - startDST.getDay());
        endDST.setDate(31 - endDST.getDay());
    
        return this >= startDST && this < endDST;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!user && !fetchingUser) {
            navigate(SIGN_IN_ROUTE);
        }

        if (user) {
            if (!user.pakketGepauzeerd) {
                navigate(DASHBOARD_ROUTE)
            }

            if (new Date(user.pakketPauzeEind) <= new Date()) {
                resumeLicense();
            }
        }
    }, [user, fetchingUser]);

    return (<>
        <div className="package-paused container">
            <NavMenu packageExpired={false} packagePaused={true} />
            <Header page="Pauze beëindigen" />

            {resumingLicense
                ? <main><Loader /></main>
                : <main>
                    <img className="logo-icon" alt="Logo" src={logo_icon} />
                    <section className="wrapper">
                        <h2>Je hebt je pakket gepauzeerd tot:</h2>
                        <h1>{getDateTime(user ? user.pakketPauzeEind : reduxUser.pakketPauzeEind)}</h1>
                        <div
                            className="resume-package-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setResumePackageWarning(true);
                            }}
                        >
                            <div className="text centered">PAUZE BEËINDIGEN</div>
                        </div>
                    </section>
                </main>
            }
        </div>

        
        <div
            className={"resume-package-overlay" + (resumePackageWarning ? " visible" : "")}
            onClick={(e) => {
                e.preventDefault();
                setResumePackageWarning(false);
            }}
        />

        {resumePackageWarning
            ? <CloseIcon
                className="resume-package-close-button"
                onClick={(e) => {
                    e.preventDefault();
                    setResumePackageWarning(false);
                }}
            />
            : <></>
        }

        <div className={"resume-package-warning" + (resumePackageWarning ? " visible" : "")}>
            <div className="wrapper">
                <PriorityHighIcon />
                <h4>Weet je zeker dat je de pauze wilt beëindigen?</h4>
                <p>Als je het pakket nu van de pauze af haalt kan je het pakket niet meer pauzeren.</p>
            
                <div
                    className="no-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setResumePackageWarning(false);
                    }}
                >
                    <div className="text centered">NIET BEËINDIGEN</div>
                </div>
                <div
                    className="yes-button"
                    onClick={(e) => {
                        e.preventDefault();
                        resumeLicense();
                    }}
                >
                    <div className="text centered">WEL BEËINDIGEN</div>
                </div>
            </div>
        </div>

        <Footer />
    </>);
};

export default PackagePaused;