import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../helpers/jwt";
import useFetch from "../../hooks/useFetch";
import { ERROR_MESSAGE, EXAM_STATUSSES_API, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR, UPDATE_CURRENT_EXAM_STATUS_API } from "../../constants";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import CurrentQuestion from "../../components/exams/currentquestion/CurrentQuestion";
import ExamList from "../../components/exams/examlist/ExamList";
import ExamText from "../../components/exams/examtext/ExamText";
import Loader from "../../components/core/loader/Loader";
import "./Exams.scss";


const Exams = () => {
    const [statusIndex, setStatusIndex] = useState(0);
    const [updatingCurrentStatus, setUpdatingCurrentStatus] = useState(false);

    const { data, loading, error } = useFetch(EXAM_STATUSSES_API, {}, true);
    const navigate = useNavigate();
    const jwt = getJWT();

    const updateCurrentStatus = async (status) => {
        if (status.id > -1) {
            setUpdatingCurrentStatus(true);

            try {
                const response = await fetch(UPDATE_CURRENT_EXAM_STATUS_API, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + jwt
                    },
                    body: JSON.stringify({
                        status: status.id
                    })
                });
        
                const data = await response.json();
    
                if (!response.ok || String(response.status).charAt(0) !== "2") {
                    if (data?.error) {
                        throw data?.error;
                    } else {
                        throw UNKNOWN_API_ERROR;
                    }
                }
        
                if (data?.error) {
                    throw data?.error;
                }
            } catch (err) {
                if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                    message.error(UNAUTHORIZED_MESSAGE);
                    navigate(SIGN_OUT_ROUTE);
                } else if (err === INACTIVE_JWT_API_ERROR) {
                    message.error(OTHER_DEVICE_MESSAGE);
                    navigate(SIGN_OUT_ROUTE);
                } else {
                    message.error(ERROR_MESSAGE);
                }
            } finally {
                setUpdatingCurrentStatus(false);
            }
        }
    };

    useEffect(() => {
        if (data && data.statusses) {
            for (let i = 0; i < data.statusses.length; i++) {
                if (data.statusses[i].currentStatus) {
                    setStatusIndex(i);
                }
            };
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            message.error(ERROR_MESSAGE);            
        }
    }, [error]);

    return (<>
        <div className="exams container">
            <NavMenu />
            <Header page="Examens maken" />
            
            {loading || error
                ? <main><Loader /></main>
                : <main>
                    {data.statusses[statusIndex].exam.voertuig === "Auto"
                        ? (
                            data.statusses[statusIndex].numKnowledgeAnswered === 0 || data.statusses[statusIndex].finished
                                ? <p className="status">
                                    Je gaat nu starten met: <span>{data.statusses[statusIndex].exam.titel}, Kennis</span>
                                </p>
                                :
                            data.statusses[statusIndex].numKnowledgeAnswered === data.statusses[statusIndex].numKnowledge
                                ? <p className="status">
                                    Je bent gebleven bij: <span>{data.statusses[statusIndex].exam.titel}, Inzicht</span>
                                </p>
                                : <p className="status">
                                    Je bent gebleven bij: <span>{data.statusses[statusIndex].exam.titel}, Kennis</span>
                                </p>
                        )
                        : (
                            data.statusses[statusIndex].numOtherAnswered === 0 || data.statusses[statusIndex].finished
                                ? <p className="status">
                                    Je gaat nu starten met: <span>{data.statusses[statusIndex].exam.titel}, {data.statusses[statusIndex].exam.voertuig}</span>
                                </p>
                                :
                            <p className="status">
                                Je bent gebleven bij: <span>{data.statusses[statusIndex].exam.titel}, {data.statusses[statusIndex].exam.voertuig}</span>
                            </p>
                        )
                    }

                    <CurrentQuestion
                        status={data.statusses[statusIndex]}
                        updatingCurrentStatus={updatingCurrentStatus}
                    />

                    <ExamList
                        statusses={data.statusses}
                        statusIndex={statusIndex}
                        setStatusIndex={setStatusIndex}
                        updateCurrentStatus={updateCurrentStatus}
                    />

                    <ExamText
                        statusses={data.statusses}
                        statusIndex={statusIndex}
                        setStatusIndex={setStatusIndex}
                        text={data.statusses[statusIndex].exam.beschrijving}
                        updateCurrentStatus={updateCurrentStatus}
                        updatingCurrentStatus={updatingCurrentStatus}
                    />
                </main>
            }
        </div>

        <Footer />
    </>);
};

export default Exams;