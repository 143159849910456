import React from "react";

const NavLink = (props) => {
    const route = window.location.pathname;

    return (
        props.route === route
            ? <div className="nav-link active">
                {props.icon}
                <h3>{props.text}</h3>
            </div>
            : <a href={props.route} className="nav-link">
                {props.icon}
                <h3>{props.text}</h3>
            </a>
    );
};

export default NavLink;