import { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../helpers/jwt";
import { INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR } from "../constants";

const useFetch = (url, options, authorization) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    const navigate = useNavigate();
    const jwt = getJWT();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (authorization) {
                if (options) {
                    if (options.headers) {
                        options.headers.Authorization = "Bearer " + jwt;
                    } else {
                        options.headers = { Authorization: "Bearer " + jwt }
                    }
                } else {
                    // eslint-disable-next-line
                    options = {
                        headers: { Authorization: "Bearer " + jwt }
                    };
                }
            }

            try {
                const response = options ? await fetch(url, options) : await fetch(url);
                const data = await response.json();
    
                if (!response.ok || String(response.status).charAt(0) !== "2") {
                    if (data?.error) {
                        throw data?.error;
                    } else {
                        throw UNKNOWN_API_ERROR;
                    }
                }

                if (data?.error) {
                    throw data?.error;
                } else {
                    setData(data);
                }
            } catch (err) {
                if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                    message.error(UNAUTHORIZED_MESSAGE);
                    navigate(SIGN_OUT_ROUTE);
                } else if (err === INACTIVE_JWT_API_ERROR) {
                    message.error(OTHER_DEVICE_MESSAGE);
                    navigate(SIGN_OUT_ROUTE);
                } else {
                    setError(err);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { data, loading, error }
};

export default useFetch;