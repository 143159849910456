import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { getJWT } from "../../helpers/jwt";
import { ANALYZE_ROUTE_STATE, ERROR_MESSAGE, EXAMS_ROUTE, EXAM_API, EXAM_ROUTE, FINISH_EXAM_API, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SAVE_EXAM_STATUS_API, SET_SECONDS_LEFT_EXAM_STATUS_API, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR } from "../../constants";
import PracticeCloseButton from "../../components/practice/practiceclosebutton/PracticeCloseButton";
import AutoExamPages from "../../components/practice/pages/AutoExamPages";
import PracticeNavigation from "../../components/practice/practicenavigation/PracticeNavigation";
import Loader from "../../components/core/loader/Loader";
import "./Practice.scss";

const KNOWLEDGE_TIME = 9 * 60;
const INSIGHT_TIME = 19 * 60;
const OTHER_TIME = 30 * 60;
// const KNOWLEDGE_TIME = 2;
// const INSIGHT_TIME = 2;
// const OTHER_TIME = 2;
const SET_SECONDS_LEFT_EXAM_STATUS_INTERVAL = 5 * 1000;

const AutoExam = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [data, setData] = useState(null);
    const [fetchingExam, setFetchingExam] = useState(true);
    const [pageIndex, setPageIndex] = useState(0);
    const [timerPlaying, setTimerPlaying] = useState(false);
    const [timerKey, setTimerKey] = useState(0);
    const [timerTime, setTimerTime] = useState(null);
    const [insightTimerStarted, setInsightTimerStarted] = useState(false);
    const [questionId, setQuestionId] = useState(-1);
    const [knowledge, setKnowledge] = useState(false);
    const [insight, setInsight] = useState(false);
    const [other, setOther] = useState(false);
    const [insightStart, setInsightStart] = useState(false);
    const [insightEnd, setInsightEnd] = useState(false);
    const [otherEnd, setOtherEnd] = useState(false);
    const [categoryEnd, setCategoryEnd] = useState(false);
    const [answer, setAnswer] = useState("");
    const [previousButtonEnabled, setPreviousButtonEnabled] = useState(false);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const [knowledgeResults, setKnowledgeResults] = useState([]);
    const [insightResults, setInsightResults] = useState([]);
    const [otherResults, setOtherResults] = useState([]);
    const [finishingExam, setFinishingExam] = useState(true);
    const [pageSwitched, setPageSwitched] = useState(false);

    const jwt = getJWT();

    const vehicle = data?.vehicle;
    const numKnowledgeQuestions = data?.knowledgeQuestions.length;
    const numInsightQuestions = data?.insightQuestions.length;
    const numOtherQuestions = data?.otherQuestions.length;

    const pages = document.querySelector(".pages");
    const timer = document.querySelector(".timer");
    const timerCircle = timer?.getElementsByTagName("div")[0].getElementsByTagName("div")[0];
    const practice_navigation = document.querySelector(".practice-navigation");

    const knowledgePageIndex = 0;
    const insightPageIndex = knowledgePageIndex + numKnowledgeQuestions + 1;
    const otherPageIndex = 0;
    const resultsPageIndex = (vehicle === "Auto" ? insightPageIndex + numInsightQuestions + 1 : numOtherQuestions + 1);

    const postRequest = async (url, body) => {
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify(body)
            });
    
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const fetchExam = async () => {
        setFetchingExam(true);

        try {
            const response = await fetch(EXAM_API, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                }
            });
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }


            if (data?.error) {
                throw data?.error;
            } else {
                setData(data);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setFetchingExam(false);
        }
    }

    const finishExam = async () => {
        setFinishingExam(true);

        try {
            const response = await fetch(FINISH_EXAM_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                }
            });
    
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                if (vehicle === "Auto") {
                    setKnowledgeResults(data.knowledgeResults);
                    setInsightResults(data.insightResults);
                } else {
                    setOtherResults(data.otherResults);
                }
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setFinishingExam(false);
        }
    };

    const switchPage = (index) => {
        setPageIndex(index);

        if (vehicle === "Auto") {
            if (index === knowledgePageIndex || index === insightPageIndex || index === resultsPageIndex) {
                timer.style.display = "none";
                practice_navigation.style.display = "none";
            } else {
                timer.style.display = "block";
                practice_navigation.style.display = "block";
            }

            if (index === knowledgePageIndex + 1 || index === insightPageIndex + 1) {
                setPreviousButtonEnabled(false);
                setNextButtonEnabled(false);
                setCategoryEnd(false);
            } else if (index === knowledgePageIndex + numKnowledgeQuestions || index === insightPageIndex + numInsightQuestions) {
                setPreviousButtonEnabled(true);
                setNextButtonEnabled(false);
                setCategoryEnd(true);
            } else {
                setPreviousButtonEnabled(true);
                setNextButtonEnabled(false);
                setCategoryEnd(false);
            }

            if (index === knowledgePageIndex) {
                setTimerPlaying(false);
            } else if (index === insightPageIndex) {
                setTimerPlaying(false);
            } else if (index > knowledgePageIndex && index < insightPageIndex) {
                setTimerPlaying(true);
            } else if (index > insightPageIndex && index < resultsPageIndex) {
                if (!insightTimerStarted) {
                    startInsightTimer();
                } else {
                    setTimerPlaying(true);
                }
            } else if (index === resultsPageIndex) {
                setTimerPlaying(false);
                finishExam();
            }
        } else {
            if (index === otherPageIndex || index === resultsPageIndex) {
                timer.style.display = "none";
                practice_navigation.style.display = "none";
            } else {
                timer.style.display = "block";
                practice_navigation.style.display = "block";
            }

            if (index === otherPageIndex + 1) {
                setPreviousButtonEnabled(false);
                setNextButtonEnabled(false);
                setCategoryEnd(false);
            } else if (index === otherPageIndex + numOtherQuestions) {
                setPreviousButtonEnabled(true);
                setNextButtonEnabled(false);
                setCategoryEnd(true);
            } else {
                setPreviousButtonEnabled(true);
                setNextButtonEnabled(false);
                setCategoryEnd(false);
            }

            if (index === otherPageIndex) {
                setTimerPlaying(false);
            } else if (index > otherPageIndex && index < resultsPageIndex) {
                setTimerPlaying(true);
            } else if (index === resultsPageIndex) {
                setTimerPlaying(false);
                finishExam();
            }
        }
    };

    const startInsightTimer = () => {
        setTimerKey(prevKey => prevKey + 1);
        setTimerTime(INSIGHT_TIME);
        postRequest(SET_SECONDS_LEFT_EXAM_STATUS_API, { secondsLeft: INSIGHT_TIME });
        setTimerPlaying(true);
        setInsightTimerStarted(true);
    };

    const secondsToTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const second = seconds % 60;

        return `${minutes.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`;
    };

    const timeToSeconds = (time) => {
        const [minutes, seconds] = time.split(":").map(Number);

        return (minutes * 60) + seconds;
    };

    const previousPage = () => {
        switchPage(pageIndex - 1);
    };

    const nextPage = () => {
        switchPage(pageIndex + 1);
    };

    const setSecondsLeftExamStatus = async () => {
        if (timerCircle) {
            const body = { secondsLeft: timeToSeconds(timerCircle.innerHTML) };

            postRequest(SET_SECONDS_LEFT_EXAM_STATUS_API, body);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchExam();
    }, []);

    useEffect(() => {
        const interval = setInterval(setSecondsLeftExamStatus, SET_SECONDS_LEFT_EXAM_STATUS_INTERVAL);

        return () => clearInterval(interval);
    }, [timerCircle]);

    useEffect(() => {
        if (data) {
            if (data.status) {
                setTimerKey(prevKey => prevKey + 1);
                setTimerTime(data.status.secondsLeft);
            } else {
                setTimerKey(prevKey => prevKey + 1);
                setTimerTime(vehicle === "Auto" ? KNOWLEDGE_TIME : OTHER_TIME);
            }
        }
    }, [data]);

    useEffect(() => {
        if (data && pages && !pageSwitched) {
            if (data.status && data.status.finished) {
                switchPage(resultsPageIndex);
            } else {
                if (location.state === ANALYZE_ROUTE_STATE) {
                    navigate(EXAM_ROUTE, {
                        state: ""
                    });
                    switchPage(resultsPageIndex);
                } else {
                    if (vehicle === "Auto") {
                        const knowledgeProgress = data.numKnowledgeAnswered < numKnowledgeQuestions && data.numKnowledgeAnswered > 0;
                        const insightProgress = data.numKnowledgeAnswered >= numKnowledgeQuestions && data.numInsightAnswered > 0

                        setKnowledge(knowledgeProgress);
                        setInsight(insightProgress);
                        setInsightStart(!knowledgeProgress && !insightProgress);
                        setInsightEnd(data.numKnowledgeAnswered >= numKnowledgeQuestions && data.numInsightAnswered >= numInsightQuestions);
                        setInsightTimerStarted(insightProgress)

                        if (data.status && data.numKnowledgeAnswered === numKnowledgeQuestions && insightStart) {
                            setPageIndex(insightPageIndex);
                        }
                    } else {
                        const otherProgress = data.numOtherAnswered < numOtherQuestions && data.numOtherAnswered > 0;

                        setOther(otherProgress);
                        setOtherEnd(data.numOtherAnswered >= numOtherQuestions);
                    }
                }
            }
            setPageSwitched(true);
        }
    }, [data, pages, insightStart, insightPageIndex, resultsPageIndex, numKnowledgeQuestions, numInsightQuestions, numOtherQuestions]);

    useEffect(() => {
        if (pages) {
            const pageArray = pages.querySelectorAll(".page");
            const pageId = pageArray[pageIndex].id;

            for (let i = 0; i < pageArray.length; i++) {
                pageArray[i].style.opacity = i === pageIndex ? "1": "0";
                pageArray[i].style.zIndex =  i === pageIndex ? "1": "0";
            }

            if (pageId !== undefined && pageId !== null && pageId !== "") {
                setQuestionId(pageId);
            }
        }
    }, [pages, pageIndex, data]);

    useEffect(() => {
        const questionPage = document.getElementById(questionId);
        const active = questionPage?.querySelector(".active");
        const inputs = questionPage?.getElementsByTagName("input");
        const value = inputs ? (inputs.length > 0 ? (inputs[0]?.value !== "" ? true : false) : false) : false;

        setNextButtonEnabled(active || value ? true : false)
    }, [questionId]);

    useEffect(() => {
        setNextButtonEnabled(answer !== "" ? true : false)
    }, [answer]);

    return (
        <div className="practice">
            {!data || fetchingExam
                ? <main><Loader /></main>
                : <main>
                    <PracticeCloseButton
                        route={EXAMS_ROUTE}
                        warn={data.status && pageIndex !== knowledgePageIndex && pageIndex !== resultsPageIndex && !data.status.finished}
                        pauses={data.status ? data.status.pauses - 1 : null}
                        handleClose={setSecondsLeftExamStatus}
                    />

                    <AutoExamPages
                        data={data}
                        vehicle={vehicle}
                        fetchExam={fetchExam}
                        pageIndex={pageIndex}
                        switchPage={switchPage}
                        knowledge={knowledge}
                        setKnowledge={setKnowledge}
                        knowledgePageIndex={knowledgePageIndex}
                        insight={insight}
                        setInsight={setInsight}
                        insightEnd={insightEnd}
                        insightPageIndex={insightPageIndex}
                        other={other}
                        setOther={setOther}
                        otherEnd={otherEnd}
                        otherPageIndex={otherPageIndex}
                        setAnswer={setAnswer}
                        setAnswered={setNextButtonEnabled}
                        knowledgeResults={knowledgeResults}
                        insightResults={insightResults}
                        otherResults={otherResults}
                        finishingExam={finishingExam}
                    />

                    <div className="timer">
                        <CountdownCircleTimer
                            key={timerKey}
                            isPlaying={timerPlaying}
                            duration={timerTime ? timerTime : (vehicle === "Auto" ? KNOWLEDGE_TIME : OTHER_TIME)}
                            colors={["#4A7DE2"]}
                            size={96}
                            strokeWidth={8}
                            onComplete={() => {
                                if (vehicle === "Auto") {
                                    if (knowledge) {
                                        const body = { questions: {} };

                                        data.knowledgeQuestions.forEach(question => {
                                            body.questions[question.id] = "(leeg gelaten)";
                                        });
                                        
                                        postRequest(SAVE_EXAM_STATUS_API, body);
                                        switchPage(insightPageIndex);
                                    } else if (insight) {
                                        switchPage(resultsPageIndex);
                                    }
                                } else {
                                    switchPage(resultsPageIndex);
                                }
                            }}
                        >
                            {({ remainingTime }) => secondsToTime(remainingTime)}
                        </CountdownCircleTimer>
                    </div>

                    <PracticeNavigation
                        previousText={previousButtonEnabled ? "VORIGE" : null}
                        previousFunction={previousButtonEnabled ? previousPage : null}
                        statusText={vehicle === "Auto"
                            ? (
                                (knowledge ? "Kennis " : insight ? "Inzicht " : "")
                                + (knowledge
                                    ? "" + (pageIndex + 1 - (knowledgePageIndex + 1))
                                    : insight
                                        ? pageIndex + 1 - (insightPageIndex + 1)
                                        : -1).toString()
                                + " van " + (knowledge
                                    ? numKnowledgeQuestions
                                    : insight
                                        ? numInsightQuestions
                                        : -1).toString()
                            )
                            : vehicle + " " + (pageIndex + 1 - (otherPageIndex + 1)) + " van " + numOtherQuestions
                        }
                        nextText={categoryEnd ? (knowledge ? "INZICHT" : (insight || (vehicle !== "Auto")) ? "INLEVEREN" : "") : "VOLGENDE"}
                        nextFunction={nextButtonEnabled ? nextPage : null}
                    />
                </main>
            }
        </div>
    );
};

export default AutoExam;