import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";

const UPDATE_INTERVAL = 60 * 1000;

const PackageStatus = () => {
    const [timeRemaining, setTimeRemaining] = useState({ days: 0, hours: 0 });
    
    const { reduxUser } = useAuthContext();

    useEffect(() => {
        const getDaysAndHours = () => {
            const end = new Date(reduxUser.pakketGepauzeerd ? reduxUser.pakketPauzeEind : (reduxUser.huidigVoertuig === "Auto" ? reduxUser.autoToegang : reduxUser.motorToegang));
            const currentDate = new Date();
            const difference = end - currentDate;
            const days = Math.floor(difference / (1000 * 60 * 60 * 24));
            const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

            setTimeRemaining({ days, hours });
        }
        
        getDaysAndHours();

        const interval = setInterval(() => {
            getDaysAndHours();
        }, UPDATE_INTERVAL);

        return () => clearInterval(interval);
    }, [reduxUser]);

    if (reduxUser.pakketGepauzeerd) {
        if (timeRemaining.days < 0 && timeRemaining.hours < 0) {
            return (
                <div className="package-status centered"><span>Je pakket is gepauzeerd</span></div>
            );
        }

        return (
            <div className="package-status centered">Je pakket is gepauzeerd voor nog:<br /><span>{timeRemaining.days} dagen, {timeRemaining.hours} uur</span></div>
        );
    }

    if (timeRemaining.days < 0 && timeRemaining.hours < 0) {
        return (
            <div className="package-status centered"><span>Je pakket is verlopen</span></div>
        );
    }

    return (
        <div className="package-status centered">Je pakket verloopt over:<br /><span>{timeRemaining.days} dagen, {timeRemaining.hours} uur</span></div>
    );
};

export default PackageStatus;