import React from "react";
import knowledge_insight_demo from "../../../images/other/knowledge-insight-demo.png";
import "./About.scss";
import "./KnowledgeInsightMobile.scss";

const KnowledgeInsightMobile = () => {
    return (
        <section className="knowledge-insight-mobile about-section-mobile">
            <div className="wrapper">
                <section className="situation-info about-block">
                    <h3>
                        's Avonds in je bed of tijdens je pauze. In de bus?{" "}
                        <span>Het kan overal en altijd.</span>
                    </h3>
                    <p>
                        In de bus zitten of een lunchpauze nemen maak
                        <br />
                        je nu lekker productief door te leren voor je
                        <br />
                        theorie.
                    </p>
                </section>

                <section className="subtitle">
                    <h2>Kennis en inzicht</h2>
                </section>

                <section className="summary">
                    <p>Alle kennis en inzicht vragen lekker bij de hand. Slagen doen we op de meest efficiënte manier. Met soortgelijke vragen die je ook echt op je CBR theorie-examen kan verwachten. We got you!</p>
                </section>

                <section className="image-section">
                    <img className="knowledge-insight-image" alt="" src={knowledge_insight_demo} />
                </section>
            </div>
        </section>
    );
};

export default KnowledgeInsightMobile;