import React, { useEffect } from "react";
import Player from "@vimeo/player";
import { BASE } from "../../../constants";
import "./Frame.scss";

const Frame = ({ video_html, thumbnail, setVideoEnded }) => {
    const inner_html = video_html.replace('style="padding:75% 0 0 0;position:relative;"', 'style="padding:56.25% 0 0 0;position:relative;"');

    useEffect(() => {
        const iframe = document.querySelector("iframe");
        const player = new Player(iframe);

        player.on("play", () => {
            setVideoEnded(false);
        });

        player.on("ended", () => {
            setVideoEnded(true);
        });

        return () => {
            player.off("ended");
            player.off("play");
        };
    }, [video_html]);

    return (
        <section 
            className="frame"
            style={{ 
                backgroundImage: `url(${BASE + thumbnail.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat"
            }}
        >
            <div dangerouslySetInnerHTML={{ __html: inner_html }} />
        </section>
    );
};

export default Frame;