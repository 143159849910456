import React, { useState, useEffect } from "react";
import Player from "@vimeo/player";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { SCROLL_DOWN_MOBILE_ANCHOR } from "../../../constants";
import KeyboardArrowDownLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownRight from "@mui/icons-material/KeyboardArrowRight";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./About.scss";
import "./UnderstandableMobile.scss";

const UnderstandableMobile = ({ backgroundVideosPlaying, setBackgroundVideosPlaying }) => {
    const [controllerClicked, setControllerClicked] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    const pauseAllVideos = () => {
        const players = Array.from(document.querySelectorAll(".swiper-slide iframe")).map(iframe => new Player(iframe));
        setControllerClicked(true);
        players.forEach(player => player.pause());
    }

    useEffect(() => {
        const players = Array.from(document.querySelectorAll(".swiper-slide iframe")).map(iframe => new Player(iframe));

        const playAfterPause = (currentPlayer) => {
            setTimeout(() => {
                currentPlayer.getPaused().then(isPaused => {
                    if (!controllerClicked && isPaused && backgroundVideosPlaying) {
                        currentPlayer.play();
                    }
                });
                setControllerClicked(false);
            }, 1000);
        };

        players.forEach(player => {
            const onPlay = () => {
                const topVideoPlayer = new Player(document.getElementById("top-video"));
                topVideoPlayer.pause();
                setBackgroundVideosPlaying(false);
                playAfterPause(player);
            };

            const onPause = () => {
                setBackgroundVideosPlaying(true);
            };

            player.on("play", onPlay);
            player.on("pause", onPause);

            return () => {
                player.off("play", onPlay);
                player.off("pause", onPause);
            };
        });
    }, [backgroundVideosPlaying, controllerClicked]);

    return (
        <section className="understandable-mobile about-section-mobile">
            <div id={SCROLL_DOWN_MOBILE_ANCHOR} className="scroll-point" />

            <div className="wrapper">
                <section className="dedicated-info about-block">
                    <h3>Examengericht oefenen. <span>Zo voorkom je verrassingen.</span></h3>
                    <p>Je gaat aan de slag met vragen die je kan verwachten op je theorie-examen.<br />Zo spannend is het niet meer als alles bekend voor komt. </p>
                </section>

                <section className="subtitle">
                    <h2>Begrijpelijk uitgelegd</h2>
                </section>

                <section className="summary">
                    <p>Bekijk gewoon lekker op je eigen gemakje alle video's.  We leggen alle onderdelen uit op de meest begrijpelijke en leuke manier. Zo hoef je niet in slaap te vallen tijdens het kijken.</p>
                </section>

                <section className="slider-section">
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={false}
                        slidesPerView={1}
                        spaceBetween={30}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0
                        }}
                        pagination={{ el: ".swiper-pagination", clickable: true }}
                        navigation={{
                            nextEl: ".next",
                            prevEl: ".previous",
                            clickable: true
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper-container"
                        onSlideChange={(swiper) => {
                            setSlideIndex(swiper.activeIndex);
                        }}
                    >
                        <SwiperSlide>
                            <section
                                className="video-section hazard-video-section"
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe src="https://player.vimeo.com/video/945576606?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="tj_online_cursus_nieuw_gevaarherkenning (2160p)"></iframe>`,
                                }}
                            ></section>
                        </SwiperSlide>
                        <SwiperSlide>
                            <section
                                className="video-section highway-video-section"
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe id="highway-video" src="https://player.vimeo.com/video/862483760?h=4fc03a4f8d" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
                                }}
                            ></section>
                        </SwiperSlide>
                        <SwiperSlide>
                            <section
                                className="video-section traffic-controller-video-section"
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe id="traffic-controller-video" src="https://player.vimeo.com/video/862484330?h=778d4cd04c" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
                                }}
                            ></section>
                        </SwiperSlide>

                        <div className="controller">
                            <div
                                className={"previous arrow" + (slideIndex > 0 ? "" : " disabled")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pauseAllVideos();
                                }}
                            >
                                <KeyboardArrowDownLeft />
                            </div>
                            <div
                                className={"next arrow" + (slideIndex < 2 ? "" : " disabled")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pauseAllVideos();
                                }}
                            >
                                <KeyboardArrowDownRight />
                            </div>
                            <div
                                className="swiper-pagination"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pauseAllVideos();
                                }}
                            />
                        </div>
                    </Swiper>
                </section>
            </div>
        </section>
    );
};

export default UnderstandableMobile;