import React, { useRef, useState } from "react";
import Confetti from "react-canvas-confetti";
import warming_up_hand from "../../../images/other/warming-up-hand.svg";
import warming_up_question_image from "../../../images/other/warming-up-question-image.jpg";
import "./About.scss";
import "./WarmingUpMobile.scss";

const WarmingUpMobile = () => {
    const [warmingUpAnswer, setWarmingUpAnswer] = useState("");

    const confettiRef = useRef(null);

    const makeItRain = () => {
        const confetti = confettiRef.current;

        if (confetti) {
            confetti.confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        }
    };

    return (
        <section className="warming-up-mobile about-section-mobile">
            <div className="wrapper">
                <section className="explained-info about-block">
                    <h3>Gewoon lekker duidelijk uitgelegd. <span>Onbeperkt herhalen.</span></h3>
                    <p>Een hoofdstuk niet begrepen? Check hem<br />opnieuw. Zak je voor een oefenexamen? We zijn<br />niet op school dus geniet van onbeperkte herkansingen 😉</p>
                </section>

                <section className="subtitle">
                    <h2>Geef antwoord 🫣</h2>
                </section>

                <section className="summary">
                    <p>Even opwarmen voordat het echte werk begint. Tik een antwoord aan in het scherm.</p>
                </section>

                <section className="question-section">
                    <img className="warming-up-hand-image" alt="" src={warming_up_hand} />

                    <div className="warming-up-hand-question">
                        <Confetti ref={confettiRef} style={{ height: "100%", width: "100%", position: "absolute", top: 0, left: 0, zIndex: 2 }} />
                        <div className="question">
                            <div className="text centered">De vrachtauto voor je is zijn vracht verloren.<br />Wat doe je?</div>
                        </div>

                        <img className="warming-up-question-image" alt="" src={warming_up_question_image} />

                        <div
                            className={"option" + (warmingUpAnswer === "" ? " active" : (warmingUpAnswer === "A" ? " incorrect" : ""))}
                            onClick={(e) => {
                                e.preventDefault();

                                if (warmingUpAnswer === "") {
                                    setWarmingUpAnswer("A");
                                }
                            }}
                        >
                            <div className="text centered">Niets</div>
                        </div>

                        <div
                            className={"option" + (warmingUpAnswer === "" ? " active" : " correct")}
                            onClick={(e) => {
                                e.preventDefault();

                                if (warmingUpAnswer === "") {
                                    setWarmingUpAnswer("B");
                                    makeItRain();
                                }
                            }}
                        >
                            <div className="text centered">De politie inschakelen</div>
                        </div>

                        <div
                            className={"option" + (warmingUpAnswer === "" ? " active" : (warmingUpAnswer === "C" ? " incorrect" : ""))}
                            onClick={(e) => {
                                e.preventDefault();

                                if (warmingUpAnswer === "") {
                                    setWarmingUpAnswer("C");
                                }
                            }}
                        >
                            <div className="text centered">De wegenwacht inschakelen</div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default WarmingUpMobile;