import React, { useEffect } from "react";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Contact from "../../components/home/contact/Contact";
import "./PTR.scss";


const Privacy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="ptr-page">
            <NavBarDesktop noScroll={true} />
            <NavBarMobile noScroll={true} />
            
            <div className="wrapper">
                <h1>Privacybeleid</h1>
                
                <h2>1. Wie zijn wij en wat doen we?</h2>
                <p>Onze bedrijfsnaam is Theorieja.<br />Wij bieden online cursussen aan die je helpen bij het voorbereiden op je autotheorie-examen. Ons bedrijf beschikt over leuke en innovatieve manieren om de juiste theorie aan te leren. Meer informatie over ons en onze Diensten vind je op onze website: <a href="https://theorieja.nl">https://theorieja.nl</a>.</p>
                
                <h2>2. Privacybeleid</h2>
                <p>Wij verwerken persoonsgegevens. Dit betekent alle informatie die een persoon direct of indirect kan identificeren (Persoonsgegevens). Dit is in overeenstemming met de Algemene verordening gegevensbescherming (AVG).</p>
                <p>In dit privacybeleid wordt uitgelegd welke persoonsgegevens wij verwerken en hoe wij dit doen. Het privacybeleid is van toepassing op iedereen die de Website bezoekt en die onze Diensten gebruikt.</p>
                
                <h2>3. Ben je jonger dan 16 jaar?</h2>
                <p>Onze Diensten zijn bedoeld voor gebruikers die ouder zijn dan 15 jaar. Als je jonger bent dan 16 jaar, dan heb je toestemming van je ouders/voogd nodig voor het gebruik van onze Diensten.</p>
                
                <h2>4. Welke Persoonsgegevens verwerken wij en waarvoor gebruiken wij ze?</h2>
                <p>Wij verwerken verschillende Persoonsgegevens voor verschillende doeleinden. Hieronder de volgende punten:</p>
                <p><strong>A. (Persoons) gegevens B. Doeleinden C. Grondslag</strong></p>
                <p><strong>D.</strong> De contactgegevens van de klanten:</p>
                <p><strong>E.</strong> Voor- en achternaam, adresgegevens en e-mailadres</p>
                <p>Deze gegevens worden gebruikt om:</p>
                <ul>
                    <li>Contact met je op te nemen:</li>
                    <li>Je met je naam aan te spreken:</li>
                    <li>Je te herinneren dat je een inschrijfprocedure bent gestart en wat ervoor nodig is om deze af te maken:</li>
                    <li>Jouw persoonlijke account te activeren:</li>
                    <li>Jou eventueel een e-mail te sturen over de door jou gekochte Dienst.</li>
                </ul>
                <p>Wij mogen deze Persoonsgegevens verwerken omdat deze gegevens nodig zijn om de overeenkomst tussen jou en Theorieja uit te voeren. Wij hebben ook het gerechtvaardigde belang om je te herinneren aan je bijna afgeronde inschrijfprocedure. Tevens vinden wij het klantvriendelijk om jou te ondersteunen bij je bijna afgeronde aanmeldproces.</p>
                <p><strong>F.</strong> Klantgegevens:</p>
                <p><strong>G.</strong> Accountgegevens (waaronder contactgegevens), voortgangsgegevens en gegevens met betrekking tot eerdere Diensten.</p>
                <p>Deze gegevens worden gebruikt om:</p>
                <ul>
                    <li>Te registreren welk pakket je bij ons hebt aangeschaft:</li>
                    <li>Jou de Diensten te kunnen laten gebruiken:</li>
                    <li>Jou inzicht te geven in je voortgang en je advies te geven:</li>
                    <li>Te zien welke Diensten je eerder bij ons hebt afgenomen:</li>
                    <li>Onze Diensten te optimaliseren en te vernieuwen.</li>
                </ul>
                <p>Wij mogen deze Persoonsgegevens verwerken omdat deze noodzakelijk zijn voor het uitvoeren van onze overeenkomst met jou.</p>
                <p>Op grond van ons gerechtvaardigde belang om onze Diensten te verbeteren en te optimaliseren, kunnen wij deze persoonsgegevens verwerken.</p>

                <p><strong>H.</strong> Bankgegevens van onze klanten:</p>
                <p><strong>I.</strong> Creditcardgegevens, iDEAL-gegevens en/of andere betaalgegevens.</p>
                <p><strong>J.</strong> Deze gegevens worden gebruikt om jouw betalingen voor onze Diensten te controleren, verwerken en te administreren</p>
                <p>Wij mogen deze Persoonsgegevens verwerken omdat deze gegevens noodzakelijk zijn om de overeenkomst tussen jou en Theorieja uit te voeren. Ook zijn wij wettelijk verplicht een financiële administratie bij te houden voor de Belastingdienst.</p>

                <p><strong>K.</strong> Contactgegevens via het contactformulier op de Website, per mail, telefoon of social media:</p>
                <p><strong>L.</strong> Informatie die je aan ons bekend maakt als je ons een bericht stuurt via ons contactformulier of via onze social media, bijvoorbeeld je voor- en achternaam, e-mailadres/gebruikersnaam en eventuele Persoonsgegevens die in het bericht worden opgenomen.</p>
                <p><strong>M.</strong> Deze gegevens worden gebruikt om:</p>
                <ul>
                    <li>Contact met jou op te nemen aan de hand van jouw bericht:</li>
                    <li>Onze Diensten te verbeteren, verwerken we jouw feedback.</li>
                </ul>
                <p><strong>N.</strong> We hebben het recht deze Persoonsgegevens te verwerken, omdat we een gerechtvaardigd belang hebben om deze informatie van jou te ontvangen en omdat je deze gegevens vrijwillig openbaar maakt.</p>

                <p><strong>O.</strong> Het privacybeleid van de socialmedia-pagina's worden ook door het sociale medium zelf beheerd.</p>
                <p>Hieronder kun je zien hoe ieder medium omgaat met jouw Persoonsgegevens:</p>
                <ul>
                    <li>WhatsApp: <a href="https://www.whatsapp.com/legal/" target="_blank" rel="noreferrer">Privacybeleid</a></li>
                    <li>TikTok: <a href="https://www.tiktok.com/legal/page/eea/privacy-policy/nl" target="_blank" rel="noreferrer">Privacybeleid</a></li>
                    <li>Snapchat: <a href="https://values.snap.com/nl-NL/privacy/privacy-policy" target="_blank" rel="noreferrer">Privacybeleid</a></li>
                    <li>Instagram: <a href="https://help.instagram.com/" target="_blank" rel="noreferrer">Privacyinstellingen en informatie</a></li>
                    <li>Facebook: <a href="https://www.facebook.com/privacy/policy/?entry_point=data_policy_redirect&entry=0" target="_blank" rel="noreferrer">Gegevensbeleid</a></li>
                </ul>

                <p><strong>P.</strong> Reviews, foto's en video's op de Website of via social media:</p>
                <p><strong>Q.</strong> Deze gegevens worden gebruikt om onze cursus bij anderen te promoten en naamsbekendheid te genereren. De cursist heeft het recht de geuite media te allen tijde in te trekken. Theorieja zal al het redelijke doen om hieraan gehoor te geven.</p>
                <p><strong>R.</strong> Wij mogen deze Persoonsgegevens verwerken op grond van jouw uitdrukkelijke toestemming.</p>
                <p><strong>S.</strong> Deze toestemming kan altijd weer worden ingetrokken.</p>
                <p>Theorieja zal de gegevens dan niet meer gebruiken voor dit doeleinde en zal al het redelijke doen eerdere uitingen te verwijderen.</p>

                <p><strong>T.</strong> Technische informatie:</p>
                <p><strong>U.</strong> Informatie over het apparaat dat je voor onze Diensten gebruikt, het IP-adres waarmee de tijdstippen, de locatie vanaf waar je onze Diensten gebruikt en informatie over de browser die je gebruikt.</p>
                <p><strong>V.</strong> Deze gegevens worden gebruikt om:</p>
                <p><strong>W.</strong> *Onze Website aan te passen aan jouw apparaat, zoals op een mobiele telefoon.</p>
                <p><strong>X.</strong> *En het herkennen en voorkomen van misbruik van ons platform</p>
                <p><strong>AA.</strong> Wij mogen deze Persoonsgegeven verwerken, omdat wij een gerechtvaardigd belang hebben jou een goed werkende website aan te bieden.</p>
                <p>Om misbruik van ons platform te voorkomen, hebben wij ook het gerechtvaardigde belang jouw activiteit in te loggen.</p>
                
                <h2>5. Hoe lang worden de Persoonsgegevens bewaard?</h2>
                <p>Persoonsgegevens worden bewaard zolang we deze nodig hebben voor de bovenstaande doeleinden. Wij bewaren de persoonsgegevens niet langer dan twee jaar, tenzij we wettelijk verplicht zijn zoals bij de fiscale bewaarplicht.</p>
                <p>Gegevens die wij op grond van jouw toestemming bewaren, kunnen wij eerder verwijderen, als je daar een verzoek voor indient. Voor meer informatie over het verwijderen van jouw Persoonsgegevens- en de andere rechten die je hebt over je Persoonsgegevens - de informatie staat onder 'Jouw rechten en onze contactgegevens'.</p>

                <h2>6. Worden jouw Persoonsgegevens gedeeld met anderen?</h2>
                <p>Wij maken gebruik van derde dienstverleners voor het verzenden van Sms-berichten en het hosten van ons platform etc. We geven alleen die Persoonsgegevens aan derde dienstverleners die voor hen noodzakelijk zijn om de diensten uit te voeren die zij aan ons verlenen. Deze derde dienstverleners zijn (Sub)Verwerkers in de zin van de AVG. In de Verwerkersovereenkomsten die wij met al onze (Sub)Verwerkers hebben afgesloten, staan onder andere afspraken over wat zij met jouw Persoonsgegevens mogen doen, hoe zij die moeten beveiligen en wanneer deze verwijderd moeten worden.</p>
                <p>Afgezien van het bovenstaande zullen we jouw gegevens enkel delen met derde partijen als dit noodzakelijk is of als we hiertoe wettelijk verplicht zijn.</p>

                <h2>7. Export van gegevens buiten de Europese Unie</h2>
                <p>Als een van onze (Sub)Verwerkers buiten de EU is gevestigd, kunnen wij Persoonsgegevens doorgeven buiten de EU. De Persoonsgegevens zullen alleen worden overgedragen aan landen en/of partijen die een adequaat beschermingsniveau bieden dat voldoet aan de Europese standaarden.</p>
                <p>De overdracht van gegevens buiten de EU zal altijd in overeenstemming zijn met hoofdstuk 5 van de Algemene Verordening Gegevensverwerking.</p>

                <h2>8. Algemene geaggregeerde (interpersoonlijke) gegevens</h2>
                <p>Wij kunnen jouw Persoonsgegevens omzetten in interpersoonlijke gegevens. De gegevens zullen dan volledig en onomkeerbaar geanonimiseerd en samengevoegd worden, omdat er geen identificatie meer kan plaatsvinden op basis van de gegevens. We kunnen deze data delen met handelspartners voor analysedoeleinden, het maken van demografische profielen en het verbeteren van onze diensten.</p>

                <h2>9. Hoe beschermen we jouw Persoonsgegevens?</h2>
                <p>Wij beschermen alle Persoonsgegevens die wij verwerken tegen ongeautoriseerde of onrechtmatige toegang, wijziging, openbaarmaking, gebruik en vernietiging van deze Persoonsgegevens. Om de gegevens te beschermen, nemen we de volgende technische en organisatorische maatregelen:</p>
                <ul>
                    <li>(Persoons-)gegevens zijn intern alleen beschikbaar in een afschermde omgeving, die alleen toegankelijk is voor medewerkers met een wachtwoord:</li>
                    <li>We hebben een strikt 'clean desk policy' voor onze medewerkers.</li>
                    <li>(Persoons-)gegevens zijn alleen toegankelijk via tweestaps-verificatie:</li>
                    <li>Deze medewerkers kunnen alleen de gegevens gebruiken die ze nodig hebben.</li>
                    <li>Onze medewerkers hebben al hun draagbare apparaten vergrendeld met een wachtwoord:</li>
                    <li>Er wordt regelmatig een back-up gemaakt van de afgeschermde omgeving die (persoons-)gegevens bevat:</li>
                </ul>

                <h2>10. Cookies</h2>
                <p>Onze Website maakt gebruik van cookies. Een cookie is een klein, eenvoudig tekstbestand dat op je computer kan worden geplaatst wanneer je de Website bezoekt. Dit tekstbestand identificeert je browser en/of computer. De cookie zorgt ervoor dat onze Website je browser of computer herkent wanneer je onze Website opnieuw bezoekt.</p>
                <p>Onze website maakt gebruik van de volgende soorten cookies:</p>
                <p>Functionele cookies:<br />Deze cookies zijn van cruciaal belang voor de werking van onze Website. Dankzij deze functie kun je onze Website navigeren en de daarin verwerkte functies gebruiken. Om de functionaliteit van bepaalde pagina's van de Website te optimaliseren, maakt Theorieja gebruik van deze cookies.</p>
                <p>Analytische of statistische cookies:<br />Het gebruik van analytische cookies is bedoeld om de kwaliteit en effectiviteit van de Website te beoordelen. We kunnen bijvoorbeeld zien hoeveel gebruikers de Website bezoeken en welke pagina's worden bekeken. Deze informatie helpt ons onze Website en Diensten te verbeteren. Om het surfgedrag van grote aantallen bezoekers - geanonimiseerd - te verwerken tot grafieken en patronen die ons helpen om onze Websites te verbeteren en te optimaliseren, maakt Theorieja gebruik van deze cookies.</p>
                <p>Tracking cookies:<br />Tracking-cookies volgen het klikgedrag en het surfgedrag van onze bezoekers op. Dankzij deze cookies kunnen we zien of en wanneer je jouw profiel bekijkt en of je doorklikt naar onze Website. Deze cookies kunnen worden gebruikt om advertenties te tonen die aansluiten op jouw interesses. Om de communicatie van Theorieja zowel op haar eigen site als op haar partnersites persoonlijker te maken en beter af te stemmen op de wensen van de individuele klant, maakt Theorieja gebruik van deze cookies.</p>

                <h2>11. Websites van derde partijen</h2>
                <p>Op onze Website vind je hyperlinks die je naar de websites van partners, leveranciers, adverteerders, sponsoren, licentiegevers of andere derde partijen leiden. De inhoud of de links die op deze websites verschijnen, zijn niet onze verantwoordelijkheid en we zijn ook niet verantwoordelijk voor de praktijken van websites die gelinkt zijn naar of van onze Website. Deze websites kunnen elk moment veranderen, inclusief de inhoud en de links. Deze websites kunnen hun eigen privacybeleid, policy's, gebruikersvoorwaarden en klantenbeleid hanteren. Als je andere websites bezoekt, inclusief websites die gelinkt zijn vanaf onze website, gelden daar de voorwaarden en het beleid van die website.</p>

                <h2>12. Wijzigingen in dit privacybeleid</h2>
                <p>Wij zijn voortdurend op zoek naar manieren om onze dienstverlening te verbeteren. Ons privacybeleid kan dus van tijd tot tijd veranderen. Wij zullen een melding maken van wijzigingen in ons privacybeleid op onze Website, samen met het bijgewerkte privacybeleid.</p>

                <h2>13. Jouw rechten en onze contactgegevens</h2>
                <p>Zoals vastgelegd in de AVG, heb je het recht om:</p>
                <ul>
                    <li>Een verzoek in te dienen om jouw gegevens te corrigeren of te updaten:</li>
                    <li>Bij ons bezwaar te maken tegen de verwerking van jouw gegevens:</li>
                    <li>Een verzoek in te dienen om jouw gegevens uit ons gegevensbestand te verwijderen, zonder opgaaf van redenen:</li>
                    <li>Het verwerken van jouw gegevens in te trekken. Dit is alleen van toepassing op verwerkingen die gebaseerd zijn op jouw toestemming en heeft geen invloed op de geldigheid van de verwerkingen voor het moment dat je jouw toestemming intrekt:</li>
                    <li>Een verzoek in te dienen om een kopie van alle Persoonsgegevens die we van jou verwerkt hebben. Op uw verzoek kunnen wij deze kopie ook doorsturen naar een andere databeheerder.</li>
                    <li>Een klacht in te dienen bij de Autoriteit Persoonsgegevens, als je denkt dat wij jouw gegevens onrechtmatig verwerken.</li>
                </ul>
                <p>Mocht je vragen, opmerkingen of zorgen hebben over de manier waarop wij met jouw Persoonsgegevens omgaan, dan kun je contact met ons opnemen via de onderstaande contactgegevens:</p>
                <p><strong>Theorieja</strong></p>
                <p>Johan van Hasseltweg, 40</p>
                <p>1022 WV Amsterdam</p>
                <p>T: 020 211 7879</p>
                <p>M: <a href="mailto:Info@theorieja.nl">Info@theorieja.nl</a></p>
                <p>W: <a href="https://theorieja.nl">https://theorieja.nl</a></p>
                <p>KvK-nummer: 75461005</p>
                <p>Ik ga akkoord met het privacybeleid</p>

            </div>

            <Contact />
        </div>
    );
};

export default Privacy;