import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { removeUser } from "../../features/userslice";
import { removeJWT } from "../../helpers/jwt";
import { HOME_ROUTE, NAVIGATE_TO_HOME_ROUTE_PARAMETER, SIGN_IN_ROUTE } from "../../constants";
import Loader from "../../components/core/loader/Loader";
import "./Authentication.scss";

const SignOut = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const navigateToHome = queryParams.get(NAVIGATE_TO_HOME_ROUTE_PARAMETER) === "ja";
    
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        removeJWT();
        dispatch(removeUser());
        navigate(navigateToHome ? HOME_ROUTE : SIGN_IN_ROUTE);
    }, []);

    return <Loader />;
};

export default SignOut;