import React from "react";
import "./Input.scss";

const Input = ({ chosenOption, setChosenOption }) => {
    return (
        <input
            value={chosenOption}
            placeholder="vul hier je antwoord in"
            onChange={(e) => {
                setChosenOption(e.target.value);
            }}
        />
    );
};

export default Input;