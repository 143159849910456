import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { getJWT } from "../../helpers/jwt";
import { ANALYZE_HAZARD_API, ANALYZE_ROUTE_STATE, ERROR_MESSAGE, FINISH_HAZARD_API, HAZARDS_ROUTE, HAZARD_QUESTIONS_API, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, RESET_HAZARD_API, RETRY_HAZARD_API, RETRY_ROUTE_STATE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR, UPDATE_HAZARD_ANSWER_API } from "../../constants";
import HazardPages from "../../components/practice/pages/HazardPages";
import PracticeCloseButton from "../../components/practice/practiceclosebutton/PracticeCloseButton";
import PracticeNavigation from "../../components/practice/practicenavigation/PracticeNavigation";
import Loader from "../../components/core/loader/Loader";
import "./Practice.scss";

const NUM_HAZARD_QUESTIONS = 25;
const HAZARD_SECONDS = 8;

const Hazard = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [routeState, setRouteState] = useState(location.state);
    const [questions, setQuestions] = useState([]);
    const [questionsFetched, setQuestionsFetched] = useState(false);
    const [pageIndex, setPageIndex] = useState(0);
    const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
    const [timerPlaying, setTimerPlaying] = useState(false);
    const [timerKey, setTimerKey] = useState(0);
    const [hazardResults, setHazardResults] = useState([]);

    const jwt = getJWT();

    const pages = document.querySelector(".pages");
    const timer = document.querySelector(".timer");
    const practice_navigation = document.querySelector(".practice-navigation");

    const fetchQuestions = async () => {
        try {
            const response = await fetch(routeState === ANALYZE_ROUTE_STATE
                                            ? ANALYZE_HAZARD_API : (routeState === RETRY_ROUTE_STATE
                                            ? RETRY_HAZARD_API : HAZARD_QUESTIONS_API), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify({
                    amount: NUM_HAZARD_QUESTIONS
                })
            });
    
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                setQuestions(data.questions);
                setQuestionsFetched(true);
                
                if (routeState === ANALYZE_ROUTE_STATE) {
                    switchPage(NUM_HAZARD_QUESTIONS + 1);
                } else {
                    switchPage(0);
                }

                setRouteState("");
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const finishHazard = async () => {
        try {
            const response = await fetch(FINISH_HAZARD_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                }
            });
    
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                setHazardResults(data.hazardResults);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const resetHazard = async () => {
        try {
            const response = await fetch(RESET_HAZARD_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                }
            });
    
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                // console.log("Post request succeeded:", data);
                switchPage(0);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const switchPage = (index) => {
        setPageIndex(index);

        if (pages) {
            if (index === 0 || index === NUM_HAZARD_QUESTIONS + 1) {
                timer.style.display = "none";
                practice_navigation.style.display = "none";
                setTimerPlaying(false);
            } else {
                timer.style.display = "block";
                practice_navigation.style.display = "grid";
            }

            setNextButtonEnabled(false);
        }
    };

    const nextPage = () => {
        switchPage(pageIndex + 1);
    }

    const handleEmptyAnswer = async () => {        
        try {
            const response = await fetch(UPDATE_HAZARD_ANSWER_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify({
                    question: questions[pageIndex - 1].id,
                    answer: "(leeg gelaten)"
                })
            });
    
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                nextPage();
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    }

    const resetTimer = () => {
        setTimerKey(prevKey => prevKey + 1);
        setTimerPlaying(true);
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!questionsFetched) {
            fetchQuestions();
        }
    }, [questionsFetched])

    useEffect(() => {    
        if (pages) {
            const pageArray = pages.querySelectorAll(".page");

            for (let i = 0; i < pageArray.length; i++) {
                pageArray[i].style.opacity = i === pageIndex ? "1": "0";
                pageArray[i].style.zIndex =  i === pageIndex ? "1": "0";
            }
        }
    }, [questions, pages, pageIndex]);

    useEffect(() => {
        setNextButtonEnabled(false);
        
        if (pageIndex > 0 && pageIndex < NUM_HAZARD_QUESTIONS + 1) {
            resetTimer();
        } else if (pageIndex === NUM_HAZARD_QUESTIONS + 1) {
            finishHazard();
        }
    }, [pageIndex]);

    return (
        <div className="practice">
            {!questions
                ? <main><Loader /></main>
                : <main>
                    <PracticeCloseButton
                        route={HAZARDS_ROUTE}
                    />
                    
                    <HazardPages
                        questions={questions}
                        fetchQuestions={fetchQuestions}
                        resetHazard={resetHazard}
                        pageIndex={pageIndex}
                        switchPage={switchPage}
                        setAnswer={() => {}}
                        setAnswered={setNextButtonEnabled}
                        hazardResults={hazardResults}
                    />

                    <section className="timer">
                        <CountdownCircleTimer
                            key={timerKey}
                            isPlaying={timerPlaying}
                            duration={HAZARD_SECONDS}
                            colors={["#4A7DE2"]}
                            size={96}
                            strokeWidth={8}
                            onComplete={handleEmptyAnswer}
                        >
                            {({ remainingTime }) => remainingTime}
                        </CountdownCircleTimer>
                    </section>

                    <PracticeNavigation
                        statusText={"Gevaarherkenning vraag " + pageIndex + " van " + NUM_HAZARD_QUESTIONS}
                        nextText="VOLGENDE"
                        nextFunction={nextButtonEnabled ? nextPage : null}
                    />
                </main>
            }
        </div>
    );
};

export default Hazard;