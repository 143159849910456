import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { putUser, removeUser, selectUser } from "../../features/userslice";
import { AuthContext } from "../../context/AuthContext";
import { getJWT, removeJWT } from "../../helpers/jwt";
import { FETCH_USER_API, INACTIVE_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, UNKNOWN_API_ERROR } from "../../constants";

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [reduxUser, setReduxUser] = useState(useSelector(selectUser));
    const [fetchingUser, setFetchingUser] = useState(true);

    const dispatch = useDispatch();
    const jwt = getJWT();

    const fetchUser = async (token) => {
        setFetchingUser(true);
    
        try {
            const response = await fetch(FETCH_USER_API, {
                headers: { Authorization: "Bearer " + token }
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                dispatch(putUser(data));
                setUser(data);
                setReduxUser(data);
            }
        } catch (err) {
            if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
            }

            dispatch(removeUser());
            removeJWT();
            setUser(null);
            setReduxUser(null);
        } finally {
            setFetchingUser(false);
        }
    };

    const handleSetUser = (user) => {
        setUser(user);
        setReduxUser(user);
    };

    useEffect(() => {
        if (jwt) {
            fetchUser(jwt);
        } else {
            fetchUser(null);
            setFetchingUser(false);
        }
    }, [jwt]);

    return (
        <AuthContext.Provider
            value={{
                user: user,
                fetchingUser: fetchingUser,
                reduxUser: reduxUser,
                setUser: handleSetUser
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;