import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../../../helpers/jwt";
import { BASE, ERROR_MESSAGE, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SAVE_EXAM_STATUS_API, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR, UPDATE_HAZARD_ANSWER_API } from "../../../../constants";
import Input from "./input/Input";
import Option from "./option/Option";
import default_image from "../../../../images/other/default.png";
import "./Question.scss";


const Question = ({ className, question, pageIndex, answer, setAnswer, setAnswered, addAnswer }) => {
    const [chosenOption, setChosenOption] = useState(answer);
    const [oldChosenOption, setOldChosenOption] = useState(answer);

    const navigate = useNavigate();
    const jwt = getJWT();

    const postRequest = async (url, body) => {
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify(body)
            });
    
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                setOldChosenOption(chosenOption);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    useEffect(() => {
        if (!answer) {
            setChosenOption("");
        }
    }, [pageIndex, answer]);
    
    useEffect(() => {
        setAnswer(chosenOption);

        if (setAnswered && chosenOption !== "") {
            setAnswered(true);
        }

        if (question.soort === "Gevaarherkenning") {
            if (chosenOption) {
                const body = {
                    question: question.id,
                    answer: chosenOption
                };
                
                postRequest(UPDATE_HAZARD_ANSWER_API, body);
            }
        } else if (chosenOption !== oldChosenOption && chosenOption !== "") {
            const body = {
                questions: { [question.id]: chosenOption }
            };
            
            postRequest(SAVE_EXAM_STATUS_API, body);
            addAnswer(question.id, chosenOption);
        }
    }, [question, chosenOption, oldChosenOption, setAnswer, setAnswered]);

    return (
        <div id={question.id} className={"question-page page" + (className ? " " + className : "")}>
            <section className="picture">
                <img src={question.afbeelding ? (BASE + question.afbeelding.url) : default_image} alt="" />
            </section>

            <section className="question">
                <div className="text centered">{question.vraag}</div>
            </section>

            <section className="options">
                {question.soort === "Gevaarherkenning"
                    ? <>
                        <Option 
                            label="Niets"
                            text="Niets"
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        />
                        <Option
                            label="Gas loslaten"
                            text="Gas loslaten"
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        />
                        <Option
                            label="Remmen"
                            text="Remmen"
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        />
                    </>
                    :
                question.soort === "Opties"
                    ? <>
                        {question.optieA ? <Option
                            label="A"
                            text={question.optieA}
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        /> : <div />}
                        {question.optieB ? <Option
                            label="B"
                            text={question.optieB}
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        /> : <div />}
                        {question.optieC ? <Option
                            label="C"
                            text={question.optieC}
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        /> : <div />}
                    </>
                    :
                question.soort === "Ja/Nee"
                    ? <>
                        <Option
                            label="Ja"
                            text="Ja"
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        />
                        <Option
                            label="Nee"
                            text="Nee"
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        />
                        <div />
                    </>
                    :
                question.soort === "Invul"
                    ? <>
                        <div /> 
                        <Input
                            chosenOption={chosenOption}
                            setChosenOption={setChosenOption}
                        />                   
                        <div />
                    </>
                    :
                <></>}
            </section>
        </div>
    );
};

export default Question;