import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../helpers/jwt";
import { ANALYZE_ROUTE_STATE, ERROR_MESSAGE, FINISH_HAZARD_API, HAZARD_ROUTE, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, RETRY_ROUTE_STATE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR } from "../../constants";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import Loader from "../../components/core/loader/Loader";
import passed_icon from "../../images/icons/passed-icon.svg";
import failed_icon from "../../images/icons/failed-icon.svg";
import "./Hazards.scss";

const NUM_HAZARD_QUESTIONS = 25;
const MAX_ALLOWED_INCORRECT = 13;

const Hazards = () => {
    const [hazardResults, setHazardResults] = useState([]);
    const [hazardResultsFetched, setHazardResultsFetched] = useState(false);

    const navigate = useNavigate();
    const jwt = getJWT();

    const finishHazard = async () => {
        try {
            const response = await fetch(FINISH_HAZARD_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                }
            });
    
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                setHazardResults(data.hazardResults);
                setHazardResultsFetched(true);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    useEffect(() => {
        if (!hazardResultsFetched) {
            finishHazard();
        }
    }, [hazardResultsFetched])

    return (<>
        <div className="hazards container">
            <NavMenu />
            <Header page={"Gevaarherkenning"} />

            {!hazardResultsFetched
                ? <main><Loader /></main>
                : <main>
                    <div className="hazards-wrapper">
                        <h2>{hazardResults.length > 0 ? "Recente resultaat voor Gevaarherkenning" : "Je hebt Gevaarherkenning nog niet geprobeerd"}</h2>

                        {hazardResults.length > 0
                            ? <>
                                {hazardResults.length <= MAX_ALLOWED_INCORRECT
                                    ? <img alt="Geslaagd" src={passed_icon} />
                                    : <img alt="Gezakt" src={failed_icon} />
                                }                        

                                {hazardResults.length <= MAX_ALLOWED_INCORRECT
                                    ? <p className="status">In jouw vorige poging ben je geslaagd!</p>
                                    : <p className="status">In jouw vorige poging ben je gezakt...</p>
                                }

                                <p className={hazardResults.length <= MAX_ALLOWED_INCORRECT ? "passed" : "failed"}>
                                    {hazardResults.length <= MAX_ALLOWED_INCORRECT ? "Voldoende" : "Onvoldoende"} - {hazardResults.length} van de {NUM_HAZARD_QUESTIONS} vragen fout.
                                    <br />
                                    Je mocht in dit onderdeel {MAX_ALLOWED_INCORRECT} fouten hebben.
                                </p>
                                <p>Heb je fouten gemaakt? Bekijk je fouten, leer ervan en oefen verder met Gevaarherkenning.</p>
                            </>
                            : <></>
                        }

                        <section className="buttons">
                            {hazardResults.length > 0
                                ? <>
                                    <div
                                        className="analyze-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(HAZARD_ROUTE, {
                                                state: ANALYZE_ROUTE_STATE
                                            });
                                        }}
                                    >
                                        <div className="text centered">Fouten bekijken</div>
                                    </div>
                                    <div
                                        className="retry-button"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate(HAZARD_ROUTE, {
                                                state: RETRY_ROUTE_STATE
                                            });
                                        }}
                                    >
                                    <div className="text centered">Herkansen</div>
                                    </div>
                                </>
                                : <></>
                            }
                            <div
                                className="continue-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(HAZARD_ROUTE);
                                }}
                            >
                                <div className="text centered">{hazardResults.length > 0 ? "Nieuwe poging starten" : "Gevaarherkenning starten"}</div>
                            </div>
                        </section>
                    </div>
                </main>
            }
        </div>

        <Footer />
    </>);
};

export default Hazards;