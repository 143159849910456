import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MAX_ALLOWED_PAUSES } from "../../../constants";
import close_icon from "../../../images/icons/close-icon.svg";
import "./PracticeCloseButton.scss";

const PracticeCloseButton = ({ route, warn, pauses, handleClose }) => {
    const [popupActive, setPopupActive] = useState(false);

    const navigate = useNavigate();

    if (warn) {
        return (
            <>
                <section
                    className="practice-close-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setPopupActive(true);
                    }}
                >
                    <img className="centered" alt="Stoppen met oefenen" src={close_icon} />
                </section>

                <div
                    className={"practice-close-overlay" + (popupActive ? " active" : "")}
                    onClick={(e) => {
                        e.preventDefault();
                        setPopupActive(false);
                    }}
                />

                <div className={"practice-close-popup centered" + (popupActive ? " active" : "")}>
                    {pauses === 0
                        ? <>
                            <div className="padding" />
                            <p className="red-color">
                                In totaal mag je {MAX_ALLOWED_PAUSES} keer pauzeren.
                            </p>
                            <p>Weet je zeker dat je dit examen wilt pauzeren?</p>
                        </>
                        :
                    pauses >= MAX_ALLOWED_PAUSES
                        ? <>
                            <div className="padding" />
                            <p className="red-color">
                                Je hebt dit examen al {pauses} keer gepauzeerd.
                                <br />
                                In totaal mag je {MAX_ALLOWED_PAUSES} keer pauzeren.
                            </p>
                            <p>Dit betekent dat je niet meer kunt pauzeren.</p>
                            <p>Wil je dit examen afronden?</p>
                        </>
                        : <>
                            <div className="padding" />
                            <p className="red-color">
                                Je hebt dit examen al {pauses} keer gepauzeerd.
                                <br />
                                In totaal mag je {MAX_ALLOWED_PAUSES} keer pauzeren.
                            </p>
                            <p>Weet je zeker dat je dit examen wilt pauzeren?</p>
                        </>
                    }

                    <div
                        className="yes-button"
                        onClick={(e) => {
                            e.preventDefault();

                            if (handleClose) {
                                handleClose();
                            }
                            
                            navigate(route);
                        }}
                    >
                        <div className="text centered">{pauses >= MAX_ALLOWED_PAUSES ? "Afronden" : "Pauzeren"}</div>
                    </div>
                    <div
                        className="no-button"
                        onClick={(e) => {
                            e.preventDefault();
                            setPopupActive(false);
                        }}
                    >
                        <div className="text centered">Annuleren</div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <section
            className="practice-close-button"
            onClick={(e) => {
                e.preventDefault();

                if (handleClose) {
                    handleClose();
                }
                
                navigate(route);
            }}
        >
            <img className="centered" alt="Stoppen met oefenen" src={close_icon} />
        </section>
    );
};

export default PracticeCloseButton;