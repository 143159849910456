import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../../helpers/jwt";
import { CREATE_EXAM_STATUS_API, ERROR_MESSAGE, EXAMS_ROUTE, EXAM_ROUTE, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR } from "../../../constants";
import Question from "./question/Question";
import PracticeResults from "../practiceresults/PracticeResults";
import Loader from "../../core/loader/Loader";
import passed_icon from "../../../images/icons/passed-icon.svg";
import failed_icon from "../../../images/icons/failed-icon.svg";
import "./Pages.scss";

const MAX_ALLOWED_INCORRECT_KNOWLEDGE = 2;
const MAX_ALLOWED_INCORRECT_INSIGHT = 3;
const MAX_ALLOWED_INCORRECT_OTHER = 9;

const AutoExamPages = ({ data, vehicle, fetchExam, pageIndex, switchPage, knowledge, setKnowledge, knowledgePageIndex, insight, setInsight, insightEnd, insightPageIndex, other, setOther, otherEnd, otherPageIndex, setAnswer, setAnswered, knowledgeResults, insightResults, otherResults, finishingExam }) => {
    const [answers, setAnswers] = useState({});
    const [newAnswers, setNewAnswers] = useState({});

    const navigate = useNavigate();
    const jwt = getJWT();

    const knowledgePassed = knowledgeResults.length <= MAX_ALLOWED_INCORRECT_KNOWLEDGE;
    const insightPassed = insightResults.length <= MAX_ALLOWED_INCORRECT_INSIGHT;
    const otherPassed = otherResults.length <= MAX_ALLOWED_INCORRECT_OTHER;

    const createExamStatus = async (exam) => {
        try {
            const response = await fetch(CREATE_EXAM_STATUS_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify({
                    exam: exam
                })
            });
    
            const data = await response.json();
        
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                fetchExam();
                navigate(EXAM_ROUTE, {
                    state: ""
                })
                switchPage(vehicle === "Auto" ? knowledgePageIndex : otherPageIndex);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            window.location.reload();
        }
    };

    const addAnswer = (key, value) => {
        setNewAnswers(previousAnswers => ({
            ...previousAnswers,
            [key]: value
        }));
    };

    useEffect(() => {
        if (data) {
            if (data.status) {
                let newAnswers = {};

                data.status.answers.array.forEach(answer => {
                    newAnswers[Object.keys(answer)[0]] = Object.values(answer)[0];
                });
                
                setAnswers(newAnswers);
            }
        }
    }, [data]);

    if (vehicle === "Auto") {
        return (
            <section className="pages">
                {data.status && (knowledge || insight)
                    ? <div className="continued-start-page page">
                        Je bent gebleven bij vraag
                        {knowledge
                            ? " " + (data.numKnowledgeAnswered + 1) + " "
                            : insight
                                ? " " + (data.numInsightAnswered + (insightEnd ? 0 : 1)) + " "
                                : " NaN "
                        } 
                        van het onderdeel {knowledge ? "Kennis" : insight ? "Inzicht" : "..."}
                        <div
                            className="start-button"
                            onClick={(e) => {
                                e.preventDefault();
                                switchPage(knowledge
                                    ? data.numKnowledgeAnswered + 1
                                    : insight
                                        ? insightPageIndex + data.numInsightAnswered + (insightEnd ? 0 : 1)
                                        : 0
                                );
                            }}
                        >
                            <div className="text centered">
                                {knowledge
                                    ? "Kennis "
                                    : insight
                                        ? "Inzicht "
                                        : "..."
                                }
                                onderdeel hervatten
                            </div>
                        </div>
                    </div>

                    : <div className="knowledge-start-page page">
                        <h3>Onderdeel Kennis</h3>
                        <p>Dit onderdeel bestaat uit 12 vragen over de onderwerpen:</p>
                        <ul>
                            <li>Gebruik van de weg</li>
                            <li>Voorrang en voor laten gaan</li>
                            <li>Bijzondere wegen, weggedeelten, weggebruikers en manoeuvres</li>
                            <li>Veilig rijden met het voertuig en reageren in noodsituaties</li>
                            <li>Verkeerstekens en aanwijzingen</li>
                            <li>Verantwoorde verkeersdeelname en milieubewust rijden</li>
                            <li>Wetgeving</li>
                            <li>Voertuigkennis</li>
                        </ul>
                        <p>Je mag bij dit onderdeel {MAX_ALLOWED_INCORRECT_KNOWLEDGE} fouten maken.</p>
                        <div
                            className="start-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setKnowledge(true);
                                setInsight(false);
                                switchPage(pageIndex + 1)
                            }}
                        >
                            <div className="text centered">Start Kennis</div>
                        </div>
                    </div>
                }

                {data.status ? data.knowledgeQuestions.map((question) => (
                    answers[question.id] ? <Question
                        question={question}
                        pageIndex={pageIndex}
                        answer={newAnswers[question.id] ? newAnswers[question.id] : answers[question.id]}
                        setAnswer={setAnswer}
                        setAnswered={setAnswered}
                        addAnswer={addAnswer}
                        key={question.id}
                    /> : <div key={question.id} style={{ display: "none" }} />
                )) : <></>}

                {data.status ? data.knowledgeQuestions.map((question) => (
                    answers[question.id] 
                        ? <div key={question.id} style={{ display: "none" }} />
                        : <Question
                            question={question}
                            pageIndex={pageIndex}
                            answer={newAnswers[question.id] ? newAnswers[question.id] : ""}
                            setAnswer={setAnswer}
                            setAnswered={setAnswered}
                            addAnswer={addAnswer}
                            key={question.id}
                        />
                )) : <></>}

                {!data.status ? data.knowledgeQuestions.map((question) => (
                    <Question
                        question={question}
                        pageIndex={pageIndex}
                        answer={newAnswers[question.id] ? newAnswers[question.id] : ""}
                        setAnswer={setAnswer}
                        setAnswered={setAnswered}
                        addAnswer={addAnswer}
                        key={question.id}
                    />
                )) : <></>}

                <div className="insight-start-page page block">
                    <h3>Onderdeel Inzicht</h3>
                    <p>Dit onderdeel bestaat uit 28 vragen over de onderwerpen:</p>
                    <ul>
                        <li>Gebruik van de weg</li>
                        <li>Voorrang en voor laten gaan</li>
                        <li>Bijzondere wegen, weggedeelten, weggebruikers en manoeuvres</li>
                        <li>Veilig rijden met het voertuig en reageren in noodsituaties</li>
                        <li>Verkeerstekens en aanwijzingen</li>
                        <li>Verantwoorde verkeersdeelname en milieubewust rijden</li>
                    </ul>
                    <p>Je mag bij dit onderdeel {MAX_ALLOWED_INCORRECT_INSIGHT} fouten maken.</p>
                    <div
                        className="start-button"
                        onClick={(e) => {
                            e.preventDefault();
                            setKnowledge(false);
                            setInsight(true);
                            switchPage(pageIndex + 1)
                        }}
                    >
                        <div className="text centered">Start Inzicht</div>
                    </div>
                </div>

                {data.status ? data.insightQuestions.map((question) => (
                    answers[question.id] ? <Question
                        question={question}
                        pageIndex={pageIndex}
                        answer={newAnswers[question.id] ? newAnswers[question.id] : answers[question.id]}
                        setAnswer={setAnswer}
                        setAnswered={setAnswered}
                        addAnswer={addAnswer}
                        key={question.id}
                    /> : <div key={question.id} style={{ display: "none" }} />
                )) : <></>}

                {data.status ? data.insightQuestions.map((question) => (
                    answers[question.id]
                        ? <div key={question.id} style={{ display: "none" }} />
                        : <Question
                            question={question}
                            pageIndex={pageIndex}
                            answer={newAnswers[question.id] ? newAnswers[question.id] : ""}
                            setAnswer={setAnswer}
                            setAnswered={setAnswered}
                            addAnswer={addAnswer}
                            key={question.id}
                        />
                )) : <></>}

                {!data.status ? data.insightQuestions.map((question) => (
                    <Question
                        question={question}
                        pageIndex={pageIndex}
                        answer={newAnswers[question.id] ? newAnswers[question.id] : ""}
                        setAnswer={setAnswer}
                        setAnswered={setAnswered}
                        addAnswer={addAnswer}
                        key={question.id}
                    />
                )) : <></>}

                {finishingExam
                    ? <div className="exam-complete-page page">
                        <Loader />
                    </div>
                    : <div className="exam-complete-page page">
                        <h3>Foutenanalyse</h3>

                        <section className="analytics">
                            <div className={"thumb" + (knowledgePassed && insightPassed ? " passed" : " failed")}>
                                <h4 className={knowledgePassed && insightPassed ? "green-color" : "red-color"}>
                                    {knowledgePassed && insightPassed ? "Geslaagd!" : "Gezakt..."}
                                </h4>
                                <img alt="Duim" src={knowledgePassed && insightPassed ? passed_icon : failed_icon} />
                            </div>

                            <div className={"knowledge-info" + (knowledgePassed ? " passed" : " failed")}>
                                <p>Onderdeel Kennis</p>
                                <p><span>{knowledgePassed ? "Voldoende" : "Onvoldoende"}</span> - {knowledgeResults.length} van de {data.knowledgeQuestions.length} vragen fout.</p>
                                <p>Je mocht in dit onderdeel {MAX_ALLOWED_INCORRECT_KNOWLEDGE} fouten<br />hebben.</p>
                            </div>

                            <div className={"insight-info" + (insightPassed ? " passed" : " failed")}>
                                <p>Onderdeel Inzicht</p>
                                <p><span>{insightPassed ? "Voldoende" : "Onvoldoende"}</span> - {insightResults.length} van de {data.insightQuestions.length} vragen fout.</p>
                                <p>Je mocht in dit onderdeel {MAX_ALLOWED_INCORRECT_INSIGHT} fouten<br />hebben.</p>
                            </div>

                            {knowledgePassed && insightPassed
                                ? <div className="result-info">
                                    <p><span>Gefeliciteerd! Je bent geslaagd voor dit oefenexamen.</span></p>
                                    <p>Heb je fouten gemaakt? Bekijk je fouten, leer ervan en maak het oefenexamen opnieuw.</p>
                                    <p>Pas als je alle video's hebt gezien en alle oefenexamens <span>foutloos</span> beheerst ben je klaar voor het echte examen!</p>
                                </div>
                                :  <div className="result-info">
                                    <p><span>Helaas gezakt! Je bent niet geslaagd voor dit oefenexamen.</span></p>
                                    <p>Bekijk je fouten, leer ervan en maak het oefenexamen opnieuw.</p>
                                    <p>Pas als je alle video's hebt gezien en alle oefenexamens <span>foutloos</span> beheerst ben je klaar voor het echte examen!</p>
                                </div>
                            }
                        </section>

                        <section
                            className="retry-button"
                            onClick={(e) => {
                                e.preventDefault();
                                createExamStatus(data.status.exam);
                            }}
                        >
                            <div className="text centered">Herkansen</div>
                        </section>
                        
                        <section
                            className="continue-button"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(EXAMS_ROUTE);
                            }}
                        >
                            <div className="text centered">Afsluiten</div>
                        </section>

                        <PracticeResults
                            knowledgeResults={knowledgeResults}
                            insightResults={insightResults}
                        />
                    </div>
                }
            </section>
        );
    } else {
        return (
            <section className="pages">
                {data.status && other
                    ? <div className="continued-start-page page">
                        Je bent gebleven bij vraag {data.numOtherAnswered + (otherEnd ? 0 : 1)}
                        <div
                            className="start-button"
                            onClick={(e) => {
                                e.preventDefault();
                                switchPage(otherPageIndex + data.numOtherAnswered + (otherEnd ? 0 : 1));
                            }}
                        >
                            <div className="text centered">
                                {vehicle === "Motor"
                                    ? "Motor"
                                    : vehicle === "Bromfiets"
                                        ? "Bromfiets"
                                        : "..."
                                }
                                examen hervatten
                            </div>
                        </div>
                    </div>

                    : <div className="other-start-page page">
                        <h3>{vehicle}</h3>
                        <p>Dit examen bestaat uit 50 vragen over de onderwerpen:</p>
                        <ul>
                            <li>Gebruik van de weg</li>
                            <li>Voorrang en voor laten gaan</li>
                            <li>Bijzondere wegen, weggedeelten, weggebruikers en manoeuvres</li>
                            <li>Veilig rijden met het voertuig en reageren in noodsituaties</li>
                            <li>Verkeerstekens en aanwijzingen</li>
                            <li>Verantwoorde verkeersdeelname en milieubewust rijden</li>
                            <li>Wetgeving</li>
                            <li>Voertuigkennis</li>
                        </ul>
                        <p>Je mag in totaal {MAX_ALLOWED_INCORRECT_OTHER} fouten maken.</p>
                        <div
                            className="start-button"
                            onClick={(e) => {
                                e.preventDefault();
                                setOther(true);
                                switchPage(pageIndex + 1)
                            }}
                        >
                            <div className="text centered">Start {vehicle}examen</div>
                        </div>
                    </div>
                }

                {data.status ? data.otherQuestions.map((question) => (
                    answers[question.id] ? <Question
                        question={question}
                        pageIndex={pageIndex}
                        answer={newAnswers[question.id] ? newAnswers[question.id] : answers[question.id]}
                        setAnswer={setAnswer}
                        setAnswered={setAnswered}
                        addAnswer={addAnswer}
                        key={question.id}
                    /> : <div key={question.id} style={{ display: "none" }} />
                )) : <></>}

                {data.status ? data.otherQuestions.map((question) => (
                    answers[question.id] 
                        ? <div key={question.id} style={{ display: "none" }} />
                        : <Question
                            question={question}
                            pageIndex={pageIndex}
                            answer={newAnswers[question.id] ? newAnswers[question.id] : ""}
                            setAnswer={setAnswer}
                            setAnswered={setAnswered}
                            addAnswer={addAnswer}
                            key={question.id}
                        />
                )) : <></>}

                {!data.status ? data.otherQuestions.map((question) => (
                    <Question
                        question={question}
                        pageIndex={pageIndex}
                        answer={newAnswers[question.id] ? newAnswers[question.id] : ""}
                        setAnswer={setAnswer}
                        setAnswered={setAnswered}
                        addAnswer={addAnswer}
                        key={question.id}
                    />
                )) : <></>}

                {finishingExam
                    ? <div className="exam-complete-page page">
                        <Loader />
                    </div>
                    : <div className="exam-complete-page page">
                        <h3>Foutenanalyse</h3>

                        <section className="analytics">
                            {vehicle === "Auto"
                                ? <>
                                    <div className={"thumb" + (knowledgePassed && insightPassed ? " passed" : " failed")}>
                                        <h4 className={knowledgePassed && insightPassed ? "green-color" : "red-color"}>
                                            {(knowledgePassed && insightPassed) ? "Geslaagd!" : "Gezakt..."}
                                        </h4>
                                        <img alt="Duim" src={knowledgePassed && insightPassed ? passed_icon : failed_icon} />
                                    </div>

                                    <div className={"knowledge-info" + (knowledgePassed ? " passed" : " failed")}>
                                        <p>Onderdeel Kennis</p>
                                        <p><span>{knowledgePassed ? "Voldoende" : "Onvoldoende"}</span> - {knowledgeResults.length} van de {data.knowledgeQuestions.length} vragen fout.</p>
                                        <p>Je mocht in dit onderdeel {MAX_ALLOWED_INCORRECT_KNOWLEDGE} fouten<br />hebben.</p>
                                    </div>

                                    <div className={"insight-info" + (insightPassed ? " passed" : " failed")}>
                                        <p>Onderdeel Inzicht</p>
                                        <p><span>{insightPassed ? "Voldoende" : "Onvoldoende"}</span> - {insightResults.length} van de {data.insightQuestions.length} vragen fout.</p>
                                        <p>Je mocht in dit onderdeel {MAX_ALLOWED_INCORRECT_INSIGHT} fouten<br />hebben.</p>
                                    </div>
                                </>
                                : <>
                                    <div className={"thumb" + (otherPassed ? " passed" : " failed")}>
                                        <h4 className={otherPassed ? "green-color" : "red-color"}>
                                            {(otherPassed) ? "Geslaagd!" : "Gezakt..."}
                                        </h4>
                                        <img alt="Duim" src={otherPassed ? passed_icon : failed_icon} />
                                    </div>
                                    
                                    <div className={"other-info" + (otherPassed ? " passed" : " failed")}>
                                        <p>{vehicle}examen</p>
                                        <p><span>{otherPassed ? "Voldoende" : "Onvoldoende"}</span> - {otherResults.length} van de {data.otherQuestions.length} vragen fout.</p>
                                        <p>Je mocht in dit examen {MAX_ALLOWED_INCORRECT_OTHER} fouten<br />hebben.</p>
                                    </div>
                                </>
                            }

                            {otherPassed
                                ? <div className="result-info">
                                    <p><span>Gefeliciteerd! Je bent geslaagd voor dit oefenexamen.</span></p>
                                    <p>Heb je fouten gemaakt? Bekijk je fouten, leer ervan en maak het oefenexamen opnieuw.</p>
                                    <p>Pas als je alle video's hebt gezien en alle oefenexamens <span>foutloos</span> beheerst ben je klaar voor het echte examen!</p>
                                </div>
                                :  <div className="result-info">
                                    <p><span>Helaas gezakt! Je bent niet geslaagd voor dit oefenexamen.</span></p>
                                    <p>Bekijk je fouten, leer ervan en maak het oefenexamen opnieuw.</p>
                                    <p>Pas als je alle video's hebt gezien en alle oefenexamens <span>foutloos</span> beheerst ben je klaar voor het echte examen!</p>
                                </div>
                            }
                        </section>

                        <section
                            className="retry-button"
                            onClick={(e) => {
                                e.preventDefault();
                                createExamStatus(data.status.exam);
                            }}
                        >
                            <div className="text centered">Herkansen</div>
                        </section>
                        
                        <section
                            className="continue-button"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(EXAMS_ROUTE);
                            }}
                        >
                            <div className="text centered">Afsluiten</div>
                        </section>

                        <PracticeResults
                            knowledgeResults={knowledgeResults}
                            insightResults={insightResults}
                            otherResults={otherResults}
                        />
                    </div>
                }
            </section>
        );
    }
};

export default AutoExamPages;