import React, { useState, useEffect } from "react";
import Player from "@vimeo/player";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { PACKAGES_DESKTOP_ANCHOR } from "../../../constants";
import KeyboardArrowDownLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownRight from "@mui/icons-material/KeyboardArrowRight";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./About.scss";
import "./UnderstandableDesktop.scss";

const UnderstandableDesktop = ({ backgroundVideosPlaying, setBackgroundVideosPlaying }) => {
    const [controllerClicked, setControllerClicked] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    const pauseAllVideos = () => {
        const players = Array.from(document.querySelectorAll(".swiper-slide iframe")).map(iframe => new Player(iframe));
        setControllerClicked(true);
        players.forEach(player => player.pause());
    }

    useEffect(() => {
        const players = Array.from(document.querySelectorAll(".swiper-slide iframe")).map(iframe => new Player(iframe));

        const playAfterPause = (currentPlayer) => {
            setTimeout(() => {
                currentPlayer.getPaused().then(isPaused => {
                    if (!controllerClicked && isPaused && backgroundVideosPlaying) {
                        currentPlayer.play();
                    }
                });
                setControllerClicked(false);
            }, 1000);
        };

        players.forEach(player => {
            const onPlay = () => {
                const topVideoPlayer = new Player(document.getElementById("top-video"));
                topVideoPlayer.pause();
                setBackgroundVideosPlaying(false);
                playAfterPause(player);
            };

            const onPause = () => {
                setBackgroundVideosPlaying(true);
            };

            player.on("play", onPlay);
            player.on("pause", onPause);

            return () => {
                player.off("play", onPlay);
                player.off("pause", onPause);
            };
        });
    }, [backgroundVideosPlaying, controllerClicked]);

    return (
        <section className="understandable-desktop about-section-desktop">
            <div className="wrapper">
                <div className="heading-text">Alle CBR onderdelen begrijpelijk uitgelegd.</div>

                <h1>Wie heeft er nou zin om uren uit een boek te leren? 👀</h1>

                <section className="slider-section">
                    <Swiper
                        effect={"coverflow"}
                        grabCursor={true}
                        centeredSlides={true}
                        loop={false}
                        slidesPerView={1}
                        spaceBetween={30}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0
                        }}
                        pagination={{ el: ".swiper-pagination", clickable: true }}
                        navigation={{
                            nextEl: ".next",
                            prevEl: ".previous",
                            clickable: true
                        }}
                        modules={[EffectCoverflow, Pagination, Navigation]}
                        className="swiper-container"
                        onSlideChange={(swiper) => {
                            setSlideIndex(swiper.activeIndex);
                        }}
                    >
                        <SwiperSlide>
                            <section
                                className="video-section hazard-video-section"
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe src="https://player.vimeo.com/video/945576606?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="tj_online_cursus_nieuw_gevaarherkenning (2160p)"></iframe>`,
                                }}
                            ></section>
                        </SwiperSlide>
                        <SwiperSlide>
                            <section
                                className="video-section highway-video-section"
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe id="highway-video" src="https://player.vimeo.com/video/862483760?h=4fc03a4f8d" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
                                }}
                            ></section>
                        </SwiperSlide>
                        <SwiperSlide>
                            <section
                                className="video-section traffic-controller-video-section"
                                dangerouslySetInnerHTML={{
                                    __html: `<iframe id="traffic-controller-video" src="https://player.vimeo.com/video/862484330?h=778d4cd04c" width="640" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`,
                                }}
                            ></section>
                        </SwiperSlide>

                        <div className="controller">
                            <div
                                className={"previous arrow" + (slideIndex > 0 ? "" : " disabled")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pauseAllVideos();
                                }}
                            >
                                <KeyboardArrowDownLeft />
                            </div>
                            <div
                                className={"next arrow" + (slideIndex < 2 ? "" : " disabled")}
                                onClick={(e) => {
                                    e.preventDefault();
                                    pauseAllVideos();
                                }}
                            >
                                <KeyboardArrowDownRight />
                            </div>
                            <div
                                className="swiper-pagination"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pauseAllVideos();
                                }}
                            />
                        </div>
                    </Swiper>
                </section>

                <section className="we-got-you-section">
                    <h3>We got you!</h3>
                    <p>Volg les en oefen met examens over alle CBR-onderdelen die je nodig hebt om gewoon lekker te slagen. Op je laptop, tablet of smartphone. Relaxed hè?</p>
                    <div
                        className="we-got-you-button"
                        onClick={(e) => {
                            e.preventDefault();
                            document.getElementById(PACKAGES_DESKTOP_ANCHOR).scrollIntoView({ behavior: "smooth" });
                        }}
                    >
                        <div className="text centered">STARTEN</div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default UnderstandableDesktop;