import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { getJWT, setJWT } from "../../helpers/jwt";
import { checkEmail, checkPassword, checkPasswordConfirmation } from "../../helpers/formChecks";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import Loader from "../../components/core/loader/Loader";
import { CHANGED_PROFILE_MESSAGE, CHANGED_PASSWORD_MESSAGE, CHANGE_PROFILE_API, CHANGE_PROFILE_BUTTON, CHANGE_PASSWORD_API, CHANGE_PASSWORD_BUTTON, CITY_PLACEHOLDER, ERROR_MESSAGE, FIRST_NAME_PLACEHOLDER, HOUSENUMBER_PLACEHOLDER, LAST_NAME_PLACEHOLDER, EMAIL_PLACEHOLDER, NEW_PASSWORD_CONFIRMATION_PLACEHOLDER, NEW_PASSWORD_PLACEHOLDER, OLD_PASSWORD_PLACEHOLDER, POSTAL_CODE_PLACEHOLDER, STREET_PLACEHOLDER, TELEPHONE_NUMBER_PLACEHOLDER, USE_LICENSE_CODE_BUTTON, CHANGE_PROFILE_HEADING, CHANGE_PASSWORD_HEADING, CODE_HEADING, USE_LICENSE_CODE_API, LICENSE_CODE_PLACEHOLDER, UNKNOWN_API_ERROR, PACKAGE_EXTENDED_MESSAGE, UNAUTHORIZED_API_ERROR, NO_JWT_API_ERROR, UNAUTHORIZED_MESSAGE, SIGN_OUT_ROUTE, INACTIVE_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, INVALID_LICENSE_CODE_API_ERROR, INVALID_LICENSE_CODE_MESSAGE, EMAIL_TAKEN_API_ERROR, PROFILE_CHANGE_EMAIL_TAKEN_MESSAGE, CHANGE_PASSWORD_INVALID_API_ERROR, CHANGE_PASSWORD_INVALID_MESSAGE, CHANGE_PASSWORD_NOT_DIFFERENT_API_ERROR, CHANGE_PASSWORD_NOT_DIFFERENT_MESSAGE } from "../../constants";
import "./Profile.scss";


const Profile = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [emailCheck, setEmailCheck] = useState("");
    const [telephoneNumber, setTelephoneNumber] = useState("");
    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [changingProfile, setChangingProfile] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordCheck, setOldPasswordCheck] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordCheck, setNewPasswordCheck] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [newPasswordConfirmationCheck, setNewPasswordConfirmationCheck] = useState("");
    const [changingPassword, setChangingPassword] = useState(false);
    const [licenseCode, setLicenseCode] = useState("");
    const [usingLicenseCode, setUsingLicenseCode] = useState(false);
    const [packageExpired, setPackageExpired] = useState(false);
    const [packagePaused, setPackagePaused] = useState(false);

    const { user, fetchingUser, reduxUser, setUser } = useAuthContext();
    const navigate = useNavigate();
    const jwt = getJWT();

    const resetFields = () => {
        setOldPassword("");
        setOldPasswordCheck("");
        setNewPassword("");
        setNewPasswordConfirmation("");
        setLicenseCode("");
        setEmailCheck("");
        setNewPasswordCheck("");
        setNewPasswordConfirmationCheck("");
    }

    const useLicenseCode = async (e) => {
        e.preventDefault();
        setUsingLicenseCode(true);

        try {
            const response = await fetch(USE_LICENSE_CODE_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({
                    code: licenseCode
                })
            });
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                message.success(PACKAGE_EXTENDED_MESSAGE);
                window.location.reload();
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INVALID_LICENSE_CODE_API_ERROR) {
                message.error(INVALID_LICENSE_CODE_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }

            setUsingLicenseCode(false);
        } finally {
            setLicenseCode("");
        }
    };

    const changeProfile = async (e) => {
        e.preventDefault();
        setChangingProfile(true);

        try {
            const jwt = getJWT();
            const response = await fetch(CHANGE_PROFILE_API, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    telephoneNumber: telephoneNumber,
                    street: street,
                    houseNumber: houseNumber,
                    postalCode: postalCode,
                    city: city
                })
            });
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                message.success(CHANGED_PROFILE_MESSAGE);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INVALID_LICENSE_CODE_API_ERROR) {
                message.error(INVALID_LICENSE_CODE_MESSAGE);
            } else if (err === EMAIL_TAKEN_API_ERROR) {
                message.error(PROFILE_CHANGE_EMAIL_TAKEN_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            resetFields();
            setChangingProfile(false);
        }
    };

    const changePassword = async (e) => {
        e.preventDefault();
        setChangingPassword(true);

        try {
            const jwt = getJWT();
            const response = await fetch(CHANGE_PASSWORD_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({
                    currentPassword: oldPassword,
                    password: newPassword,
                    passwordConfirmation: newPasswordConfirmation
                })
            });
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                setJWT(data.jwt);
                setUser(data.user);
                message.success(CHANGED_PASSWORD_MESSAGE);
                resetFields();
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err.message === CHANGE_PASSWORD_INVALID_API_ERROR) {
                message.error(CHANGE_PASSWORD_INVALID_MESSAGE);
                setOldPasswordCheck(CHANGE_PASSWORD_INVALID_MESSAGE);
            } else if (err.message === CHANGE_PASSWORD_NOT_DIFFERENT_API_ERROR) {
                message.error(CHANGE_PASSWORD_NOT_DIFFERENT_MESSAGE);
                setNewPasswordCheck(CHANGE_PASSWORD_NOT_DIFFERENT_MESSAGE);
                setOldPassword("");
                setOldPasswordCheck("");
                setNewPassword("");
                setNewPasswordConfirmation("");
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setChangingPassword(false);
        }
    };

    useEffect(() => {
        if (user) {
            setFirstName(user.voornaam ? user.voornaam : "");
            setLastName(user.achternaam ? user.achternaam : "");
            setEmail(user.email);
            setTelephoneNumber(user.telefoonnummer ? user.telefoonnummer : "");
            setStreet(user.straatnaam ? user.straatnaam : "");
            setHouseNumber(user.huisnummer ? user.huisnummer : "");
            setPostalCode(user.postcode ? user.postcode : "");
            setCity(user.stad ? user.stad : "");
        }
    }, [user]);

    useEffect(() => {        
        const currentDate = new Date();
        const packageEnd = new Date(user ? (user.huidigVoertuig === "Auto" ? user.autoToegang : (user.huidigVoertuig === "Motor" ? user.motorToegang : null))
                                    : (reduxUser.huidigVoertuig === "Auto" ? reduxUser.autoToegang : (reduxUser.huidigVoertuig === "Motor" ? reduxUser.motorToegang : null)));
        const difference = packageEnd - currentDate;
        const packagePaused = user ? user.pakketGepauzeerd : reduxUser.pakketGepauzeerd;

        setPackageExpired(difference <= 0);
        setPackagePaused(packagePaused);
    }, [user, reduxUser]);

    return (<>
        <div className="profile container">
            <NavMenu packageExpired={packageExpired} packagePaused={packagePaused} />
            <Header page={"Jouw gegevens"} />
            
            {fetchingUser || changingProfile || changingPassword || usingLicenseCode
                ? <main><Loader /></main>
                : <main>
                    <form className="profile-form" onSubmit={changeProfile}>
                        <h1>{CHANGE_PROFILE_HEADING}</h1>

                        <input
                            type="text"
                            value={firstName}
                            placeholder={FIRST_NAME_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setFirstName(e.target.value);
                            }}
                        />
                        <p className="error" />
                        
                        <input
                            type="text"
                            value={lastName}
                            placeholder={LAST_NAME_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setLastName(e.target.value);
                            }}
                        />
                        <p className="error" />

                        <input
                            type="email"
                            value={email}
                            placeholder={EMAIL_PLACEHOLDER}
                            autoComplete=""
                            onChange={(e) => {
                                e.preventDefault();
                                setEmail(e.target.value.toLowerCase());
                                checkEmail(e.target.value, setEmailCheck);
                            }}
                            required
                        />
                        <p className="error">{emailCheck}</p>

                        <input
                            type="text"
                            value={telephoneNumber}
                            placeholder={TELEPHONE_NUMBER_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setTelephoneNumber(e.target.value);
                            }}
                        />
                        <p className="error" />

                        <input
                            type="text"
                            value={street}
                            placeholder={STREET_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setStreet(e.target.value);
                            }}
                        />
                        <p className="error" />
                        
                        <input
                            type="text"
                            value={houseNumber}
                            placeholder={HOUSENUMBER_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setHouseNumber(e.target.value);
                            }}
                        />
                        <p className="error" />
                        
                        <input
                            type="text"
                            value={postalCode}
                            placeholder={POSTAL_CODE_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setPostalCode(e.target.value);
                            }}
                        />
                        <p className="error" />
                        
                        <input
                            type="text"
                            value={city}
                            placeholder={CITY_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setCity(e.target.value);
                            }}
                        />
                        <p className="error" />

                        {emailCheck || !email
                            ? <button
                                type="submit"
                                disabled
                            >
                                {CHANGE_PROFILE_BUTTON}
                            </button>
                            : <button
                                type="submit"
                            >
                                {CHANGE_PROFILE_BUTTON}
                            </button>
                        }
                    </form>

                    <form className="password-form" onSubmit={changePassword}>
                        <h1>{CHANGE_PASSWORD_HEADING}</h1>

                        <input
                            type="text"
                            name="email"
                            autoComplete=""
                            style={{ display: "none" }}
                        />

                        <input
                            type="password"
                            placeholder={OLD_PASSWORD_PLACEHOLDER}
                            autoComplete=""
                            onChange={(e) => {
                                setOldPassword(e.target.value);
                                setOldPasswordCheck("");
                            }}
                        />
                        <p className="error">{oldPasswordCheck}</p>

                        <input
                            type="password"
                            value={newPassword}
                            placeholder={NEW_PASSWORD_PLACEHOLDER}
                            autoComplete=""
                            onChange={(e) => {
                                e.preventDefault();
                                setNewPassword(e.target.value);
                                checkPassword(e.target.value, setNewPasswordCheck);
                            }}
                            required
                        />
                        <p className="error">{newPasswordCheck}</p>
            
                        <input
                            type="password"
                            value={newPasswordConfirmation}
                            placeholder={NEW_PASSWORD_CONFIRMATION_PLACEHOLDER}
                            autoComplete=""
                            onChange={(e) => {
                                e.preventDefault();
                                setNewPasswordConfirmation(e.target.value);
                                checkPasswordConfirmation(newPassword, e.target.value, setNewPasswordConfirmationCheck);
                            }}
                            required
                        />
                        <p className="error">{newPasswordConfirmationCheck}</p>

                        {newPasswordCheck || newPasswordConfirmationCheck || !newPassword || !newPasswordConfirmation
                            ? <button
                                type="submit"
                                disabled
                            >
                                {CHANGE_PASSWORD_BUTTON}
                            </button>
                            : <button
                                type="submit"
                            >
                                {CHANGE_PASSWORD_BUTTON}
                            </button>
                        }
                    </form>
                    
                    <form className="code-form" onSubmit={useLicenseCode}>
                        <h1>{CODE_HEADING}</h1>

                        <input
                            type="text"
                            value={licenseCode}
                            placeholder={LICENSE_CODE_PLACEHOLDER}
                            onChange={(e) => {
                                e.preventDefault();
                                setLicenseCode(e.target.value);
                            }}
                        />
                        <p className="error" />

                        {!licenseCode
                            ? <button
                                type="submit"
                                disabled
                            >
                                {USE_LICENSE_CODE_BUTTON}
                            </button>
                            : <button
                                type="submit"
                            >
                                {USE_LICENSE_CODE_BUTTON}
                            </button>
                        }
                    </form>
                </main>
            }
        </div>

        <Footer />
    </>);
};

export default Profile;