import React from "react";
import { useAuthContext } from "../../../context/AuthContext";
import play_icon from "../../../images/icons/play-icon.svg";
import pause_icon from "../../../images/icons/pause-icon.svg";
import passed_icon from "../../../images/icons/passed-icon.svg";
import failed_icon from "../../../images/icons/failed-icon.svg";
import lock_icon from "../../../images/icons/lock-icon.svg";
import "./ExamList.scss";

const ExamList = ({ statusses, statusIndex, setStatusIndex, updateCurrentStatus }) => {
    const { user, reduxUser } = useAuthContext();

    const changeStatus = (e, index) => {
        const buttons = document.querySelector(".exam-list").getElementsByClassName("exam-button");
        const clickedButton = e.target.className === "text" ? e.target.parentNode : e.target;

        if (!clickedButton.classList.contains("active")) {
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].classList.remove("active");
            }

            clickedButton.classList.add("active");
            setStatusIndex(index);
        }
    }

    return (
        <section className="exam-list">
            <div className="exam-list-top">
                <h2 className="centered">Examens</h2>
            </div>
            <div className="list">
                {statusses.map((status, i) => (
                    <div
                        key={i}
                        className={"exam-button" + (statusIndex === i ? " active" : "") + ((!reduxUser.premium && status.exam.premium) ? " locked" : "")}
                        onClick={(e) => {
                            e.preventDefault();

                            if ((!user.premium || !reduxUser.premium) && status.exam.premium) {
                                document.getElementById("premium-item-clicked-button").click();
                            } else {
                                updateCurrentStatus(status);
                                changeStatus(e, i);
                            }
                        }}
                    >
                        {(!user.premium || !reduxUser.premium) && status.exam.premium
                            ? <img alt="Examen niet toegestaan" src={lock_icon} />
                            : status.finished
                                ? (status.exam.voertuig === "Auto" && status.knowledgePassed && status.insightPassed) || (status.exam.voertuig !== "Auto" && status.otherPassed)
                                    ? <img alt="Geslaagd" src={passed_icon} />
                                    : <img alt="Gezakt" src={failed_icon} />
                                : (status.exam.voertuig === "Auto" && status.numKnowledgeAnswered > 0) || (status.exam.voertuig !== "Auto" && status.numOtherAnswered > 0)
                                    ? <img alt="Gepauzeerd" src={pause_icon} />
                                    : <img alt="Examen starten" src={play_icon} />
                        }
                        <div className="text">{status.exam.titel}</div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ExamList;