import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { CONTACT_ANCHOR, HOME_ROUTE, REGISTER_ROUTE, PACKAGES_MOBILE_ANCHOR, PACKAGES_ROUTE_PARAMETER, SIGN_IN_ROUTE, DASHBOARD_ROUTE } from "../../../constants";
import logo from "../../../images/logos/logo.svg";
import "./NavBar.scss";
import "./NavBarMobile.scss";

const SCROLLED_TO_TOP_THRESHOLD = 50;

const NavBarMobile = ({ noScroll }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const scrollToPackages = queryParams.get(PACKAGES_ROUTE_PARAMETER) === "ja";

    const [scrolledToTop, setScrolledToTop] = useState(true);
    const [active, setActive] = useState(false);

    const navigate = useNavigate();
    const { user, reduxUser } = useAuthContext();

    const scrollTo = (anchor) => {
        document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY < SCROLLED_TO_TOP_THRESHOLD && !noScroll) {
                setScrolledToTop(true);
            } else {
                setScrolledToTop(false);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [noScroll]);

    useEffect(() => {
        if (scrollToPackages) {
            setTimeout(() => {
                scrollTo(PACKAGES_MOBILE_ANCHOR);
            }, 100);
        }
    }, [scrollToPackages]);

    return (
        <nav className={"nav-bar-mobile" + (scrolledToTop && !noScroll ? " scrolled-to-top" : "")}>
            <div className="logo" >
                <img
                    alt="Logo"
                    src={logo}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(HOME_ROUTE);
                    }}
                />
            </div>

            <div className="links">
                <div
                    className="link order-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(false);

                        if (scrollToPackages) {
                            scrollTo(PACKAGES_MOBILE_ANCHOR);
                        } else {
                            navigate(HOME_ROUTE + "?" + PACKAGES_ROUTE_PARAMETER + "=ja");
                        }
                    }}
                >
                    <div className="text centered">BESTELLEN</div>
                </div>
                <div
                    className={"link" + (user || reduxUser ? " dashboard-button" : " sign-in-button")}
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(false);
                        navigate(user || reduxUser ? DASHBOARD_ROUTE : SIGN_IN_ROUTE)
                    }}
                >
                    <div className="text centered">{user || reduxUser ? "DASHBOARD" : "INLOGGEN"}</div>
                </div>
            </div>

            <div
                onClick={(e) => {
                    e.preventDefault();
                    setActive(!active);
                }}
                className={"hamburger" + (active ? " active" : "")}
            >
                <div className="line top-line"></div>
                <div className="line middle-line"></div>
                <div className="line bottom-line"></div>
            </div>

            <div className={"menu" + (active ? " active" : "") + (user || reduxUser ? " signed-in" : "")}>
                <div
                    className="link license-code-link"
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(false);
                        navigate(REGISTER_ROUTE);
                    }}
                >
                <div className="text centered">Ik heb een licentiecode</div>
                </div>
                <div
                    className="link contact-link"
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(false);
                        scrollTo(CONTACT_ANCHOR)
                    }}
                >
                    <div className="text centered">Contact</div>
                </div>
            </div>
        </nav>
    );
};

export default NavBarMobile;