import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./PracticeNavigation.scss";

const PracticeNavigation = ({ previousText, previousFunction, statusText, nextText, nextFunction }) => {
    return (
        <section className="practice-navigation">
            {previousText
                ? <div
                    className={"previous-button" + (previousFunction ? "" : " disabled")}
                    onClick={(e) => {
                        e.preventDefault();

                        if (previousFunction) {
                            previousFunction();
                        }
                    }}
                >
                    <ArrowBackIosIcon />
                    <div className="text">{previousText}</div>
                </div>
                : <></>
            }
            {statusText
                ? <div className="status" >
                    <div className="text">{statusText}</div>
                </div>
                : <></>
            }
            {nextText
                ? <div
                    className={"next-button" + (nextFunction ? "" : " disabled")}
                    onClick={(e) => {
                        e.preventDefault();

                        if (nextFunction) {
                            nextFunction();
                        }
                    }}
                >
                    <ArrowForwardIosIcon />
                    <div className="text">{nextText}</div>
                </div>
                : <></>
            }
        </section>
    );
};

export default PracticeNavigation;