import React from "react";
import { CONTACT_ANCHOR, HOME_ROUTE, PRIVACY_ROUTE, RESTITUTION_ROUTE, TERMS_ROUTE } from "../../../constants";
import logo_icon from "../../../images/logos/logo-icon.svg";
import shadow_icon from "../../../images/icons/shadow-icon.png";
import youtube_icon from "../../../images/icons/youtube-icon.svg";
import tiktok_icon from "../../../images/icons/tiktok-icon.svg";
import instagram_icon from "../../../images/icons/instagram-icon.svg";
import snapchat_icon from "../../../images/icons/snapchat-icon.svg";
import facebook_icon from "../../../images/icons/facebook-icon.svg";
import "./Contact.scss";

const Contact = () => {
    return (
        <footer className="home-footer">
            <div id={CONTACT_ANCHOR} className="scroll-point" />
            
            <section className="left">
                <div className="logo">
                    <img className="logo-icon" alt="Logo" src={logo_icon} />
                    <img className="shadow-icon" alt="Schaduw" src={shadow_icon} />
                </div>
            </section>
            <section className="middle">
                <div className="info">
                    <p className="question">Heb je een vraag?</p>
                    <p>Ons team staat 7 dagen in de week voor je klaar.</p>
                    <p>Je kan ons zowel bellen als appen op hetzelfde nummer: <a href="tel:+31202117879">+31 20 211 78 79</a></p>
                    <p>Liever mailen? Stuur een mail naar: <a href="mailto:info@theorieja.nl">info@theorieja.nl</a></p>
                </div>
            </section>
            <section className="right">
                <div className="social-media">
                    <a href="https://www.snapchat.com/add/theorieja" target="_blank" rel="noreferrer">
                        <img className="snapchat-icon" alt="Snapchat" src={snapchat_icon} />
                    </a>
                    <a href="https://www.tiktok.com/@theorieja" target="_blank" rel="noreferrer">
                        <img className="tiktok-icon" alt="TikTok" src={tiktok_icon} />
                    </a>
                    <a href="https://www.facebook.com/theorieja.nl/" target="_blank" rel="noreferrer">
                        <img className="facebook-icon" alt="Facebook" src={facebook_icon} />
                    </a>
                    <a href="https://www.instagram.com/theorieja/" target="_blank" rel="noreferrer">
                        <img className="instagram-icon" alt="Instagram" src={instagram_icon} />
                    </a>
                    <a href="https://www.youtube.com/@theorieja" target="_blank" rel="noreferrer">
                        <img className="youtube-icon" alt="YouTube" src={youtube_icon} />
                    </a>
                </div>
            </section>
            <section className="copyright">
                <div className="footer-links">
                    <a className="privacy" href={PRIVACY_ROUTE}>Privacybeleid</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="terms" href={TERMS_ROUTE}>Algemene voorwaarden</a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="refund" href={RESTITUTION_ROUTE}>Restitutiebeleid</a>
                </div>
                <p>© <a href={HOME_ROUTE}>Theorieja</a> {new Date().getFullYear()}</p>
            </section>
        </footer>
    );
};

export default Contact;