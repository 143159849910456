import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../helpers/jwt";
import { useAuthContext } from "../../context/AuthContext";
import useFetch from "../../hooks/useFetch";
import { ERROR_MESSAGE, FIRST_TIME_API, INACTIVE_JWT_API_ERROR, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR, UPDATE_FIRST_TIME_API } from "../../constants";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import ExamsBlock from "../../components/dashboard/examsblock/ExamsBlock";
import NewsBlock from "../../components/dashboard/newsblock/NewsBlock";
import Loader from "../../components/core/loader/Loader";
import ContactBlock from "../../components/dashboard/contactblock/ContactBlock";
import CloseIcon from "@mui/icons-material/Close";
import preface_warning from "../../images/other/preface-warning.svg";
import "./Dashboard.scss"

const Dashboard = () => {
    const [prefaceWarning, setPrefaceWarning] = useState(false);
    const [prefaceVideo, setPrefaceVideo] = useState(false);
    
    const { reduxUser } = useAuthContext();
    const { data, loading } = useFetch(FIRST_TIME_API, {}, true);
    const navigate = useNavigate();
    const jwt = getJWT();

    const updateFirstTime = async () => {
        try {
            const response = await fetch(UPDATE_FIRST_TIME_API, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
            });
    
            const data = await response.json();

            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const getGreeting = () => {
        const hour = new Date().getHours();
    
        if (hour < 12) {
            return "Goedemorgen";
        } else if (hour < 18) {
            return "Goedemiddag";
        } else {
            return "Goedenavond";
        }
    };

    useEffect(() => {
        if (data?.preface) {
            setPrefaceVideo(true);
        }
    }, [data]);

    if (loading) {
        return <Loader />;
    }

    return (<>
        {!(prefaceWarning || prefaceVideo)
            ? <>
                <div className={"dashboard container"}>
                    <NavMenu />
                    <Header page="Dashboard" />

                    <main>
                        <h1>{getGreeting() + " " + (reduxUser.voornaam ? reduxUser.voornaam : "cursist") + ", welkom!"}</h1>
                        <ExamsBlock />
                        <NewsBlock />
                        <ContactBlock />
                    </main>
                </div>
                
                <Footer />
            </>
            : <>
                <div className="preface-overlay" />
                
                <div
                    className={"preface-warning" + (prefaceWarning ? " visible" : "")}
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className="wrapper">
                        <img className="preface-warning-image centered" alt="Voorwoord" src={preface_warning} />
                        <h3>Hi cursist!<br />Welkom op de nieuwe online training.</h3>
                        <p>Bedankt voor het vertrouwen in ons! Ga nu terug naar de live en laat weten dat je besteld hebt. Daarna kun je direct aan de slag met oefenen!</p>
                    </div>

                    <CloseIcon
                        className="close-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            setPrefaceWarning(false);
                            setPrefaceVideo(true);
                        }}
                    />
                </div>
                
                <div
                    className={"preface-video" + (prefaceVideo ? " visible" : "")}
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <h1>Hi bikkel!<br />Speel de video af en begin daarna met de online training.</h1>
                    <div
                        className="video"
                        dangerouslySetInnerHTML={{
                            __html: `<iframe src="https://player.vimeo.com/video/945578311?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="BEDANKT_VOOR_AANKOOP_MOTOR"></iframe>`,
                        }}
                    ></div>

                    <CloseIcon
                        className="close-icon"
                        onClick={(e) => {
                            e.preventDefault();
                            setPrefaceVideo(false);
                            updateFirstTime();
                        }}
                    />
                </div>
            </>
        }
        
    </>);
};

export default Dashboard;