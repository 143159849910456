import React from "react";
import useFetch from "../../../hooks/useFetch";
import { MESSAGES_API } from "../../../constants";
import Loader from "../../core/loader/Loader";
import "./NewsBlock.scss";

const NewsBlock = () => {
    const { data } = useFetch(MESSAGES_API, {}, true);

    return (
        <section className="news-block">
            {!data
                ? <Loader />
                : <>
                    <div className="notification" />
                    <h2>Berichten van<br />Theorieja</h2>
                    <div className="news-message" dangerouslySetInnerHTML={{ __html: data.messages.tekst }} />
                </>
            }
        </section>
    );
};

export default NewsBlock;