import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { DASHBOARD_ROUTE, SIGN_IN_ROUTE } from "../../constants";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import logo_icon from "../../images/logos/logo-icon.svg";
import "./PackageDelayed.scss";


const PackageDelayed = () => {
    const navigate = useNavigate();
    const { user, fetchingUser } = useAuthContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!user && !fetchingUser) {
            navigate(SIGN_IN_ROUTE);
        }

        if (user) {
            if (!user.vertraging || (new Date(user.vertraging)) < new Date()) {
                navigate(DASHBOARD_ROUTE);
            }
        }
    }, [user, fetchingUser]);

    return (<>
        <div className="package-delayed container">
            <NavMenu packageExpired={false} packagePaused={false} packageDelayed={true} />
            <Header page="Reageer in de live" />

            <main>
                <img className="logo-icon" alt="Logo" src={logo_icon} />
                <section className="wrapper">
                    <h2>Ga terug naar de live en laat met een comment weten dat je een pakket hebt aangeschaft zodat jouw account geactiveerd kan worden!</h2>
                </section>
            </main>
        </div>

        <Footer />
    </>);
};

export default PackageDelayed;