import React, { useEffect, useState } from "react";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Top from "../../components/home/top/Top";
// import DemoPackage from "../../components/home/about/DemoPackage";
import OnlinePracticeDesktop from "../../components/home/about/OnlinePracticeDesktop";
import PackagesDesktop from "../../components/home/about/PackagesDesktop";
import WarmingUpDesktop from "../../components/home/about/WarmingUpDesktop";
import UnderstandableDesktop from "../../components/home/about/UnderstandableDesktop";
import ValuesDesktop from "../../components/home/about/ValuesDesktop";
import UnderstandableMobile from "../../components/home/about/UnderstandableMobile";
import KnowledgeInsightMobile from "../../components/home/about/KnowledgeInsightMobile";
import WarmingUpMobile from "../../components/home/about/WarmingUpMobile";
import ResultsAnalysisMobile from "../../components/home/about/ResultsAnalysisMobile";
import PackagesMobile from "../../components/home/about/PackagesMobile";
import Reviews from "../../components/home/reviews/Reviews";
import Contact from "../../components/home/contact/Contact";
import "./Home.scss";


const Home = () => {
    const [backgroundVideosPlaying, setBackgroundVideosPlaying] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="home">
            <NavBarDesktop />
            <NavBarMobile />
            <Top backgroundVideosPlaying={backgroundVideosPlaying} />
            <OnlinePracticeDesktop />
            <PackagesDesktop />
            {/* <DemoPackage /> */}
            <WarmingUpDesktop />
            <UnderstandableDesktop backgroundVideosPlaying={backgroundVideosPlaying} setBackgroundVideosPlaying={setBackgroundVideosPlaying} />
            <ValuesDesktop />
            <UnderstandableMobile backgroundVideosPlaying={backgroundVideosPlaying} setBackgroundVideosPlaying={setBackgroundVideosPlaying} />
            <KnowledgeInsightMobile />
            <WarmingUpMobile />
            <ResultsAnalysisMobile />
            <PackagesMobile />
            <Reviews />
            <Contact />
        </div>
    );
};

export default Home;