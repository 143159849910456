import React from "react";
import ReactDOM from "react-dom/client";
import { message } from "antd";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./app/store";
import AuthProvider from "./components/core/AuthProvider";
import App from "./App";
import Purchase from "./routes/authentication/Purchase";
import Register from "./routes/authentication/Register";
import SignIn from "./routes/authentication/SignIn";
import SignOut from "./routes/authentication/SignOut";
import ForgotPassword from "./routes/authentication/ForgotPassword";
import ResetPassword from "./routes/authentication/ResetPassword";
import AuthProtector from "./components/core/AuthProtector";
import Privacy from "./routes/ptr/Privacy";
import Terms from "./routes/ptr/Terms";
import Restitution from "./routes/ptr/Restitution";
import Dashboard from "./routes/dashboard/Dashboard";
import Videos from "./routes/videos/Videos";
import Hazards from "./routes/hazards/Hazards";
import Hazard from "./routes/practice/Hazard";
import Exams from "./routes/exams/Exams";
import AutoExam from "./routes/practice/AutoExam";
import Downloads from "./routes/downloads/Downloads";
import Profile from "./routes/profile/Profile";
import Orders from "./routes/orders/Orders";
import PackageExpired from "./routes/packageexpired/PackageExpired";
import PackagePaused from "./routes/packagepaused/PackagePaused";
import PackageDelayed from "./routes/packagedelayed/PackageDelayed";
import { HAZARD_ROUTE, DASHBOARD_ROUTE, EXAMS_ROUTE, EXAM_ROUTE, FORGOT_PASSWORD_ROUTE, HOME_ROUTE, PROFILE_ROUTE, RESET_PASSWORD_ROUTE, SIGN_IN_ROUTE, SIGN_OUT_ROUTE, VIDEOS_ROUTE, HAZARDS_ROUTE, PURCHASE_ROUTE, ORDERS_ROUTE, PACKAGE_PAUSED_ROUTE, PACKAGE_EXPIRED_ROUTE, REGISTER_ROUTE, PRIVACY_ROUTE, TERMS_ROUTE, RESTITUTION_ROUTE, DOWNLOADS_ROUTE, PACKAGE_DELAYED_ROUTE } from "./constants";
import logo_icon from "./images/logos/logo-icon.svg";
import "material-icons/iconfont/material-icons.css";

message.config({
    maxCount: 1,
    duration: 5
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<>
    <Helmet>
        <link rel="icon" href={logo_icon} />
    </Helmet>
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <AuthProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path={HOME_ROUTE} element={<App />} />
                            <Route path={PURCHASE_ROUTE} element={<Purchase />} />
                            <Route path={REGISTER_ROUTE} element={<Register />} />
                            <Route path={SIGN_IN_ROUTE} element={<SignIn />} />
                            <Route path={SIGN_OUT_ROUTE} element={<SignOut />} />
                            <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
                            <Route path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
                            <Route path={PRIVACY_ROUTE} element={<Privacy />} />
                            <Route path={TERMS_ROUTE} element={<Terms />} />
                            <Route path={RESTITUTION_ROUTE} element={<Restitution />} />
                            <Route path={DASHBOARD_ROUTE} element={<AuthProtector Component={Dashboard} />} />
                            <Route path={VIDEOS_ROUTE} element={<AuthProtector Component={Videos} />} />
                            <Route path={HAZARDS_ROUTE} element={<AuthProtector Component={Hazards} />} />
                            <Route path={HAZARD_ROUTE} element={<AuthProtector Component={Hazard} />} />
                            <Route path={EXAMS_ROUTE} element={<AuthProtector Component={Exams} />} />
                            <Route path={EXAM_ROUTE} element={<AuthProtector Component={AutoExam} />} />
                            <Route path={DOWNLOADS_ROUTE} element={<AuthProtector Component={Downloads} />} />
                            <Route path={PROFILE_ROUTE} element={<Profile />} />
                            <Route path={ORDERS_ROUTE} element={<Orders />} />
                            <Route path={PACKAGE_EXPIRED_ROUTE} element={<PackageExpired />} />
                            <Route path={PACKAGE_PAUSED_ROUTE} element={<PackagePaused />} />
                            <Route path={PACKAGE_DELAYED_ROUTE} element={<PackageDelayed />} />

                            <Route path="*" element={
                                <main style={{ padding: "1rem" }}><p>Deze pagina bestaat niet...</p></main>
                            } />
                        </Routes>
                    </BrowserRouter>
                </AuthProvider>
            </PersistGate>
        </Provider>
    </React.StrictMode>
</>);