import React, { useEffect, useRef, useState } from "react";
import Player from "@vimeo/player";
import { PACKAGES_DESKTOP_ANCHOR, PACKAGES_MOBILE_ANCHOR, SCROLL_DOWN_DESKTOP_ANCHOR, SCROLL_DOWN_MOBILE_ANCHOR } from "../../../constants";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import exclamation_icon from "../../../images/icons/exclamation-icon.svg";
import "./Top.scss";
import "./TopDesktop.scss";
import "./TopMobile.scss";

const VIDEO_PLAY_RETRIES = 100;

const Top = ({ backgroundVideosPlaying }) => {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [videoVisible, setVideoVisible] = useState(false);

    const videoRef = useRef(null);

    const playVideo = (player, retries = 0) => {
        player.play().catch(err => {
            if (retries < VIDEO_PLAY_RETRIES) {
                setTimeout(() => {
                    playVideo(player, retries + 1);
                    setVideoVisible(true);
                }, 500);
            }
        });
        setVideoVisible(true);
    };

    const checkIfPaused = async (player) => {
        const isPaused = await player.getPaused();
        
        if (isPaused && backgroundVideosPlaying) {
            player.play();
        }
    };

    const scrollTo = (anchor) => {
        document.getElementById(anchor).scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        const player = new Player(document.getElementById("top-video"));
        videoRef.current = player;

        player.on("loaded", () => {
            playVideo(player);
        });

        return () => {
            player.off("loaded");
            player.unload();
        };
    }, []);

    useEffect(() => {
        const player = videoRef.current;
        if (player) {
            if (backgroundVideosPlaying) {
                let interval;

                playVideo(player);

                interval = setInterval(() => {
                    checkIfPaused(player);
                }, 500);

                return () => clearInterval(interval);
            } else {
                player.pause();
            }
        }
    }, [backgroundVideosPlaying]);

    return (
        <section className={viewportWidth > 650 ? "top-desktop" : "top-mobile"}>
            <section
                className={"top-video centered" + (videoVisible ? " visible" : "")}
                dangerouslySetInnerHTML={{
                    __html: `<iframe id="top-video" class="centered" src="https://player.vimeo.com/video/861782946?h=776d3003ad&muted=1&mute=1&controls=0&byline=0&portrait=0&title=0&badge=0&loop=1&background=1" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>`
                }}
            />
            
            <div className="slogan">
                <h1>GEWOON LEKKER<br /><span>SLAGEN</span></h1>
                <p>Videocursus en oefenexamens</p>
                <img alt="Uitroepteken" src={exclamation_icon} />
                
                <div
                    className="order-button"
                    onClick={(e) => {
                        e.preventDefault();
                        scrollTo(viewportWidth > 800 ? PACKAGES_DESKTOP_ANCHOR : PACKAGES_MOBILE_ANCHOR);
                    }}
                >
                    <div className="text centered">KIES JE PAKKET</div>
                </div>
            </div>

            <div
                className="scroll-down"
                onClick={(e) => {
                    e.preventDefault();
                    scrollTo(viewportWidth > 800 ? SCROLL_DOWN_DESKTOP_ANCHOR : SCROLL_DOWN_MOBILE_ANCHOR);
                }}
            >
                <KeyboardArrowDownIcon className="centered" />
            </div>
        </section>
    );
};

export default Top;