import React from "react";
import "./Option.scss";

const Option = ({ label, text, chosenOption, setChosenOption }) => {
    return (
        <div
            className={"option" + (label === chosenOption ? " active" : " inactive")}
            onClick={(e) => {
                e.preventDefault();
                setChosenOption(label);
            }}
        >
            <div className="text">{text}</div>
        </div>
    );
};

export default Option;