import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PACKAGES_DESKTOP_ANCHOR, PURCHASE_ROUTE } from "../../../constants";
import range_icon from "../../../images/icons/range-icon.png";
import twingo_icon from "../../../images/icons/twingo-icon.png";
import smart_icon from "../../../images/icons/smart-icon.png";
import harley_icon from "../../../images/icons/harley-icon.png";
import yamaha_icon from "../../../images/icons/yamaha-icon.png";
import beverly_icon from "../../../images/icons/beverly-icon.png";
import emergency_icon from "../../../images/icons/emergency-icon.png";
import play_icon from "../../../images/icons/play-icon.svg";
import passed_icon from "../../../images/icons/passed-icon.svg";
import check_icon from "../../../images/icons/check-icon.svg";
import "./About.scss";
import "./PackagesDesktop.scss";

const PackagesDesktop = () => {
    const [vehicle, setVehicle] = useState("Auto");

    const navigate = useNavigate();

    return (
        <section className="packages-desktop about-section-desktop">            
            <div className="wrapper">
                <div id={PACKAGES_DESKTOP_ANCHOR} className="scroll-point" />
                <section className="packages-section">
                    <div className={"vehicles" + (vehicle === "Auto" ? " blue" : " green")}>
                        <div
                            className={"auto" + (vehicle === "Auto" ? " active" : "")}
                            onClick={(e) => {
                                e.preventDefault();
                                setVehicle("Auto");
                            }}
                        >
                            <div className="text centered">Auto</div>
                        </div>
                        <div
                            className={"motor" + (vehicle === "Motor" ? " active" : "")}
                            onClick={(e) => {
                                e.preventDefault();
                                setVehicle("Motor");
                            }}
                        >
                            <div className="text centered">Motor</div>
                        </div>
                        <div className={"slider" + (vehicle === "Auto" ? " a" : " b")} />
                    </div>
                    
                    <div className={"auto-packages packages" + (vehicle === "Auto" ? " visible" : "")}>
                        <div className="auto-range package">
                            <h4>Populairst</h4>
                            <h2>Auto Range</h2>
                            <h3>5 weken</h3>
                            <img className="range-icon package-icon" alt="Pakket Range" src={range_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€59,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal weken</p>
                                    <p className="right">5</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Auto Range" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                        <div className="auto-twingo package">
                            <h2>Auto Twingo</h2>
                            <h3>3 weken</h3>
                            <img className="twingo-icon package-icon" alt="Pakket Twingo" src={twingo_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€54,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal weken</p>
                                    <p className="right">3</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Auto Twingo" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                        <div className="auto-smart package">
                            <h2>Auto Smart</h2>
                            <h3>1 week</h3>
                            <img className="smart-icon package-icon" alt="Pakket Smart" src={smart_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€49,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal weken</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Auto Smart" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                        <div className="auto-spoed package">
                            <h2>Auto Spoed</h2>
                            <h3>2 dagen</h3>
                            <img className="smart-icon package-icon" alt="Pakket Spoed" src={emergency_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€39,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal dagen</p>
                                    <p className="right">2</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Auto Spoed" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                    </div>

                    <div className={"motor-packages packages" + (vehicle === "Motor" ? " visible" : "")}>
                        <div className="motor-harley package">
                            <h4>Populairst</h4>
                            <h2>Motor Harley</h2>
                            <h3>5 weken</h3>
                            <img className="harley-icon package-icon" alt="Pakket Harley" src={harley_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€59,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal weken</p>
                                    <p className="right">5</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Motor Harley" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                        <div className="motor-yamaha package">
                            <h2>Motor Yamaha</h2>
                            <h3>3 weken</h3>
                            <img className="yamaha-icon package-icon" alt="Pakket Yamaha" src={yamaha_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€54,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal weken</p>
                                    <p className="right">3</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Motor Yamaha" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                        <div className="motor-beverly package">
                            <h2>Motor Beverly</h2>
                            <h3>1 week</h3>
                            <img className="beverly-icon package-icon" alt="Pakket Beverly" src={beverly_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€49,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal weken</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Motor Beverly" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                        <div className="motor-spoed package">
                            <h2>Motor Spoed</h2>
                            <h3>2 dagen</h3>
                            <img className="smart-icon package-icon" alt="Pakket Spoed" src={emergency_icon} />
                            <div className="package-info">
                                <div className="price">
                                    <p className="left">Prijs</p>
                                    <p className="right">€39,99</p>
                                </div>
                                <div>
                                    <p className="left">Aantal dagen</p>
                                    <p className="right">2</p>
                                </div>
                                <div>
                                    <p className="left">Aantal examens</p>
                                    <p className="right">8</p>
                                </div>
                                <div>
                                    <p className="left">Aantal keer pauzeren</p>
                                    <p className="right">1</p>
                                </div>
                                <div>
                                    <p className="left">Alle CBR-onderdelen</p>
                                    <img className="check-icon" alt="Ja" src={check_icon} />
                                </div>
                            </div>
                            <div
                                className="package-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(PURCHASE_ROUTE, { state: { pakket: "Motor Spoed" } });
                                }}
                            >
                                <div className="text centered">BESTELLEN</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="info">
                    <div className="videos-info">
                        <h3>Alle CBR onderdelen uitgelegd</h3>
                        <p>Met ezelsbruggetjes en verschillende verkeerssituaties worden alles uitgelegd. Op een begrijpelijke en leuke manier.</p>
                        <img alt="Les volgen" src={play_icon} />
                    </div>
                    <div className="exams-info">
                        <h3>Oefenexamens</h3>
                        <p>Je oefent net als bij het CBR theorie-examen de onderdelen Gevaarherkenning, Kennis en Inzicht. Tijdens het CBR-examen zul je veel van deze vragen meteen herkennen.</p>
                        <img alt="Geslaagd" src={passed_icon} />
                    </div>
                </section>
            </div>
        </section>
    );
};

export default PackagesDesktop;