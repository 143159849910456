import React from "react";
import { useAuthContext } from "../../../context/AuthContext";
import "./Header.scss";
import PackageStatus from "../PackageStatus";

const Header = ({ page }) => {
    const { reduxUser } = useAuthContext();

    return (
        <header className="header">
            <section className="left">
                <div className="centered">Je bent nu bij:<br /><span>{page}</span></div>
            </section>
            <section className="mid">
                <PackageStatus />
            </section>
            <section className="right">
                <div className="centered">Huidig voertuig:<br /><span>{reduxUser.huidigVoertuig}</span></div>
            </section>
        </header>
    );
};

export default Header;