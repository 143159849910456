import React from "react";
import { BASE } from "../../../constants";
import PracticeResultOption from "./practiceresultoption/PracticeResultOption";
import default_image from "../../../images/other/default.png";
import "./PracticeResults.scss";

const HAZARD_OPTIONS = ["Niets", "Gas loslaten", "Remmen"];

const PracticeResults = ({ className, hazardResults, knowledgeResults, insightResults, otherResults }) => {
    return (
        <div className="practice-results-container">
            <div className="practice-results-wrapper">
                <section className={"practice-results" + (className ? " " + className : "")}>
                    {hazardResults && hazardResults.length > 0 ? <h4>Je fouten bij <span>Gevaarherkenning</span></h4> : <></>}
                    {hazardResults ? hazardResults.map(result => (
                        <div key={result.question.id} className="result-wrapper">
                            <section className="question-number">
                                <div className="text centered">
                                    {result.questionNumber} / {result.numQuestions}
                                </div>
                            </section>

                            <section className="picture">
                                <img src={result.question.afbeelding ? (BASE + result.question.afbeelding.url) : default_image} alt="" />
                            </section>

                            <section className="question">
                                <div className="text centered">{result.question.vraag}</div>
                            </section>

                            <section className="options">
                                {HAZARD_OPTIONS.map((option) => (
                                    <PracticeResultOption
                                        key={option}
                                        label={option}
                                        text={option}
                                        result={result}
                                    />
                                ))}
                            </section>
                        </div>
                    )) : null}

                    {knowledgeResults && knowledgeResults.length > 0 ? <h4>Je fouten bij <span>Kennis</span></h4> : <></>}
                    {knowledgeResults ? knowledgeResults.map(result => (
                        <div key={result.question.id} className="result-wrapper">
                            <section className="question-number">
                                <div className="text centered">
                                    {result.questionNumber} / {result.numQuestions}
                                </div>
                            </section>

                            <section className="picture">
                                <img src={result.question.afbeelding ? (BASE + result.question.afbeelding.url) : default_image} alt="" />
                            </section>

                            <section className="question">
                                <div className="text centered">{result.question.vraag}</div>
                            </section>

                            <section className="options">
                                {result.question.soort === "Opties"
                                    ? <>
                                        {result.question.optieA ? <PracticeResultOption
                                            label="A"
                                            text={result.question.optieA}
                                            result={result}
                                        /> : <div />}
                                        {result.question.optieB ? <PracticeResultOption
                                            label="B"
                                            text={result.question.optieB}
                                            result={result}
                                        /> : <div />}
                                        {result.question.optieC ? <PracticeResultOption
                                            label="C"
                                            text={result.question.optieC}
                                            result={result}
                                        /> : <div />}
                                    </>
                                    :
                                result.question.soort === "Ja/Nee"
                                    ? <>
                                        <PracticeResultOption
                                            label="Ja"
                                            text="Ja"
                                            result={result}
                                        />
                                        <PracticeResultOption
                                            label="Nee"
                                            text="Nee"
                                            result={result}
                                        />
                                        <div />
                                    </>
                                    :
                                result.question.soort === "Invul"
                                    ? <>
                                        <PracticeResultOption
                                            label={result.question.correcteAntwoord}
                                            text={result.question.correcteAntwoord}
                                            result={result}
                                        />
                                        <PracticeResultOption
                                            label={result.answer}
                                            text={result.answer}
                                            result={result}
                                        />               
                                        <div />
                                    </>
                                    :
                                <></>}
                            </section>
                        </div>
                    )) : null}

                    {insightResults && insightResults.length > 0 ? <h4>Je fouten bij <span>Inzicht</span></h4> : <></>}
                    {insightResults ? insightResults.map(result => (
                        <div key={result.question.id} className="result-wrapper">
                            <section className="question-number">
                                <div className="text centered">
                                    {result.questionNumber} / {result.numQuestions}
                                </div>
                            </section>

                            <section className="picture">
                                <img src={result.question.afbeelding ? (BASE + result.question.afbeelding.url) : default_image} alt="" />
                            </section>

                            <section className="question">
                                <div className="text centered">{result.question.vraag}</div>
                            </section>

                            <section className="options">
                            {result.question.soort === "Opties"
                                    ? <>
                                        {result.question.optieA ? <PracticeResultOption
                                            label="A"
                                            text={result.question.optieA}
                                            result={result}
                                        /> : <div />}
                                        {result.question.optieB ? <PracticeResultOption
                                            label="B"
                                            text={result.question.optieB}
                                            result={result}
                                        /> : <div />}
                                        {result.question.optieC ? <PracticeResultOption
                                            label="C"
                                            text={result.question.optieC}
                                            result={result}
                                        /> : <div />}
                                    </>
                                    :
                                result.question.soort === "Ja/Nee"
                                    ? <>
                                        <PracticeResultOption
                                            label="Ja"
                                            text="Ja"
                                            result={result}
                                        />
                                        <PracticeResultOption
                                            label="Nee"
                                            text="Nee"
                                            result={result}
                                        />
                                        <div />
                                    </>
                                    :
                                result.question.soort === "Invul"
                                    ? <>
                                        <PracticeResultOption
                                            label={result.question.correcteAntwoord}
                                            text={result.question.correcteAntwoord}
                                            result={result}
                                        />
                                        <PracticeResultOption
                                            label={result.answer}
                                            text={result.answer}
                                            result={result}
                                        />               
                                        <div />
                                    </>
                                    :
                                <></>}
                            </section>
                        </div>
                    )) : null}

                    {otherResults && otherResults.length > 0 ? <h4>Overzicht van je fouten</h4> : <></>}
                    {otherResults ? otherResults.map(result => (
                        <div key={result.question.id} className="result-wrapper">
                            <section className="question-number">
                                <div className="text centered">
                                    {result.questionNumber} / {result.numQuestions}
                                </div>
                            </section>

                            <section className="picture">
                                <img src={result.question.afbeelding ? (BASE + result.question.afbeelding.url) : default_image} alt="" />
                            </section>

                            <section className="question">
                                <div className="text centered">{result.question.vraag}</div>
                            </section>

                            <section className="options">
                            {result.question.soort === "Opties"
                                    ? <>
                                        {result.question.optieA ? <PracticeResultOption
                                            label="A"
                                            text={result.question.optieA}
                                            result={result}
                                        /> : <div />}
                                        {result.question.optieB ? <PracticeResultOption
                                            label="B"
                                            text={result.question.optieB}
                                            result={result}
                                        /> : <div />}
                                        {result.question.optieC ? <PracticeResultOption
                                            label="C"
                                            text={result.question.optieC}
                                            result={result}
                                        /> : <div />}
                                    </>
                                    :
                                result.question.soort === "Ja/Nee"
                                    ? <>
                                        <PracticeResultOption
                                            label="Ja"
                                            text="Ja"
                                            result={result}
                                        />
                                        <PracticeResultOption
                                            label="Nee"
                                            text="Nee"
                                            result={result}
                                        />
                                        <div />
                                    </>
                                    :
                                result.question.soort === "Invul"
                                    ? <>
                                        <PracticeResultOption
                                            label={result.question.correcteAntwoord}
                                            text={result.question.correcteAntwoord}
                                            result={result}
                                        />
                                        <PracticeResultOption
                                            label={result.answer}
                                            text={result.answer}
                                            result={result}
                                        />               
                                        <div />
                                    </>
                                    :
                                <></>}
                            </section>
                        </div>
                    )) : null}
                </section>
            </div>
        </div>
    );
};

export default PracticeResults;