import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../context/AuthContext";
import useFetch from "../../hooks/useFetch";
import { ORDERS_API } from "../../constants";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import Loader from "../../components/core/loader/Loader";
import "./Orders.scss";


const Orders = () => {
    const [packageExpired, setPackageExpired] = useState(false);
    const [packagePaused, setPackagePaused] = useState(false);

    const { user, reduxUser } = useAuthContext();
    const { data } = useFetch(ORDERS_API, {}, true);

    const getDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, "0");
        const month = String(date.getUTCMonth() + 1).padStart(2, "0");
        const year = date.getUTCFullYear();
    
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {        
        const currentDate = new Date();
        const packageEnd = new Date(user ? (user.huidigVoertuig === "Auto" ? user.autoToegang : (user.huidigVoertuig === "Motor" ? user.motorToegang : null))
                                    : (reduxUser.huidigVoertuig === "Auto" ? reduxUser.autoToegang : (reduxUser.huidigVoertuig === "Motor" ? reduxUser.motorToegang : null)));
        const difference = packageEnd - currentDate;
        const packagePaused = user ? user.pakketGepauzeerd : reduxUser.pakketGepauzeerd;

        setPackageExpired(difference <= 0);
        setPackagePaused(packagePaused);
    }, [user, reduxUser]);

    return (<>
        <div className="orders container">
            <NavMenu packageExpired={packageExpired} packagePaused={packagePaused} />
            <Header page={"Jouw aankopen"} />
            
            <main>
                <h1>Betalingen</h1>
                {!data
                    ? <div className="order-list"><Loader /></div>
                    : <div className="order-list">
                        {[...data.orders].reverse().map((order) => (
                            <div key={order.id} className="order"
                            >
                                <div className="id">
                                    <div className="text centered">#{order.id}</div>
                                </div>
                                <div className="package">
                                    <div className="text centered">{order.pakket}</div>
                                </div>
                                <div className="date">
                                    <div className="text centered">{getDate(order.datum)}</div>
                                </div>
                                <div className="price">
                                    <div className="text centered">€{String(parseFloat((order.prijs * 0.01).toFixed(2))).replace(".", ",")}</div>
                                </div>
                                <div className="paid">
                                    <div className="text centered">Betaald</div>
                                </div>
                                <div className="method">
                                    <div className="text centered">Betaalmethode:&nbsp;&nbsp;<span>{order.betaalmethode}</span></div>
                                </div>
                            </div>
                        ))}

                        {data.orders.length === 0
                            ? <p>Je hebt nog geen pakketten besteld.</p>
                            : <></>
                        }
                    </div>
                }
            </main>
        </div>

        <Footer />
    </>);
};

export default Orders;