import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { getJWT } from "../../helpers/jwt";
import { CHAPTERS_AND_MODULES_API, ERROR_MESSAGE, EXAMS_ROUTE, INACTIVE_JWT_API_ERROR, MODULE_API, NO_JWT_API_ERROR, OTHER_DEVICE_MESSAGE, SIGN_OUT_ROUTE, UNAUTHORIZED_API_ERROR, UNAUTHORIZED_MESSAGE, UNKNOWN_API_ERROR, UPDATE_CURRENT_MODULE_API } from "../../constants";
import NavMenu from "../../components/core/nav/NavMenu";
import Header from "../../components/core/header/Header";
import Footer from "../../components/core/footer/Footer";
import Frame from "../../components/videos/frame/Frame";
import ChaptersAndModules from "../../components/videos/chaptersandmodules/ChaptersAndModules";
import ModuleText from "../../components/videos/moduletext/ModuleText";
import Loader from "../../components/core/loader/Loader";
import "./Videos.scss";


const Videos = () => {
    const [chapters, setChapters] = useState([]);
    const [modules, setModules] = useState({});
    const [fetchingChaptersAndModules, setFetchingChaptersAndModules] = useState(false);
    const [currentChapter, setCurrentChapter] = useState(-1);
    const [currentModule, setCurrentModule] = useState(-1);
    const [frames, setFrames] = useState({});
    const [currentFrame, setCurrentFrame] = useState(null);
    const [videoEnded, setVideoEnded] = useState(false);
    
    const navigate = useNavigate();
    const jwt = getJWT();

    const fetchModule = async () => {
        try {
            const response = await fetch(MODULE_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify({
                    module: currentModule
                })
            });
    
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }
    
            if (data?.error) {
                throw data?.error;
            } else {
                if (!frames[currentChapter]) {
                    frames[currentChapter] = { [currentModule]: <Frame video_html={data.module.video} thumbnail={data.module.thumbnail} setVideoEnded={setVideoEnded} /> }
                } else {
                    frames[currentChapter][currentModule] = <Frame video_html={data.module.video} thumbnail={data.module.thumbnail} setVideoEnded={setVideoEnded} />
                }
                setCurrentFrame(<Frame video_html={data.module.video} thumbnail={data.module.thumbnail} setVideoEnded={setVideoEnded} />);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    const updateCurrentModule = async () => {
        try {
            const response = await fetch(UPDATE_CURRENT_MODULE_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + jwt
                },
                body: JSON.stringify({
                    module: currentModule
                })
            });
    
            const data = await response.json();
    
            if (data?.error) {
                throw data?.error;
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    useEffect(() => {
        const fetchChaptersAndModules = async () => {
            setFetchingChaptersAndModules(true);
        
            try {
                const response = await fetch(CHAPTERS_AND_MODULES_API, {
                    headers: { Authorization: "Bearer " + jwt }
                });
                const data = await response.json();
    
                if (!response.ok || String(response.status).charAt(0) !== "2") {
                    if (data?.error) {
                        throw data?.error;
                    } else {
                        throw UNKNOWN_API_ERROR;
                    }
                }
        
                if (data?.error) {
                    throw data?.error;
                } else {
                    let chaptersData = [];
                    let modulesData = {};

                    data.chapters.forEach(chapter => {
                        chaptersData.push(chapter);
                        modulesData[chapter.id] = data.modules[chapter.titel].modules;
                    });

                    setChapters(chaptersData);
                    setModules(modulesData);

                    let sameVehicle = false;

                    data.chapters.forEach(chapter => {
                        if (chapter.id === data.currentModule.hoofdstuk.id) {
                            sameVehicle = true;
                        }
                    });

                    if (sameVehicle) {
                        setCurrentChapter(data.currentModule.hoofdstuk.id);
                        setCurrentModule(data.currentModule.id);
                    } else {
                        setCurrentChapter(data.chapters[0].id);
                        setCurrentModule(data.chapters[0].modules[0].id);
                    }
                    
                    setFrames({ [data.currentModule.hoofdstuk.id]: { [data.currentModule.id]: <Frame video_html={data.currentModule.video} thumbnail={data.currentModule.thumbnail} setVideoEnded={setVideoEnded} /> }});
                }
            } catch (err) {
                if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                    message.error(UNAUTHORIZED_MESSAGE);
                    navigate(SIGN_OUT_ROUTE);
                } else if (err === INACTIVE_JWT_API_ERROR) {
                    message.error(OTHER_DEVICE_MESSAGE);
                    navigate(SIGN_OUT_ROUTE);
                } else {
                    message.error(ERROR_MESSAGE);
                }
            } finally {
                setFetchingChaptersAndModules(false);
            }
        };

        fetchChaptersAndModules();
    }, [jwt]);

    useEffect(() => {
        setVideoEnded(false);

        if (currentChapter > -1 && currentModule > -1) {
        //     if (frames[currentChapter] && frames[currentChapter][currentModule]) {
        //         setCurrentFrame(frames[currentChapter][currentModule]);
        //     } else {
        //         fetchModule();
        //     }
            fetchModule();
            updateCurrentModule();
        }
    }, [currentChapter, currentModule, frames]);

    const chapterIdToIndex = (chapterId) => {
        for (let i = 0; i < chapters.length; i++) {
            if (chapters[i].id === chapterId) {
                return i;
            }
        }

        return -1;
    };

    const chapterIndexToId = (chapterIndex) => {
        return chapters[chapterIndex].id;
    };

    const moduleIdToIndex = (chapterId, moduleId) => {
        for (let i = 0; i < modules[chapterId].length; i++) {
            if (modules[chapterId][i].id === moduleId) {
                return i;
            }
        }

        return -1;
    };

    const moduleIndexToId = (chapterId, moduleIndex) => {
        return modules[chapterId][moduleIndex].id;
    };

    const handleChapterChange = (chapterIndex) => {
        setCurrentChapter(chapterIndexToId(chapterIndex));
        setCurrentModule(moduleIndexToId(chapterIndexToId(chapterIndex), 0));
    };

    const handleModuleChange = (moduleId) => {
        setCurrentModule(moduleId);
    };

    const nextModule = () => {
        if (currentChapter > -1 && currentModule > -1) {
            if (moduleIdToIndex(currentChapter, currentModule) < modules[currentChapter].length - 1) {
                setCurrentModule(moduleIndexToId(currentChapter, moduleIdToIndex(currentChapter, currentModule) + 1));
            } else if (chapterIdToIndex(currentChapter) < chapters.length - 1) {
                handleChapterChange(chapterIdToIndex(currentChapter) + 1);
            }
        }
    };

    return (<>
        <div className="videos container">
            <NavMenu />
            <Header page="Les volgen" />
            
            {fetchingChaptersAndModules || currentChapter === -1 || currentModule === -1
                ? <main><Loader /></main>
                : <main>
                    <p className="status">Je kijkt nu naar het onderdeel: <span>{modules[currentChapter][moduleIdToIndex(currentChapter, currentModule)].titel}</span></p>

                    <div className="frame-wrapper">
                        {currentFrame}
                        
                        {videoEnded
                            ? <div
                                className="next-video-button centered"
                                onClick={(e) => {
                                    e.preventDefault();

                                    if (chapterIdToIndex(currentChapter) === chapters.length - 1) {
                                        navigate(EXAMS_ROUTE);
                                    } else {
                                        nextModule();
                                    }
                                }}
                            >
                                <div className="text centered">{(chapterIdToIndex(currentChapter) === chapters.length - 1) ? "Examens maken" : "Volgende video"}</div>
                            </div>
                            : <></>
                        }
                    </div>

                    <ChaptersAndModules
                        chapters={chapters}
                        chapterIndex={chapterIdToIndex(currentChapter)}
                        handleChapterChange={handleChapterChange}
                        moduleList={modules[currentChapter]}
                        currentModule={currentModule}
                        handleModuleChange={handleModuleChange}
                    />

                    <ModuleText
                        title={modules[currentChapter][moduleIdToIndex(currentChapter, currentModule)].titel}
                        text={modules[currentChapter][moduleIdToIndex(currentChapter, currentModule)].beschrijving}
                    />
                </main>
            }
        </div>

        <Footer />
    </>);
};

export default Videos;