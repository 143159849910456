import React from "react";
import "./ModuleText.scss";

const ModuleText = ({ title, text }) => {
    return (
        <section className="module-text">
            <h2>{title}</h2>
            <p>{text}</p>
        </section>
    );
};

export default ModuleText;