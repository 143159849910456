import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { removeUser } from "../../features/userslice";
import { useAuthContext } from "../../context/AuthContext";
import { checkEmail, checkEmailConfirmation, checkPassword, checkPasswordConfirmation } from "../../helpers/formChecks";
import { removeJWT, setJWT } from "../../helpers/jwt";
import { BACK_TO_DASHBOARD_IN_LINK, BACK_TO_HOME_IN_LINK, CHECK_DISCOUNT_CODE_API, CREATE_ORDER_API, DASHBOARD_ROUTE, DISCOUNT_CODE_PLACEHOLDER, EMAIL_CONFIRMATION_PLACEHOLDER, EMAIL_PLACEHOLDER, EMAIL_TAKEN_API_ERROR, EMAIL_TAKEN_MESSAGE, ERROR_MESSAGE, FIRST_NAME_CHECK, FIRST_NAME_PLACEHOLDER, FORGOT_PASSWORD_LINK, FORGOT_PASSWORD_ROUTE, HAS_ACCOUNT_LINK, HAS_LICENSE_CODE_LINK, HAS_NO_ACCOUNT_LINK, HOME_ROUTE, INVALID_DISCOUNT_CODE_API_ERROR, INVALID_DISCOUNT_CODE_MESSAGE, INVALID_SIGN_IN_API_ERROR, INVALID_SIGN_IN_MESSAGE, ORDER_BUTTON, PASSWORD_CONFIRMATION_PLACEHOLDER, PASSWORD_PLACEHOLDER, PURCHASE_REGISTER_API, PURCHASE_SIGN_IN_API, REGISTERED_MESSAGE, REGISTER_ROUTE, SIGN_IN_BUTTON, SIGN_OUT_LINK, UNKNOWN_API_ERROR } from "../../constants";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Loader from "../../components/core/loader/Loader";
import Contact from "../../components/home/contact/Contact";
import "./Authentication.scss";


const Purchase = () => {
    const [firstName, setFirstName] = useState("");
    const [firstNameCheck, setFirstNameCheck] = useState("");
    const [email, setEmail] = useState("");
    const [emailCheck, setEmailCheck] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [emailConfirmationCheck, setEmailConfirmationCheck] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordConfirmationCheck, setPasswordConfirmationCheck] = useState("");
    const [discountCode, setDiscountCode] = useState("");
    const [discountCodeCorrect, setDiscountCodeCorrect] = useState(false);
    const [discountCodeIncorrect, setDiscountCodeIncorrect] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState(0);
    const [buttonText, setButtonText] = useState(ORDER_BUTTON);
    const [hasAccountText, setHasAccountText] = useState(HAS_ACCOUNT_LINK);
    const [discountCodeField, setDiscountCodeField] = useState(false);
    const [register, setRegister] = useState(true);
    const [registering, setRegistering] = useState(false);
    const [signingIn, setSigningIn] = useState(false);
    const [creatingOrder, setCreatingOrder] = useState(false);
    
    const timeoutRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, fetchingUser, setUser } = useAuthContext();
    const pakket = location.state?.pakket ? location.state.pakket : "Range";

    const checkDiscountCode = async (code) => {
        try {
            const response = await fetch(CHECK_DISCOUNT_CODE_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    code: code.trim().toLowerCase()
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                if (data.percentage === 0) {
                    setDiscountCodeCorrect(false);
                    setDiscountCodeIncorrect(true);
                    setDiscountPercentage(0);
                } else {
                    setDiscountCodeCorrect(true);
                    setDiscountCodeIncorrect(false);
                    setDiscountPercentage(data.percentage);
                }
            }
        } catch (err) {
            message.error(ERROR_MESSAGE);
        } finally {
        }
    };

    const createOrder = async () => {
        setCreatingOrder(true);

        try {
            const response = await fetch(CREATE_ORDER_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    firstName: user ? user.voornaam : firstName,
                    email: user ? user.email : email,
                    password: password,
                    package: location.state?.pakket ? location.state.pakket : "Auto Range",
                    discountCode: discountCode.trim().toLowerCase()
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                window.location.href = data.paymentUrl;
            }
        } catch (err) {
            if (err === INVALID_DISCOUNT_CODE_API_ERROR) {
                message.error(INVALID_DISCOUNT_CODE_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
            
            setCreatingOrder(false);
        } finally {
        }
    };

    const purchaseRegister = async () => {
        setRegistering(true);

        try {
            const response = await fetch(PURCHASE_REGISTER_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    username: email,
                    email: email,
                    password: password,
                    voornaam: firstName,
                    discountCode: discountCode.trim().toLowerCase()
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                setJWT(data.jwt);
                setUser(data.user);
                message.success(REGISTERED_MESSAGE);
                createOrder();
            }
        } catch (err) {
            if (err === INVALID_DISCOUNT_CODE_API_ERROR) {
                message.error(INVALID_DISCOUNT_CODE_MESSAGE);
            } else if (err.message === EMAIL_TAKEN_API_ERROR) {
                message.error(EMAIL_TAKEN_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setRegistering(false);
        }
    };

    const purchaseSignIn = async () => {
        setSigningIn(true);

        try {
            const response = await fetch(PURCHASE_SIGN_IN_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    identifier: email,
                    password: password
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                setJWT(data.jwt);
                setUser(data.user);
            }
        } catch (err) {
            if (err.message === INVALID_SIGN_IN_API_ERROR) {
                message.error(INVALID_SIGN_IN_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setSigningIn(false);
        }
    };

    const discountCodeTimeout = (e) => {
        e.preventDefault();

        const newValue = e.target.value;

        setDiscountCode(newValue);
        setDiscountCodeCorrect(false);
        setDiscountCodeIncorrect(false);
        setDiscountPercentage(0);
    
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            handleDiscountCode(newValue);
        }, 1000);
    };
    
    const handleDiscountCode = (code) => {
        if (code) {
            checkDiscountCode(code);
        } else {
            setDiscountCodeCorrect(false);
            setDiscountCodeIncorrect(false);
            setDiscountPercentage(0);
        }
    };

    const getPrice = () => {
        let price = "";

        if (pakket === "Auto Range") {
            price = "59,99";
        } else if (pakket === "Auto Twingo") {
            price = "54,99";
        } else if (pakket === "Auto Smart") {
            price = "49,99";
        } else if (pakket === "Auto Spoed") {
            price = "39,99";
        } else if (pakket === "Motor Harley") {
            price = "59,99";
        } else if (pakket === "Motor Yamaha") {
            price = "54,99";
        } else if (pakket === "Motor Beverly") {
            price = "49,99";
        } else if (pakket === "Motor Spoed") {
            price = "39,99";
        }

        if (discountCodeCorrect) {
            return (<>
                <span className="old-price">€{price}</span>&nbsp;&nbsp;&nbsp;<span className="new-price">€{String(Math.round(parseFloat(price.replace(",", ".")) * (100 - discountPercentage)) / 100).replace(".", ",")}</span>
            </>);
        } else {
            return "€" + price;
        }
    }

    const signOut = async (e) => {
        e.preventDefault();
        dispatch(removeUser());
        removeJWT();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        setFirstName("");
        setFirstNameCheck("");
        setEmail("");
        setEmailCheck("");
        setEmailConfirmation("");
        setEmailConfirmationCheck("");
        setPassword("");
        setPasswordCheck("");
        setPasswordConfirmation("");
        setPasswordConfirmationCheck("");
        setDiscountCode("");
        setDiscountCodeCorrect(false);
        setDiscountCodeIncorrect(false);
        setDiscountPercentage(0);
        setButtonText(user || register ? ORDER_BUTTON : SIGN_IN_BUTTON);
        setHasAccountText(register ? HAS_ACCOUNT_LINK : HAS_NO_ACCOUNT_LINK);
        setDiscountCodeField(false);
    }, [user, register]);

    if (fetchingUser || registering || signingIn || creatingOrder) {
        return <Loader />;
    }

    return (<>
        <NavBarDesktop noScroll={true} />
        <NavBarMobile noScroll={true} />

        <div className="authentication-container">
            <main className="purchase">
                <aside className={
                    pakket === "Auto Range" ? "auto-range-aside" : pakket === "Motor Harley" ? "motor-harley-aside" :
                    pakket === "Auto Twingo" ? "auto-twingo-aside" : pakket === "Motor Yamaha" ? "motor-yamaha-aside" :
                    pakket === "Auto Smart" ? "auto-smart-aside" : pakket === "Motor Beverly" ? "motor-beverly-aside" :
                    pakket === "Auto Spoed" ? "auto-spoed-aside" : pakket === "Motor Spoed" ? "motor-spoed-aside" :
                    "?"
                }>
                    <div className="aside-right">
                        <div className="package-info centered">
                            <div className="price">
                                <p className="left">Prijs</p>
                                <p className="right">{getPrice()}</p>
                            </div>
                            <div>
                                <p className="left">Aantal {(pakket === "Auto Spoed" || pakket === "Motor Spoed") ? "dagen" : "weken"}</p>
                                <p className="right">
                                    {
                                        pakket === "Auto Range" ? "5" : pakket === "Motor Harley" ? "5" :
                                        pakket === "Auto Twingo" ? "3" : pakket === "Motor Yamaha" ? "3" :
                                        pakket === "Auto Smart" ? "1" : pakket === "Motor Beverly" ? "1" :
                                        pakket === "Auto Spoed" ? "2" : pakket === "Motor Spoed" ? "2" :
                                        "?"
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="aside-left">
                        <h1>{pakket}</h1>
                    </div>
                </aside>
                <form onSubmit={user ? createOrder : (register ? purchaseRegister : purchaseSignIn)}>
                    <h1>Je bent er bijna!</h1>

                    {!user && register
                        ? <>
                            <input
                                type="text"
                                value={firstName}
                                placeholder={FIRST_NAME_PLACEHOLDER}
                                autoComplete=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setFirstName(e.target.value);
                                    setFirstNameCheck(e.target.value.length > 20 ? FIRST_NAME_CHECK : "");
                                }}
                                required
                            />
                            <div className="error">{firstNameCheck}</div>
                        </>
                        : <></>
                    }

                    {!user
                        ? <>
                            <input
                                type="email"
                                value={email}
                                placeholder={EMAIL_PLACEHOLDER}
                                autoComplete="email"
                                onChange={(e) => {
                                    e.preventDefault();
                                    setEmail(e.target.value.toLowerCase());

                                    if (register) {
                                        checkEmail(e.target.value, setEmailCheck);
                                        checkEmailConfirmation(e.target.value, emailConfirmation, setEmailConfirmationCheck);
                                    }
                                }}
                                required
                            />
                            <div className="error">{emailCheck}</div>
                        </>
                        : <></>
                    }

                    {!user && register
                        ? <>
                            <input
                                type="email"
                                value={emailConfirmation}
                                placeholder={EMAIL_CONFIRMATION_PLACEHOLDER}
                                autoComplete=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setEmailConfirmation(e.target.value.toLowerCase());
                                    
                                    if (register) {
                                        checkEmailConfirmation(email, e.target.value, setEmailConfirmationCheck);
                                    }
                                }}
                                required
                            />
                            <div className="error">{emailConfirmationCheck}</div>
                        </>
                        : <></>
                    }


                    {!user
                        ? <>
                            <input
                                type="password"
                                value={password}
                                placeholder={PASSWORD_PLACEHOLDER}
                                autoComplete={register ? "new-password" : ""}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setPassword(e.target.value);
                                    
                                    if (register) {
                                        checkPassword(e.target.value, setPasswordCheck);
                                        checkPasswordConfirmation(e.target.value, passwordConfirmation, setPasswordConfirmationCheck);
                                    }
                                }}
                                required
                            />
                            <div className="error">{passwordCheck}</div>
                        </>
                        : <></>
                    }

                    {!user && register
                        ? <>
                            <input
                                type="password"
                                value={passwordConfirmation}
                                placeholder={PASSWORD_CONFIRMATION_PLACEHOLDER}
                                autoComplete=""
                                onChange={(e) => {
                                    e.preventDefault();
                                    setPasswordConfirmation(e.target.value);
                                    
                                    if (register) {
                                        checkPasswordConfirmation(password, e.target.value, setPasswordConfirmationCheck);
                                    }
                                }}
                                required
                            />
                            <div className="error">{passwordConfirmationCheck}</div>
                        </>
                        : <></>
                    }

                    {user || register
                        ? discountCodeField
                            ? <>
                                <div className="discount-code-field">
                                    <span
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setDiscountCode("");
                                            setDiscountCodeCorrect(false);
                                            setDiscountCodeField(false);
                                        }}
                                    >
                                        Geen kortingscode
                                    </span>
                                </div>
                                <input
                                    className={discountCodeCorrect ? "correct" : (discountCodeIncorrect ? "incorrect" : "")}
                                    type="text"
                                    value={discountCode}
                                    placeholder={DISCOUNT_CODE_PLACEHOLDER}
                                    autoComplete=""
                                    onChange={(e) => discountCodeTimeout(e)}
                                />
                                <div className="error" />
                            </>
                            : <div className="discount-code-field">
                                Heb je een kortingscode? <span
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDiscountCode("");
                                        setDiscountCodeCorrect(false);
                                        setDiscountCodeField(true);
                                    }}
                                >
                                    Voeg toe
                                </span>
                            </div>
                        : <></>
                    }

                    {!user && (emailCheck || emailConfirmationCheck || passwordCheck || passwordConfirmationCheck || (register && !firstName) || !email || (register && !emailConfirmation) || !password || (register && !passwordConfirmation))
                        ? <button
                            type="submit"
                            disabled
                        >
                            {buttonText}
                        </button>
                        : <button
                            type="submit"
                        >
                            {buttonText}
                        </button>
                    }

                    {user
                        ? <div className="signed-in">
                            <div className="text centered">Je bent nu ingelogd als {user.email}.<br /><span onClick={(e) => signOut(e)}>{SIGN_OUT_LINK}</span></div>
                        </div>
                        : <></>
                    }

                    {!user && !register
                        ? <div
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(FORGOT_PASSWORD_ROUTE);
                            }}
                        >
                            {FORGOT_PASSWORD_LINK}
                        </div>
                        : <></>
                    }

                    {!user
                        ? <div
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                setRegister(!register);
                            }}
                        >
                            {hasAccountText}
                        </div>
                        : <></>
                    }

                    {!user && register
                        ? <div
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(REGISTER_ROUTE);
                            }}
                        >
                            {HAS_LICENSE_CODE_LINK}
                        </div>
                        : <></>
                    }

                    {user
                        ? <div
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(DASHBOARD_ROUTE);
                            }}
                        >
                            {BACK_TO_DASHBOARD_IN_LINK}
                        </div>
                        : <div
                            className="link"
                            onClick={(e) => {
                                e.preventDefault();
                                navigate(HOME_ROUTE);
                            }}
                        >
                            {BACK_TO_HOME_IN_LINK}
                        </div>
                    }
                </form>
            </main>
        
        </div>
        <Contact />
    </>);
};

export default Purchase;