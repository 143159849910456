import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { checkPassword, checkPasswordConfirmation } from "../../helpers/formChecks";
import { setJWT } from "../../helpers/jwt";
import { DASHBOARD_ROUTE, ERROR_MESSAGE, PASSWORD_CONFIRMATION_PLACEHOLDER, PASSWORD_PLACEHOLDER, RESET_PASSWORD_API, RESET_PASSWORD_BUTTON, RESET_PASSWORD_HEADING, SIGNED_IN_MESSAGE, UNKNOWN_API_ERROR } from "../../constants";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Contact from "../../components/home/contact/Contact";
import Loader from "../../components/core/loader/Loader";
import "./Authentication.scss";

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordConfirmationCheck, setPasswordConfirmationCheck] = useState("");
    const [resettingPassword, setResettingPassword] = useState(false);

    const { setUser } = useAuthContext();

    const resetPassword = async (e) => {
        e.preventDefault();
        setResettingPassword(true);

        try {
            const response = await fetch(RESET_PASSWORD_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    code: code,
                    password: password,
                    passwordConfirmation: passwordConfirmation
                })
            });
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                setJWT(data.jwt);
                setUser(data.user);
                navigate(DASHBOARD_ROUTE);
                message.success(SIGNED_IN_MESSAGE);
            }
        } catch (err) {
            message.error(ERROR_MESSAGE);
        } finally {
            setResettingPassword(false);
        }
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (resettingPassword) {
        return <Loader />;
    }

    return (<>
        <NavBarDesktop noScroll={true} />
        <NavBarMobile noScroll={true} />

        <div className="authentication-container">
            <main>
                <form className="authentication-form" onSubmit={resetPassword}>
                    <h1>{RESET_PASSWORD_HEADING}</h1>

                    <input
                        type="text"
                        name="email"
                        autoComplete="email"
                        style={{ display: "none" }}
                    />

                    <input
                        type="password"
                        value={password}
                        placeholder={PASSWORD_PLACEHOLDER}
                        autoComplete="new-password"
                        onChange={(e) => {
                            e.preventDefault();
                            setPassword(e.target.value);
                            checkPassword(e.target.value, setPasswordCheck);
                            checkPasswordConfirmation(e.target.value, passwordConfirmation, setPasswordConfirmationCheck);
                        }}
                        required
                    />
                    <div className="error">{passwordCheck}</div>

                    <input
                        type="password"
                        value={passwordConfirmation}
                        placeholder={PASSWORD_CONFIRMATION_PLACEHOLDER}
                        autoComplete="new-password"
                        onChange={(e) => {
                            e.preventDefault();
                            setPasswordConfirmation(e.target.value);
                            checkPasswordConfirmation(password, e.target.value, setPasswordConfirmationCheck);
                        }}
                        required
                    />
                    <div className="error">{passwordConfirmationCheck}</div>

                    {passwordCheck || passwordConfirmationCheck || !password || !passwordConfirmation
                        ? <button
                            type="submit"
                            disabled
                        >
                            {RESET_PASSWORD_BUTTON}
                        </button>
                        : <button
                            type="submit"
                        >
                            {RESET_PASSWORD_BUTTON}
                        </button>
                    }
                </form>
            </main>
        </div>

        <Contact />
    </>);
};

export default ResetPassword;