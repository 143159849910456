import validator from "validator";
import { EMAIL_MISMATCH_CHECK, INVALID_EMAIL_CHECK, INVALID_PASSWORD_CHECK, PASSWORD_MISMATCH_CHECK } from "../constants";

export const checkEmail = (e, setCheck) => {
    setCheck(validator.isEmail(e) || e === ""
        ? ""
        : INVALID_EMAIL_CHECK);
};

export const checkEmailConfirmation = (a, b, setCheck) => {
    setCheck(a === b || b === ""
        ? ""
        : EMAIL_MISMATCH_CHECK);
};

export const checkPassword = (p, setCheck) => {
    const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    
    setCheck(passwordRegex.test(p) || p === ""
        ? ""
        : INVALID_PASSWORD_CHECK);
};

export const checkPasswordConfirmation = (a, b, setCheck) => {
    setCheck(a === b || b === ""
        ? ""
        : PASSWORD_MISMATCH_CHECK);
};