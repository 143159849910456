import React from "react";
import Question from "./question/Question";
import PracticeResults from "../practiceresults/PracticeResults";
import "./Pages.scss";

const MAX_ALLOWED_INCORRECT_HAZARD = 13;

const HazardPages = ({ questions, fetchQuestions, resetHazard, pageIndex, switchPage, setAnswer, setAnswered, hazardResults }) => {
    return (
        <section className="pages">
            <div className="hazard-start-page page">
                <h3>Onderdeel Gevarenherkenning</h3>
                <p>Dit onderdeel bestaat uit {questions.length} vragen die gaan over het onderwerp Gevaarherkenning.</p>
                <p>Je moet {MAX_ALLOWED_INCORRECT_HAZARD} van de {questions.length} vragen goed hebben.</p>
                
                <section
                    className="start-button"
                    onClick={(e) => {
                        e.preventDefault();
                        switchPage(pageIndex + 1);
                    }}
                >
                    <div className="text centered">Start Gevaarherkenning</div>
                </section>
            </div>

            {questions.map((question) => (
                <Question
                    question={question}
                    pageIndex={pageIndex}
                    setAnswer={setAnswer}
                    setAnswered={setAnswered}
                    key={question.id}
                />
            ))}

            <div className="hazard-complete-page page">
                <h3>Foutenanalyse</h3>

                {hazardResults.length <= MAX_ALLOWED_INCORRECT_HAZARD
                    ? <p className="status">Gefeliciteerd! Je bent geslaagd voor het onderdeel Gevaarherkenning.</p>
                    : <p className="status">Helaas... Je bent niet geslaagd voor het onderdeel Gevaarherkenning.</p>
                }
                
                <p className={hazardResults.length <= MAX_ALLOWED_INCORRECT_HAZARD ? "passed" : "failed"}>
                    {hazardResults.length <= MAX_ALLOWED_INCORRECT_HAZARD ? "Voldoende" : "Onvoldoende"} - {hazardResults.length} van de {questions.length} vragen fout.
                    <br />
                    Je mocht in dit onderdeel {MAX_ALLOWED_INCORRECT_HAZARD} fouten hebben.
                </p>
                <p>Heb je fouten gemaakt? Bekijk je fouten, leer ervan en oefen verder met Gevaarherkenning.</p>
                
                <section
                    className="retry-button"
                    onClick={(e) => {
                        e.preventDefault();
                        resetHazard();
                    }}
                >
                    <div className="text centered">Herkansen</div>
                </section>
                
                <section
                    className="continue-button"
                    onClick={(e) => {
                        e.preventDefault();
                        fetchQuestions();
                    }}
                >
                    <div className="text centered">Nieuwe vragen</div>
                </section>

                <PracticeResults
                    hazardResults={hazardResults}
                />
            </div>
        </section>
    );
};

export default HazardPages;