import React from "react";
import "./About.scss";
import "./ValuesDesktop.scss";

const ValuesDesktop = () => {
    return (
        <section className="values-desktop about-section-desktop">
            <div className="wrapper">
                <h2>Theorieja leert je de theorie op een manier waardoor je alle onderdelen van het examen beheerst. De vragen van het CBR zijn de basis voor onze oefenexamens.</h2>

                <ul>
                    <li>De verschillende soorten weggedeelten kennen. En je moet als weggebruiker weten wat je op een snelweg of erf kunt verwachten.</li>
                    <li>De eisen, behoeften en symbolen die aan het voertuig gesteld worden.</li>
                    <li>Hoe je zuinig met brandstof omgaat en veilig deelneemt aan het verkeer.</li>
                    <li>Je moet de gebruiksregels en symbolen kennen om veilig te kunnen rijden.</li>
                    <li>Het gebruik van het voertuig en de wetten en documenten die hierbij van toepassing zijn.</li>
                    <li>Herkennen en beslissen wat te doen in gevaarlijke situaties.</li>
                </ul>

                <p>Er zijn natuurlijk nog meer zaken die je moet weten om te slagen voor de auto theorie examen. We willen je daar graag mee helpen!</p>
            </div>
        </section>
    );
};

export default ValuesDesktop;