import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { checkEmail, checkEmailConfirmation, checkPassword, checkPasswordConfirmation } from "../../helpers/formChecks";
import { setJWT } from "../../helpers/jwt";
import { DASHBOARD_ROUTE, EMAIL_CONFIRMATION_PLACEHOLDER, EMAIL_PLACEHOLDER, EMAIL_TAKEN_API_ERROR, EMAIL_TAKEN_MESSAGE, ERROR_MESSAGE, FIRST_NAME_CHECK, FIRST_NAME_PLACEHOLDER, HAS_ACCOUNT_LINK, INVALID_LICENSE_CODE_API_ERROR, INVALID_LICENSE_CODE_MESSAGE, LICENSE_CODE_PLACEHOLDER, PASSWORD_CONFIRMATION_PLACEHOLDER, PASSWORD_PLACEHOLDER, REGISTERED_MESSAGE, REGISTER_API, REGISTER_BUTTON, REGISTER_HEADING, REGISTER_PARAGRAPH, SIGN_IN_ROUTE, UNKNOWN_API_ERROR } from "../../constants";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Loader from "../../components/core/loader/Loader";
import Contact from "../../components/home/contact/Contact";
import "./Authentication.scss";

const Register = () => {
    const [licenseCode, setLicenseCode] = useState("");
    const [firstName, setFirstName] = useState("");
    const [firstNameCheck, setFirstNameCheck] = useState("");
    const [email, setEmail] = useState("");
    const [emailCheck, setEmailCheck] = useState("");
    const [emailConfirmation, setEmailConfirmation] = useState("");
    const [emailConfirmationCheck, setEmailConfirmationCheck] = useState("");
    const [password, setPassword] = useState("");
    const [passwordCheck, setPasswordCheck] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordConfirmationCheck, setPasswordConfirmationCheck] = useState("");
    const [registering, setRegistering] = useState(false);

    const navigate = useNavigate();
    const { setUser } = useAuthContext();

    const registerUser = async (e) => {
        e.preventDefault();
        setRegistering(true);

        try {
            const response = await fetch(REGISTER_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    code: licenseCode,
                    voornaam: firstName,
                    username: email,
                    email: email,
                    password: password
                })
            });
            
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                setJWT(data.jwt);
                setUser(data.user);
                navigate(DASHBOARD_ROUTE);
                message.success(REGISTERED_MESSAGE);
            }
        } catch (err) {
            if (err === INVALID_LICENSE_CODE_API_ERROR) {
                message.error(INVALID_LICENSE_CODE_MESSAGE);
            } else if (err.message === EMAIL_TAKEN_API_ERROR) {
                message.error(EMAIL_TAKEN_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setRegistering(false);
        }
    };
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (registering) {
        return <Loader />;
    }

    return (<>
        <NavBarDesktop noScroll={true} />
        <NavBarMobile noScroll={true} />

        <div className="authentication-container">
            <main>
                <form onSubmit={registerUser}>
                    <h1 style={{ fontSize: "1.4rem" }}>{REGISTER_HEADING}</h1>
                    <p>{REGISTER_PARAGRAPH}</p>

                    <input
                        type="text"
                        value={firstName}
                        placeholder={FIRST_NAME_PLACEHOLDER}
                        autoComplete=""
                        onChange={(e) => {
                            e.preventDefault();
                            setFirstName(e.target.value);
                            setFirstNameCheck(e.target.value.length > 20 ? FIRST_NAME_CHECK : "");
                        }}
                        required
                    />
                    <div className="error">{firstNameCheck}</div>

                    <input
                        type="email"
                        value={email}
                        placeholder={EMAIL_PLACEHOLDER}
                        autoComplete="email"
                        onChange={(e) => {
                            e.preventDefault();
                            setEmail(e.target.value.toLowerCase());
                            checkEmail(e.target.value, setEmailCheck);
                            checkEmailConfirmation(e.target.value, emailConfirmation, setEmailConfirmationCheck);
                        }}
                        required
                    />
                    <div className="error">{emailCheck}</div>

                    <input
                        type="email"
                        value={emailConfirmation}
                        placeholder={EMAIL_CONFIRMATION_PLACEHOLDER}
                        autoComplete=""
                        onChange={(e) => {
                            e.preventDefault();
                            setEmailConfirmation(e.target.value.toLowerCase());
                            checkEmailConfirmation(email, e.target.value, setEmailConfirmationCheck);
                        }}
                        required
                    />
                    <div className="error">{emailConfirmationCheck}</div>

                    <input
                        type="password"
                        value={password}
                        placeholder={PASSWORD_PLACEHOLDER}
                        autoComplete="new-password"
                        onChange={(e) => {
                            e.preventDefault();
                            setPassword(e.target.value);
                            checkPassword(e.target.value, setPasswordCheck);
                            checkPasswordConfirmation(e.target.value, passwordConfirmation, setPasswordConfirmationCheck);
                        }}
                        required
                    />
                    <div className="error">{passwordCheck}</div>

                    <input
                        type="password"
                        value={passwordConfirmation}
                        placeholder={PASSWORD_CONFIRMATION_PLACEHOLDER}
                        autoComplete=""
                        onChange={(e) => {
                            e.preventDefault();
                            setPasswordConfirmation(e.target.value);
                            checkPasswordConfirmation(password, e.target.value, setPasswordConfirmationCheck);
                        }}
                        required
                    />
                    <div className="error">{passwordConfirmationCheck}</div>

                    <input
                        type="text"
                        value={licenseCode}
                        placeholder={LICENSE_CODE_PLACEHOLDER}
                        onChange={(e) => {
                            e.preventDefault();
                            setLicenseCode(e.target.value);
                        }}
                        required
                    />
                    <div className="error" />

                    {firstNameCheck || emailCheck || emailConfirmationCheck || passwordCheck || passwordConfirmationCheck || !licenseCode || !email || !password || !passwordConfirmation
                        ? <button
                            type="submit"
                            disabled
                        >
                            {REGISTER_BUTTON}
                        </button>
                        : <button
                            type="submit"
                        >
                            {REGISTER_BUTTON}
                        </button>
                    }

                    <div
                        className="link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(SIGN_IN_ROUTE);
                        }}
                    >
                        {HAS_ACCOUNT_LINK}
                    </div>
                </form>
            </main>
        </div>
        
        <Contact />
    </>);
};

export default Register;