import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { PACKAGE_DELAYED_ROUTE, PACKAGE_EXPIRED_ROUTE, PACKAGE_PAUSED_ROUTE, SIGN_IN_ROUTE } from "../../constants";
import Loader from "./loader/Loader";

const AuthProtector = ({ Component }) => {
    const [checking, setChecking] = useState(true);

    const navigate = useNavigate();
    const { user, fetchingUser, reduxUser } = useAuthContext();

    useEffect(() => {
        setChecking(true);
        
        if ((!user && !fetchingUser) || (!user && !reduxUser)) {
            navigate(SIGN_IN_ROUTE);
        } else {
            const currentDate = new Date();
            const packageEnd = new Date(user ? (user.huidigVoertuig === "Auto" ? user.autoToegang : (user.huidigVoertuig === "Motor" ? user.motorToegang : null))
                                        : (reduxUser.huidigVoertuig === "Auto" ? reduxUser.autoToegang : (reduxUser.huidigVoertuig === "Motor" ? reduxUser.motorToegang : null)));
            const difference = packageEnd - currentDate;
            const packagePaused = user ? user.pakketGepauzeerd : reduxUser.pakketGepauzeerd;
            const packageDelay = new Date(user ? user.vertraging : reduxUser.vertraging);

            if (difference <= 0) {
                navigate(PACKAGE_EXPIRED_ROUTE);
            }

            if (packagePaused) {
                navigate(PACKAGE_PAUSED_ROUTE);
            }

            if (packageDelay > currentDate) {
                navigate(PACKAGE_DELAYED_ROUTE);
            }
        }

        setChecking(false);
    }, [user, fetchingUser, reduxUser]);

    if (checking || fetchingUser) {
        <Loader />
    }
    
    if (!checking && (user || reduxUser)) {
        return <Component />;
    }
};

export default AuthProtector;