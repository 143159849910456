import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { removeUser } from "../../features/userslice";
import { removeJWT, setJWT } from "../../helpers/jwt";
import { BACK_TO_HOME_IN_LINK, DASHBOARD_ROUTE, EMAIL_PLACEHOLDER, ERROR_MESSAGE, FORGOT_PASSWORD_LINK, FORGOT_PASSWORD_ROUTE, HOME_ROUTE, INVALID_SIGN_IN_API_ERROR, INVALID_SIGN_IN_MESSAGE, PASSWORD_PLACEHOLDER, SIGNED_IN_MESSAGE, SIGN_IN_API, SIGN_IN_BUTTON, SIGN_IN_HEADING, UNKNOWN_API_ERROR } from "../../constants";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Loader from "../../components/core/loader/Loader";
import Contact from "../../components/home/contact/Contact";
import "./Authentication.scss";

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signingIn, setSigningIn] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, reduxUser, setUser } = useAuthContext();
    
    const signInUser = async (e) => {
        e.preventDefault();
        setSigningIn(true);

        try {
            const response = await fetch(SIGN_IN_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    identifier: email,
                    password: password
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                setJWT(data.jwt);
                setUser(data.user);
                navigate(DASHBOARD_ROUTE);
                message.success(SIGNED_IN_MESSAGE);
            }
        } catch (err) {
            if (err.message === INVALID_SIGN_IN_API_ERROR) {
                message.error(INVALID_SIGN_IN_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setSigningIn(false);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (user || reduxUser) {
            removeJWT();
            setUser(null)
            dispatch(removeUser());
            window.location.reload();
        }
    }, []);

    if (signingIn) {
        return <Loader />;
    }

    return (<>
        <NavBarDesktop noScroll={true} />
        <NavBarMobile noScroll={true} />

        <div className="authentication-container">
            <main>
                <form className="authentication-form" onSubmit={signInUser}>
                    <h1>{SIGN_IN_HEADING}</h1>
                    
                    <input
                        type="email"
                        value={email}
                        placeholder={EMAIL_PLACEHOLDER}
                        autoComplete="email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        required
                    />
                    <div className="error" />

                    <input
                        type="password"
                        value={password}
                        placeholder={PASSWORD_PLACEHOLDER}
                        autoComplete="current-password"
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                        required
                    />
                    <div className="error" />

                    <button
                        type="submit"
                    >
                        {SIGN_IN_BUTTON}
                    </button>

                    <div
                        className="link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(FORGOT_PASSWORD_ROUTE);
                        }}
                    >
                        {FORGOT_PASSWORD_LINK}
                    </div>

                    <div
                        className="link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(HOME_ROUTE);
                        }}
                    >
                        {BACK_TO_HOME_IN_LINK}
                    </div>
                </form>
            </main>
        </div>

        <Contact />
    </>);
};

export default SignIn;