import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { BACK_TO_SIGN_IN_LINK, ERROR_MESSAGE, FORGOT_PASSWORD_SUBMIT_2_BUTTON, FORGOT_PASSWORD_API, FORGOT_PASSWORD_SUBMITTED_MESSAGE, SIGN_IN_ROUTE, FORGOT_PASSWORD_HEADING, FORGOT_PASSWORD_SUBMIT_1_BUTTON, EMAIL_PLACEHOLDER, FORGOT_PASSWORD_LINE, UNKNOWN_API_ERROR, INVALID_DISCOUNT_CODE_API_ERROR, INVALID_DISCOUNT_CODE_MESSAGE } from "../../constants";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Loader from "../../components/core/loader/Loader";
import Contact from "../../components/home/contact/Contact";
import "./Authentication.scss";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [submitButtonText, setSubmitButtonText] = useState(FORGOT_PASSWORD_SUBMIT_1_BUTTON);
    const [submittingEmail, setSubmittingEmail] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const navigate = useNavigate();

    const submitEmail = async (e) => {
        e.preventDefault();
        setSubmittingEmail(true);

        try {
            const response = await fetch(FORGOT_PASSWORD_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: email
                })
            });
            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                message.success(FORGOT_PASSWORD_SUBMITTED_MESSAGE);
                setSubmitButtonText(FORGOT_PASSWORD_SUBMIT_2_BUTTON);
                setSubmittingEmail(false);
                setEmailSent(true);
            }
        } catch (err) {
            if (err === INVALID_DISCOUNT_CODE_API_ERROR) {
                message.error(INVALID_DISCOUNT_CODE_MESSAGE);
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (submittingEmail) {
        return <Loader />;
    }

    return (<>
        <NavBarDesktop noScroll={true} />
        <NavBarMobile noScroll={true} />

        <div className="authentication-container">
            <main>
                <form className="authentication-form" onSubmit={submitEmail}>
                    <h1>{FORGOT_PASSWORD_HEADING}</h1>
                    
                    <input
                        type="email"
                        value={email}
                        placeholder={EMAIL_PLACEHOLDER}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        required
                    />
                    <div className="error"></div>
                    <button
                        type="submit"
                    >
                        {submitButtonText}
                    </button>

                    <div className={"email-sent" + (emailSent ? " visible" : "")}>{FORGOT_PASSWORD_LINE}</div>

                    <div
                        className="link"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate(SIGN_IN_ROUTE);
                        }}
                    >
                        {BACK_TO_SIGN_IN_LINK}
                    </div>
                </form>
            </main>
        </div>

        <Contact />
    </>);
};

export default ForgotPassword;