import React, { useState } from "react";
import { EffectCoverflow, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import KeyboardArrowDownLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowDownRight from "@mui/icons-material/KeyboardArrowRight";
import StarIcon from "@mui/icons-material/Star";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./Reviews.scss";

const Reviews = () => {
    const [slideIndex, setSlideIndex] = useState(0);

    return (
        <div className="reviews">
            <div className="rating-stars"><h1>4.7 <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h1></div>
            <p className="review-number">+ 150 Google Reviews</p>

            <Swiper
                grabCursor={true}
                centeredSlides={true}
                loop={false}
                slidesPerView={"auto"}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    slideShadows: false
                }}
                pagination={{ el: ".swiper-pagination", clickable: true }}
                navigation={{
                    nextEl: ".next",
                    prevEl: ".previous",
                    clickable: true
                }}
                modules={[EffectCoverflow, Pagination, Navigation]}
                className="swiper-container"
                onSlideChange={(swiper) => {
                    setSlideIndex(swiper.activeIndex);
                }}
            >
                <SwiperSlide>
                    <div className="review">
                        <section className="review-wrapper">
                            <h2><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h2>
                            <h3>Domenica van Veggel</h3>
                            <p>Ik heb met de online cursus van theorieja,  mijn theorie vandaag in 1x gehaald!!! Bij vragen antwoorden jullie super snel en via tiktok zijn jullie dagelijks live te volgen😁 Super bedankt voor de duidelijke uitleg en de filmpjes die op een leuke en leerzame manier worden gegeven 🤗  ik beveel jullie iedereen aan 😬</p>
                        </section>                        
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review">
                        <section className="review-wrapper">
                            <h2><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h2>
                            <h3>Anne Couwenberg</h3>
                            <p>Video's waren heel fijn uitgelegd! Veel dezelfde oefenvragen kwamen ook in CBR examen voor, dat was echt heel fijn! Hierdoor waren zenuwen ook bijna weg omdat ik deze vragen geoefend had.</p>
                            <p>Theorie Ja was ook nog goed te bereiken via Whatsapp, communicatie was dus zeker aanwezig!</p>
                        </section>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review">
                        <section className="review-wrapper">
                            <h2><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h2>
                            <h3>Danielle Nietvantoepassing</h3>
                            <p>Jullie zijn echt toppers. Ik had het opgegeven tot ik jullie tegen kwam. Ik heb moeite met leren en vooral uit een boek. Dankzij jullie video's en samenvattingen begreep ik alles. En kwam ook alles binnen bij me. En nu ben ik ook eindelijk GESLAAGD.!!!!</p>
                            <p>dank jullie wel heren<br />Ik raad hun 10000% aan.❤️</p>
                        </section>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review">
                        <section className="review-wrapper">
                            <h2><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h2>
                            <h3>Yasmin Motamedi</h3>
                            <p>Dit is letterlijk de beste online pakket die er te bieden is ik heb zoveeel geprobeerd maar met Dit ben ik geslaagd en je leert alles zo ben je goed voorbereid op je praktijk echt zo goeie service lieve mensen en heel behulpzaam! Ze waren altijd bereikbaar!</p>
                        </section>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review">
                        <section className="review-wrapper">
                            <h2><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h2>
                            <h3>Kimberly Faye</h3>
                            <p>Top manier van leren! Hierdoor heb ik mijn theorie ook gehaald. Het is een duidelijke en simpele manier om de kennis te leren. Erg tevreden!</p>
                        </section>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="review">
                        <section className="review-wrapper">
                            <h2><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /></h2>
                            <h3>Gloria Gigi</h3>
                            <p>In een keer mijn examen gehaald. Ben zeer dankbaar. Goede uitleg, vragen met antwoorden erbij, dus kon je fouten inzien. #TheorieJa  dankjulliewel!!</p>
                        </section>
                    </div>
                </SwiperSlide>

                <div className="controller">
                    <div className={"previous arrow" + (slideIndex > 0 ? "" : " disabled")}>
                        <KeyboardArrowDownLeft />
                    </div>
                    <div className={"next arrow" + (slideIndex < 5 ? "" : " disabled")}>
                        <KeyboardArrowDownRight />
                    </div>
                    <div className="swiper-pagination" />
                </div>
            </Swiper>
        </div>
    );
};

export default Reviews;