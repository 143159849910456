import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import { getJWT } from "../../../helpers/jwt";
import NavLink from "./NavLink";
import PackageStatus from "../PackageStatus";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import logo from "../../../images/logos/logo.svg";
import dashboard_icon from "../../../images/icons/dashboard-icon.svg";
import play_icon from "../../../images/icons/play-icon.svg";
import passed_icon from "../../../images/icons/passed-icon.svg";
import danger_icon from "../../../images/icons/danger-icon.svg";
import download_icon from "../../../images/icons/download-icon.svg";
import account_icon from "../../../images/icons/account-icon.svg";
import orders_icon from "../../../images/icons/orders-icon.svg";
import signout_icon from "../../../images/icons/signout-icon.svg";
import { PACKAGE_ALREADY_PAUSED_MESSAGE, DASHBOARD_ROUTE, ERROR_MESSAGE, EXAMS_ROUTE, HAZARDS_ROUTE, HOME_ROUTE, ORDERS_ROUTE, OTHER_DEVICE_MESSAGE, PACKAGE_EXPIRED_ROUTE, PACKAGE_PAUSED_ROUTE, PACKAGE_PAUSE_NOT_ALLOWED_MESSAGE, PAUSE_PACKAGE_API, PROFILE_ROUTE, SIGN_OUT_ROUTE, UNAUTHORIZED_MESSAGE, VIDEOS_ROUTE, PACKAGE_PAUSED_MESSAGE, UNAUTHORIZED_API_ERROR, NO_JWT_API_ERROR, INACTIVE_JWT_API_ERROR, UNKNOWN_API_ERROR, PACKAGE_ALREADY_PAUSED_API_ERROR, PACKAGE_PAUSE_NOT_ALLOWED_API_ERROR, DOWNLOADS_ROUTE, CREATE_ORDER_API, SWITCH_VEHICLE_API } from "../../../constants";
import "./NavMenu.scss";


const NavMenu = ({ packageExpired = false, packagePaused = false }) => {
    const [active, setActive] = useState(false);
    const [pausePackageWarning, setPausePackageWarning] = useState(false);
    const [pausePackageDuration, setPausePackageDuration] = useState(42);
    const [premiumButtonClicked, setPremiumButtonClicked] = useState(false);
    const [premiumItemClicked, setPremiumItemClicked] = useState(false);
    
    const navigate = useNavigate();
    const jwt = getJWT();
    const { user, reduxUser, setUser } = useAuthContext();

    const pausePackage = async () => {
        try {
            const response = await fetch(PAUSE_PACKAGE_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({
                    duration: pausePackageDuration
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                const packagePauseBegin = new Date();
                let packagePauseEnd = new Date();
                let packageEnd = new Date(user.autoToegang);
                let userCopy = JSON.parse(JSON.stringify(user));
    
                packagePauseEnd.setDate(packagePauseBegin.getDate() + pausePackageDuration);
                packageEnd.setDate(packageEnd.getDate() + pausePackageDuration);
                userCopy.autoToegang = packageEnd;
                userCopy.pakketPauzeBegin = packagePauseBegin;
                userCopy.pakketPauzeEind = packagePauseEnd;
                userCopy.pakketGepauzeerd = true;
                userCopy.pakketPauzeringen = user.pakketPauzeringen + 1;
                setUser(userCopy);
                message.success(PACKAGE_PAUSED_MESSAGE);
                navigate(PACKAGE_PAUSED_ROUTE);
            }
        } catch (err) {
            if (err === UNAUTHORIZED_API_ERROR || err === NO_JWT_API_ERROR) {
                message.error(UNAUTHORIZED_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === INACTIVE_JWT_API_ERROR) {
                message.error(OTHER_DEVICE_MESSAGE);
                navigate(SIGN_OUT_ROUTE);
            } else if (err === PACKAGE_ALREADY_PAUSED_API_ERROR) {
                message.error(PACKAGE_ALREADY_PAUSED_MESSAGE);
            } else if (err === PACKAGE_PAUSE_NOT_ALLOWED_API_ERROR) {
                message.error(PACKAGE_PAUSE_NOT_ALLOWED_MESSAGE);                
            } else {
                message.error(ERROR_MESSAGE);
            }
        } finally {
            setPausePackageWarning(false);
            setPremiumButtonClicked(false);
            setPremiumItemClicked(false);
        }
    };

    const createOrder = async () => {
        try {
            const response = await fetch(CREATE_ORDER_API, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    firstName: user.voornaam,
                    email: user.email,
                    password: "",
                    package: "Premium"
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                window.location.href = data.paymentUrl;
            }
        } catch (err) {
            message.error(ERROR_MESSAGE);     
        } finally {
        }
    };

    const switchVehicle = async (vehicle) => {
        try {
            console.log(vehicle)
            const response = await fetch(SWITCH_VEHICLE_API, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + jwt
                },
                body: JSON.stringify({
                    vehicle: vehicle
                })
            });

            const data = await response.json();
    
            if (!response.ok || String(response.status).charAt(0) !== "2") {
                if (data?.error) {
                    throw data?.error;
                } else {
                    throw UNKNOWN_API_ERROR;
                }
            }

            if (data?.error) {
                throw data?.error;
            } else {
                navigate(DASHBOARD_ROUTE);
                window.location.reload();
            }
        } catch (err) {
            message.error(ERROR_MESSAGE);
        } finally {
        }
    };

    useEffect(() => {
        const activeNavLink = document.getElementsByClassName("nav-link active")[0];

        if (activeNavLink) {
            activeNavLink.onclick = (e) => {
                e.preventDefault();
                setActive(!active);
            };
        }
    }, [active]);

    return (<>
        <nav className={"nav-menu" + (active ? " visible" : "")}>
            <section className="nav-top">
                <img
                    className="logo"
                    alt="Logo"
                    src={logo}
                    onClick={(e) => {
                        e.preventDefault();
                        navigate(HOME_ROUTE);
                    }}
                />

                <div
                    className="hamburger-button"
                    onClick={(e) => {
                        e.preventDefault();
                        setActive(!active);
                    }}
                >
                    <div className={"hamburger centered" + (active ? " active" : "")}>
                        <div className="line line1"></div>
                        <div className="line line2"></div>
                        <div className="line line3"></div>
                    </div>
                </div>
            </section>

            <section className="header-info">
                <div className="current-vehicle centered">Huidige categorie:<br /><span>{reduxUser.huidigVoertuig}</span></div>
                <PackageStatus />
            </section>

            <section className="links">
                {!(packageExpired || packagePaused)
                    ? <>
                        <NavLink route={DASHBOARD_ROUTE} icon={<img alt="Dashboard" src={dashboard_icon} />} text="Dashboard" />
                        
                        <h4>OEFENEN</h4>
                        
                        <NavLink route={VIDEOS_ROUTE} icon={<img alt="Les volgen" src={play_icon} />} text="Les volgen" />
                        <NavLink route={EXAMS_ROUTE} icon={<img alt="Examens maken" src={passed_icon} />} text="Examens maken" />
                        {reduxUser.huidigVoertuig === "Auto" ? <NavLink route={HAZARDS_ROUTE} icon={<img alt="Gevaarherkenning" src={danger_icon} />} text="Gevaarherkenning" /> : <></>}
                        <NavLink route={DOWNLOADS_ROUTE} icon={<img alt="Bestanden" src={download_icon} />} text="Bestanden" />
                    </>
                    : <></>
                }

                {packageExpired
                    ? <NavLink route={PACKAGE_EXPIRED_ROUTE} icon={<img alt="Pakket verlengen" src={play_icon} />} text="Pakket verlengen" />
                    : <></>
                }

                {packagePaused
                    ? <NavLink route={PACKAGE_PAUSED_ROUTE} icon={<img alt="Pauze beëindigen" src={play_icon} />} text="Pauze beëindigen" />
                    : <></>
                }
                
                <h4>PROFIEL</h4>

                <NavLink route={PROFILE_ROUTE} icon={<img alt="Mijn gegevens" src={account_icon} />} text="Mijn gegevens" />
                <NavLink route={ORDERS_ROUTE} icon={<img alt="Mijn aankopen" src={orders_icon} />} text="Mijn aankopen" />
                <NavLink route={SIGN_OUT_ROUTE} icon={<img alt="Uitloggen" src={signout_icon} />} text="Uitloggen" />

                {!(packageExpired || packagePaused) && reduxUser.pakketPauzeringen === 0
                    ? <div
                        className="pause-package-button"
                        onClick={(e) => {
                            e.preventDefault();
                            setActive(!active);
                            setPausePackageWarning(true);
                        }}
                    >
                        <div className="text centered">PAUZEREN</div>
                    </div>
                    : <></>
                }

                {!(packageExpired || packagePaused) && !reduxUser.premium && reduxUser.huidigVoertuig === "Auto"
                    ? <div
                        className="premium-package-button"
                        onClick={(e) => {
                            e.preventDefault();
                            setActive(!active);
                            setPremiumButtonClicked(true);
                        }}
                    >
                        <div className="text centered">UPGRADEN</div>
                        <h4>-75%</h4>
                    </div>
                    : <></>
                }

                {!(packageExpired || packagePaused) && !reduxUser.premium
                    ? <div
                        id="premium-item-clicked-button"
                        style={{ display: "none" }}
                        onClick={(e) => {
                            e.preventDefault();
                            setPremiumItemClicked(true);
                        }}
                    />
                    : <></>
                }

                {reduxUser.huidigVoertuig === "Auto"
                    ? <div
                        className="switch-vehicle-link"
                        onClick={(e) => {
                            e.preventDefault();
                            switchVehicle("Motor");
                        }}
                    >
                        <div className="text centered">Switch naar Motor</div>
                    </div>
                    : <></>
                }

                {reduxUser.huidigVoertuig === "Motor"
                    ? <div
                        className="switch-vehicle-link"
                        onClick={(e) => {
                            e.preventDefault();
                            switchVehicle("Auto");
                        }}
                    >
                        <div className="text centered">Switch naar Auto</div>
                    </div>
                    : <></>
                }
            </section>
        </nav>

        <div
            className={"menu-overlay" + (active ? " visible" : "")}
            onClick={(e) => {
                e.preventDefault();
                setActive(!active);
            }}
        />

        {!(packageExpired || packagePaused)
            ? <>
                <div
                    className={"package-overlay" + (pausePackageWarning || premiumButtonClicked || premiumItemClicked ? " visible" : "")}
                    onClick={(e) => {
                        e.preventDefault();
                        setPausePackageWarning(false);
                        setPremiumButtonClicked(false);
                        setPremiumItemClicked(false);
                    }}
                />

                {pausePackageWarning || premiumButtonClicked || premiumItemClicked
                    ? <div className="nav-overlays-close-button">
                        <CloseIcon
                            onClick={(e) => {
                                e.preventDefault();
                                setPausePackageWarning(false);
                                setPremiumButtonClicked(false);
                                setPremiumItemClicked(false);
                            }}
                        />
                    </div>
                    : <></>
                }

                {reduxUser.pakketPauzeringen === 0
                    ? <div className={"pause-package-warning" + (pausePackageWarning ? " visible" : "")}>
                        <div className="wrapper">
                            <PriorityHighIcon />
                            <h4>Weet je zeker dat je wilt pauzeren?</h4>
                            <p>Je mag maar 1 keer pauzeren. Als je het pakket pauzeert kan je gedurende de pauze niet oefenen. Je kan wel je pakket weer van de pauze af halen. Daarna kan je niet meer pauzeren en telt de timer van je pakket weer af. Je kan hooguit 6 weken pauzeren, na 6 weken activeert je pakket automatisch opnieuw.</p>
                        
                            <div
                                className="no-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPausePackageWarning(false);
                                }}
                            >
                                <div className="text centered">NIET PAUZEREN</div>
                            </div>
                            <div
                                className="yes-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    pausePackage();
                                }}
                            >
                                <div className="text centered">WEL PAUZEREN</div>
                            </div>
                        </div>
                    </div>
                    : <></>
                }

                {!reduxUser.premium
                    ? <div className={"premium-button-clicked" + (premiumButtonClicked ? " visible" : "")}>
                        <div className="wrapper">
                            <h3>Vergroot nu je kans op slagen met Premium!</h3>
                            <p>Je bent slechts één stap verwijderd van het ontgrendelen van 5 extra video's en 6 extra oefenexamens bovenop je huidige pakket.</p>
                            <p>Met ons Premium Pakket krijg je toegang tot:</p>
                            <ul>
                                <li>6 Extra Oefenexamens met nog meer Kennis en Inzicht vragen</li>
                                <li>5 Extra Nieuwe Videocursussen met uitleg over de moeilijke CBR-onderdelen.</li>
                            </ul>
                            <p>Premium kost nu tijdelijk &nbsp;<span className="old-price">€19,99</span>&nbsp;&nbsp;<span className="new-price">€4,99</span><br />Je betaalt eenmalig en je krijgt direct toegang tot de examens en video's.</p>
                            <p className="support-team">Nog vragen? Ons <a href="tel:+31202117879" target="_blanc">supportteam</a> staat klaar om je te helpen!</p>
                        
                            <div
                                className="yes-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    createOrder();
                                }}
                            >
                                <div className="text centered">Ja, ik wil Premium! 🔓</div>
                            </div>
                            <div
                                className="no-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPremiumButtonClicked(false);
                                }}
                            >
                                <div className="text centered">Annuleren</div>
                            </div>
                        </div>
                    </div>
                    : <></>
                }

                {!reduxUser.premium
                    ? <div className={"premium-item-clicked" + (premiumItemClicked ? " visible" : "")}>
                        <div className="wrapper">
                            <h3>Oeps! Deze inhoud is alleen voor premium gebruikers.</h3>
                            <p>Je staat op het punt toegang te krijgen tot de premium inhoud! 🔒</p>
                            <p>Wil je deze video bekijken of dit oefenexamen afleggen? Overweeg dan ons Premium Pakket voor volledige toegang tot:</p>
                            <ul>
                                <li>6 Extra Oefenexamens met nog meer Kennis en Inzicht vragen</li>
                                <li>5 Extra Nieuwe Videocursussen met uitleg over de moeilijke CBR-onderdelen.</li>
                            </ul>
                            <p>Premium kost nu tijdelijk &nbsp;<span className="old-price">€19,99</span>&nbsp;&nbsp;<span className="new-price">€4,99</span><br />Je betaalt eenmalig en je krijgt direct toegang tot de examens en video's.</p>
                            <p>Upgrade nu en vergroot je kans op slagen voor je theorie-examen!</p>
                            <p className="support-team">Nog vragen? Ons <a href="tel:+31202117879" target="_blanc">supportteam</a> staat klaar om je te helpen!</p>
                        
                            <div
                                className="yes-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    createOrder();
                                }}
                            >
                                <div className="text centered">🚀 Ga voor Premium</div>
                            </div>
                            <div
                                className="no-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPremiumItemClicked(false);
                                }}
                            >
                                <div className="text centered">Annuleren</div>
                            </div>
                        </div>
                    </div>
                    : <></>
                }
            </>
            : <></>
        }
    </>);
};

export default NavMenu;