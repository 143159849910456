import React, { useEffect } from "react";
import NavBarDesktop from "../../components/home/navbar/NavBarDesktop";
import NavBarMobile from "../../components/home/navbar/NavBarMobile";
import Contact from "../../components/home/contact/Contact";
import "./PTR.scss";


const Restitution = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="ptr-page">
            <NavBarDesktop noScroll={true} />
            <NavBarMobile noScroll={true} />
            
            <div className="wrapper">
                <h1>Restitutiebeleid</h1>
                <p>Via onze website <a href="https://theorieja.nl">https://theorieja.nl</a> verkopen wij alleen digitale producten. Het is niet mogelijk om te ruilen.</p>
                <p>Wanneer de digitale inhoud of een digitale dienst niet goed functioneert, moeten wij ervoor zorgen dat het binnen twee werkdagen wordt opgelost.</p>
                <p>Kunnen we het probleem niet oplossen? Wordt de oplossing langer dan twee werkdagen geleverd? Dan heb je de keuze uit twee mogelijkheden:</p>
                <p>1. Je kan stoppen met de digitale dienst en het volledige bedrag terugkrijgen. Deze optie is alleen van toepassing op pakketten die minder dan 25% van de tijdsduur hebben gebruikt.</p>
                <p>2. Je houdt de digitale dienst met gebrek, maar je betaalt minder (je krijgt een deel van het betaalde bedrag terug).</p>
                <p>Gaat het om een klein gebrek zoals een typefout, onjuiste grammatica, verkeerde afbeelding, etc.? Je kunt dan de digitale dienst niet beëindigen en je kunt ook geen gedeeltelijke terugbetaling krijgen.</p>
                <p>Restitutie is alleen mogelijk via een giraal proces.</p>
            </div>

            <Contact />
        </div>
    );
};

export default Restitution;