import React from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar} from "react-circular-progressbar";
import { ANALYZE_ROUTE_STATE, EXAM_ROUTE, MAX_ALLOWED_PAUSES } from "../../../constants";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import passed_icon from "../../../images/icons/passed-icon.svg";
import failed_icon from "../../../images/icons/failed-icon.svg";
import "react-circular-progressbar/dist/styles.css";
import "./ExamText.scss";

const ExamText = ({ statusses, statusIndex, setStatusIndex, text, updateCurrentStatus, updatingCurrentStatus }) => {
    const navigate = useNavigate();

    return (
        <section className="exam-text">
            <div className="exam-navigation">
                <h2 className="centered">{statusses[statusIndex].exam.titel}</h2>
                <div className="exam-number">
                    <div className="left">
                        <div className="text centered">{statusIndex + 1}</div>
                    </div>
                    <div className="middle">
                        <div className="text centered">/</div>
                    </div>
                    <div className="right">
                        <div className="text centered">{statusses.length}</div>
                    </div>
                </div>
                {statusIndex > 0
                    ? <div
                        className="previous-button"
                        onClick={(e) => {
                            e.preventDefault();
                            updateCurrentStatus(statusses[statusIndex - 1]);
                            setStatusIndex(statusIndex - 1);
                        }}
                    >
                        <WestIcon className="centered" />
                    </div>
                    : <div
                        className="previous-button disabled"
                    >
                        <WestIcon className="centered" />
                    </div>
                }

                {statusIndex < statusses.length - 1
                    ? <div
                        className="next-button"
                        onClick={(e) => {
                            e.preventDefault();
                            updateCurrentStatus(statusses[statusIndex + 1]);
                            setStatusIndex(statusIndex + 1);
                        }}
                    >
                        <EastIcon className="centered" />
                    </div>
                    : <div
                        className="next-button disabled"
                    >
                        <EastIcon className="centered" />
                    </div>
                }
            </div>
            {statusses[statusIndex].finished
                ? (statusses[statusIndex].exam.voertuig === "Auto" && statusses[statusIndex].knowledgePassed && statusses[statusIndex].insightPassed) || (statusses[statusIndex].exam.voertuig !== "Auto" && statusses[statusIndex].otherPassed)
                    ? <>
                        <h3>Resultaat recente poging</h3>
                        <img alt="Geslaagd" src={passed_icon} />
                        <div
                            className="status-text-button"
                            onClick={(e) => {
                                e.preventDefault();

                                if (!updatingCurrentStatus) {
                                    navigate(EXAM_ROUTE, {
                                        state: ANALYZE_ROUTE_STATE
                                    });
                                }
                            }}
                        >
                            <div className="text centered">Fouten bekijken</div>
                        </div>
                    </>
                    : <>
                        <h3>Resultaat recente poging</h3>
                        <img alt="Gezakt" src={failed_icon} />
                        <div
                            className="status-text-button"
                            onClick={(e) => {
                                e.preventDefault();

                                if (!updatingCurrentStatus) {
                                    navigate(EXAM_ROUTE, {
                                        state: ANALYZE_ROUTE_STATE
                                    });
                                }
                            }}
                        >
                            <div className="text centered">Fouten bekijken</div>
                        </div>
                    </>
                : (statusses[statusIndex].exam.voertuig === "Auto" && statusses[statusIndex].numKnowledgeAnswered > 0) || (statusses[statusIndex].exam.voertuig !== "Auto" && statusses[statusIndex].numOtherAnswered > 0)
                    ? <>
                        <h3>Nog niet afgerond</h3>
                        <div className="progress-circle-wrapper">
                            <CircularProgressbar
                                value={
                                    (statusses[statusIndex].exam.voertuig === "Auto" ? (statusses[statusIndex].numKnowledgeAnswered + statusses[statusIndex].numInsightAnswered) : statusses[statusIndex].numOtherAnswered / 
                                    (statusses[statusIndex].exam.voertuig === "Auto" ? (statusses[statusIndex].numKnowledge + statusses[statusIndex].numInsight) : statusses[statusIndex].numOther)) * 100
                                }
                                text={
                                    `${Math.round(((statusses[statusIndex].exam.voertuig === "Auto" ? (statusses[statusIndex].numKnowledgeAnswered + statusses[statusIndex].numInsightAnswered) : statusses[statusIndex].numOtherAnswered) / 
                                    (statusses[statusIndex].exam.voertuig === "Auto" ? (statusses[statusIndex].numKnowledge + statusses[statusIndex].numInsight) : statusses[statusIndex].numOther)) * 100)}%`
                                }
                            />
                        </div>
                        <div className="number-of-pauses">
                            Je hebt dit examen al {statusses[statusIndex].pauses} keer gepauzeerd.
                            <br />
                            In totaal mag je {MAX_ALLOWED_PAUSES} keer pauzeren.
                        </div>
                        <div
                            className="status-text-button"
                            onClick={(e) => {
                                e.preventDefault();

                                if (!updatingCurrentStatus) {
                                    navigate(EXAM_ROUTE, {
                                        state: ANALYZE_ROUTE_STATE
                                    });
                                }
                            }}
                        >
                            <div className="text centered">Examen inleveren</div>
                        </div>
                    </>
                    : <>
                        <p>{text}</p>
                    </>
            }
        </section>
    );
};

export default ExamText;