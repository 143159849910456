import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../context/AuthContext";
import useFetch from "../../../hooks/useFetch";
import { EXAMS_ROUTE, EXAM_STATUSSES_API } from "../../../constants";
import Loader from "../../core/loader/Loader";
import play_icon from "../../../images/icons/play-icon.svg";
import pause_icon from "../../../images/icons/pause-icon.svg";
import passed_icon from "../../../images/icons/passed-icon.svg";
import failed_icon from "../../../images/icons/failed-icon.svg";
import "./ExamsBlock.scss";

const ExamsBlock = () => {
    const { data } = useFetch(EXAM_STATUSSES_API, {}, true);
    const { user, reduxUser } = useAuthContext();
    const navigate = useNavigate();

    return (
        <section
            className="exams-block"
            onClick={(e) => {
                e.preventDefault();
                navigate(EXAMS_ROUTE);
            }}
        >
            {!data
                ? <Loader />
                : <>
                    <h2>Examenoverzicht</h2>
                    <div className={"exams-list" + ((user.premium && reduxUser.premium) ? " scroll" : "")}>
                        {data.statusses.map((status, i) => (
                            (!user.premium || !reduxUser.premium) && status.exam.premium
                                ? <></>
                                : <div key={i} className="exams-item">
                                    {reduxUser.huidigVoertuig === "Auto" ?
                                        status.finished
                                            ? status.knowledgePassed && status.insightPassed
                                                ? <img alt="Geslaagd" src={passed_icon} />
                                                : <img alt="Gezakt" src={failed_icon} />
                                            : status.numKnowledgeAnswered > 0
                                                ? <img alt="Gepauzeerd" src={pause_icon} />
                                                : <img alt="Examen starten" src={play_icon} />
                                        : status.finished
                                            ? status.otherPassed
                                                ? <img alt="Geslaagd" src={passed_icon} />
                                                : <img alt="Gezakt" src={failed_icon} />
                                            : status.numOtherAnswered > 0
                                                ? <img alt="Gepauzeerd" src={pause_icon} />
                                                : <img alt="Examen starten" src={play_icon} />
                                    }
                                    <div className="text">{status.exam.titel}</div>
                                </div>
                            
                        ))}
                    </div>
                </>
            }
        </section>
    );
};

export default ExamsBlock;