import React from "react";
import "./ContactBlock.scss";
import whatsapp_icon from "../../../images/icons/whatsapp-icon.svg";
import telephone_icon from "../../../images/icons/telephone-icon.svg";

const ContactBlock = () => {
    return (
        <section className="contact-block block">
            <h2>Neem contact op</h2>
            <a href="https://wa.me/31202117879" target="_blanc">
                <div className="whatsapp">
                    <img alt="Whatsapp" src={whatsapp_icon} />
                    <p>Hoi, mijn naam is Luna. Ik help je graag met al je vragen! Ik reageer binnen 4 uur, meestal veel sneller!</p>
                </div>
            </a>
            <a href="tel:+31202117879" target="_blanc">
                <div className="telephone">
                    <img alt="Telephone" src={telephone_icon} />
                    <p>Spoed of wil je gewoon iemand aan de lijn hebben? Bel ons!</p>
                </div>
            </a>
        </section>
    );
};

export default ContactBlock;