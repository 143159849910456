import React, { useState } from "react";
import info_icon from "../../../../images/icons/info-icon.svg";
import cross_icon from "../../../../images/icons/cross-icon.svg";
import "./PracticeResultOption.scss";

const PracticeResultOption = ({ label, text, result }) => {
    const [explanationActive, setExplanationActive] = useState(false);

    return (
        <div
            className={
                "practice-result-option"
                + (result.question.correcteAntwoord === label
                    ? " correct"
                    : (result.answer === label ? " incorrect" : ""))}
        >
            <div className="text">{text}</div>
            
            {result.question.uitleg
                ? <aside
                    className={"explanation-wrapper" + (explanationActive ? " active" : "")}
                    onClick={(e) => {
                        e.preventDefault();
                        setExplanationActive(!explanationActive);
                    }}
                >
                    <img className="info-icon centered" alt="Toelichting openen" src={info_icon} />
                    <img className="cross-icon centered" alt="Toelichting sluiten" src={cross_icon} />
                    <aside className="balloon">
                        <div className="explanation">
                            <div className="text centered">{result.question.uitleg}</div>
                        </div>
                        <div className="triangle" />
                    </aside>
                </aside>
                : <></>
            }
        </div>
    );
};

export default PracticeResultOption;